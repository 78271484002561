.cookies {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 32px;
  color: $color-jaguar;
  background-color: $color-default-white;
  border-top: 1px solid $color-solitude;

  z-index: 2000;

  &__container {
    display: flex;
    align-items: center;

    max-width: 69%;
    margin: 0 auto;

    @include vp-1439 {
      max-width: 80%;
    }

    @include vp-1023 {
      max-width: 100%;
    }

    @include vp-767 {
      flex-wrap: wrap;
    }
  }

  &__text {
    margin: 0;
    line-height: 2;
    padding-right: 8%;

    @include vp-1439 {
      padding-right: 5%;
      line-height: 1.5;
    }

    @include vp-1023 {
      padding-right: 16px;
      font-size: 14px;
    }

    @include vp-767 {
      margin-bottom: 32px;
      text-align: center;
    }
  }

  &__btn:not(:first-of-type) {
    margin-left: 8px;
  }

  &__btn {
    padding: 22px 38px;

    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.03em;

    @include vp-767 {
      margin: 0 auto;
      padding: 14px;

      font-size: 12px;
    }
  }
}
