.avatar-block {
  position: relative;

  &__btn {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 32px;
    height: 32px;

    color: $color-default-white;

    background-color: $color-shamrock;
    border-radius: 50%;
  }
}
