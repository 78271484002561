.modal-pro-address {

  .modal__content {
    width: 911px;
    padding: 80px 40px;

    @include vp-767 {
      width: 100%;
      padding: 24px;
    }
  }

  .modal__content-title {
    max-width: 100%;
    margin: 0 0 24px;
    text-align: left;
  }

  .modal__content-buttons {
    display: flex;
    justify-content: flex-end;

    @include vp-374 {
      flex-direction: column;
    }
  }

  .modal__content-button {
    @include vp-767 {
      max-width: 100%;
      padding: 18px;
    }
  }

  .modal__content-button:first-child {
    color: $color-black-russian;
    background-color: transparent;
    border: 1px solid $color-link-water;
  }

  .modal__content-button--background {
    background-color: $color-neon-blue;

    @include vp-767 {
      margin-top: 0;
      margin-left: 16px;
    }

    @include vp-374 {
      margin-top: 16px;
      margin-left: 0;
    }
  }
}
