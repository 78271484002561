.customer-options {
  grid-column: 1 / -1;
  grid-row: 1;
  background: linear-gradient(
    to right,
    $color-quartz 50%,
    $color-humming-bird 50%
  );
  margin-bottom: 24px;

  @include vp-1023 {
    display: none;
  }

  &__links {
    width: 100%;
    display: flex;

    &-item {
      padding: 16px;
      user-select: none;
      padding-right: 55px;
      width: 50%;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: $color-jaguar;
      text-align: center;
      transition: opacity $trans-default;

      &:last-child {
        padding-right: 0;
        padding-left: 41px;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          opacity: 0.6;
        }
      }
    }
  }
}
