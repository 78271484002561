.progress-bar {
  display: flex;
  padding: 10px 10px 0;

  @include vp-767 {
    padding: 2px 5px;
  }

  &__item {
    flex: 1;
    margin: 0 2px;
    height: 8px;
    border-radius: 4px;
    background-color: $color-neon-blue;
    opacity: 0.2;
    transition: opacity $trans-default;

    @include vp-767 {
      height: 4px;
      border-radius: 2px;
    }
  }

  &__item.is-succes {
    opacity: 1;
  }
}
