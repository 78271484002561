.user-card {
  display: flex;
  align-items: flex-start;
  position: relative;

  @include vp-767 {
    flex-wrap: wrap;
  }

  &__avatar {
    margin-right: 40px;

    @include vp-767 {
      margin-right: 16px;
    }
  }

  &__name {
    line-height: 1.6;
    padding-bottom: 5px;
  }

  &__info {
    padding-right: 32px;
    flex: 1 0 0;
    max-width: 480px;

    @include vp-1023 {
      max-width: 300px;
    }

    @include vp-767 {
      display: flex;
      flex-direction: column;
      padding-right: 16px;
    }
  }

  &__note:not(:last-child) {
    margin-right: 12px;
  }

  &__indicators {
    margin-bottom: 24px;
    column-gap: 8px;

    .rating {
      flex-direction: row;
    }

    @include vp-767 {
      margin-bottom: 0;
      padding-bottom: 5px;
    }
  }

  &__lang {
    padding-top: 12px;
    padding-right: 12px;
    margin: 0 auto;

    @include vp-767 {
      order: 1;
      margin: 0;
      flex: 1;
      flex-shrink: 0;
      padding-right: 16px;
      min-width: 90%;
    }
  }

  &__nav {
    @include vp-767 {
      position: absolute;
      right: 0;
      top: 0;
      // margin-left: auto;
    }
  }

  .languages__title {
    margin-bottom: 10px;
  }

  .languages__list {
    max-width: 170px;
  }

  .rating {
    padding-right: 8px;
  }
}
