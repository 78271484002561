.submenu {
    padding: 11px 0;
    background-color: $color-ghost-white;
    font-size: 14px;
    line-height: 20px;
    position: relative;

    .browser-firefox & {
        width: calc(4 / 3 * 100%);
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 1199px) {
            width: 100%;
        }
    }

    .page.customer-chat & {
        width: 100%;
        left: 0;
        transform: none;
    }

    @include vp-767 {
        padding: 10px 0;
        font-size: 10px;
    }

    &__link {
        padding: 13px 16px;
        border-radius: 23px;
        min-width: 230px;
        color: $color-neon-blue;
        letter-spacing: 0.03em;
        background-color: $color-quartz;

        @include vp-767 {
            min-width: unset;
            padding: 4px 38px;
            letter-spacing: 0.06em;
            font-size: 10px;
            line-height: 20px
        }

        @include vp-479 {
            padding: 4px 25px;
        }

        @include vp-374 {
            padding: 4px 15px;
        }
    }

    &__link:active {
        background-color: #b6baeb;
    }

    &__link:hover {
        background-color: #c5c9fe;
    }

    &__link:not(:last-of-type) {
        margin-right: 10px;
    }

    &__link--green {
        color: $color-jungle-green;
        background-color: $color-humming-bird;
    }

    &__link--green:hover {
        background-color: #acf6dc;
    }

    &__link--green:active {
        background-color: #a7e4ce;
    }

    .inner-container {
        @include vp-767 {
            display: flex;
            width: 100%;
            justify-content: center;
        }
    }
}