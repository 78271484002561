.registration-form {
  position: relative;
  padding-right: 351px;
  padding-left: 351px;

  @media (max-width: 1420px) {
    padding-right: 151px;
    padding-left: 151px;
  }

  @include vp-1023 {
    padding-right: 40px;
    padding-left: 40px;
  }

  @include vp-767 {
    padding-right: 0;
    padding-left: 0;
  }

  &__content {
    margin: 0 auto;
    padding: 61px 63px 80px 64px;
    max-width: 690px;
    min-height: 410px;
    background-color: $color-default-white;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0px 20px 76px rgba($color-gun-powder, 0.1);

    @include vp-767 {
      padding: 28px 20px 38px 20px;
    }
  }

  &__title-wrapper {
    margin-bottom: 31px;

    .title--h1 {
      font-size: 46px;
      line-height: 54px;
      max-width: 484px;

      @include vp-767 {
        font-size: 26px;
        line-height: 34px;
      }
    }
  }

  &__text {
    margin: 0;
    margin-top: 9px;
    font-size: 18px;
    line-height: 30px;
    color: $color-black-russian;
    &--gray {
      color: $color-gray-chateau;
    }
  }
}
