.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 32px 0;
  background-color: $color-default-white;

  .browser-firefox & {
    // position: relative;
    // width: calc(4 / 3 * 100%);
    // left: 50%;
    // transform: translateX(-50%);

    // @media (max-width: 1199px) {
    //   position: sticky;
    // }
    position: fixed;
    top: 0 !important;
    -moz-transform: scale(0.75);
    -moz-transform-origin: 50% 0%;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: $color-default-white;
      width: 100vw;
      height: 100%;
      top: 0;
    }

    &::before {
      left: 100%;
    }

    &::after {
      right: 100%;
    }

    @media (max-width: 1199px) {
      position: sticky;
      -moz-transform: scale(1);
    }
  }

  @include vp-1023 {
    padding: 12px 0;
  }

  &__container {
    position: relative;
    z-index: 1000;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    column-gap: 23px;

    &--preview {
      pointer-events: none;
    }

    @media (max-width: 1100px) {
      column-gap: 0;
    }

    @include vp-1023 {
      grid-template-columns: 1fr 1fr;
      column-gap: 0;
    }
  }

  &__logo {
    width: 154px;
    height: 40px;
    margin-right: 35px;
    display: block;
    user-select: none;

    img {
      width: 100%;
      height: 100%;
    }

    @include vp-1023 {
      width: 94px;
      height: 24px;
      margin-right: 0;
    }
  }

  .custom-select {
    z-index: 3;

    @include vp-1023 {
      width: 70px;
      height: 45px;
      margin-top: -10px;
      margin-left: 75%;
    }

    @include vp-767 {
      width: 75px;
      height: 45px;
      margin-top: -10px;
      margin-left: 50%;
    }
  }

  &__menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include vp-767 {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__menu {
    margin: 0;
    padding: 0;

    display: flex;
    // flex-wrap: wrap;

    list-style: none;

    // @include vp-1023 {
    //   margin-bottom: 55px;
    //   flex-direction: column;
    // }
  }

  &__menu-item:not(:last-child) {
    margin-right: 8px;

    // @include vp-1023 {
    //   margin-right: 0;
    //   margin-bottom: 32px;
    // }
  }

  &__menu-link {
    padding: 8px 12px;

    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;

    color: $color-black-russian;
    text-transform: uppercase;
    user-select: none;

    @include vp-1023 {
      padding: 8px 0;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }
  }

  &__menu-link-active {
    margin-right: 12px;
    margin-left: 12px;
    padding: 8px 12px;
    color: $color-default-white;
    border-radius: 48px;

    @include vp-1023 {
      margin: 0;
      padding: 8px 12px;
    }

    &--about {
      background-color: $color-jaguar;
    }

    &--customer {
      background-color: $color-neon-blue;
    }

    &--specialist {
      background-color: $color-shamrock;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);

    @include vp-1023 {
      display: flex;

      .btn--transparent {
        padding: 14px 32px;
      }
    }
  }

  &__button {
    @include vp-1023 {
      margin-bottom: 6px;
    }
  }

  &__navigation.is-active {

    .header__menu-container {
      display: block;
    }


  }

  &__user-panel {
    padding-top: 3px;
    padding-right: 16px;
    padding-bottom: 6px;
  }
}

.header__navigation.is-active~.header__logo {
  display: none;
}

.header__navigation.is-active~.custom-select {
  display: block;
}


.burger-container {
  &.tablet {
    padding: 10px 24px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 0 0 12px 12px;

    .header__menu {
      gap: 10px;
    }

    .custom-select {
      display: block;
      width: 55px;
      height: 40px;

      &__control {
        padding: 0;
      }

      &__single-value {
        width: 30px;
      }

      &__indicator {
        width: 14px;
      }
    }
  }

  &.mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;


    .header__user-panel {
      order: -1;
      padding: 16px;
      width: 100%;
      border-bottom: 1px solid #D2D5DD;
    }

    .mobile-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px 0;
      border-bottom: 1px solid #D2D5DD;

      .close-btn {
        cursor: pointer;
        border: none;
        background: none;
        outline: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        padding: 5px 10px 10px;
      }

      .custom-select {
        display: block;
        width: 55px;

        &__control {
          padding: 0;
        }

        &__single-value {
          width: 30px;
        }

        &__indicator {
          width: 14px;
        }
      }
    }

    .header__overlay {
      padding: 0;
    }

    .header__menu-container {
      grid-template-columns: 1fr;
    }

    .header__menu {
      padding: 43px 16px;
      flex-direction: column;
      gap: 36px
    }

    .header__buttons {
      padding: 0 16px;
      flex-direction: column-reverse;
      margin-top: 40px;
    }

    @include vp-479 {
      .header__menu {
        padding: 24px 16px;
        gap: 16px;

        &-container {
          position: relative;
        }
      }

      .user-nav__item {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: #17171b;
        text-transform: uppercase;
        -webkit-user-select: none;
        user-select: none;
      }
    }
  }
}