.about-info {
    margin-bottom: 311px;
    padding-right: 260px;
    padding-left: 260px;

    @media (max-width: 1180px) {
        padding-right: 100px;
        padding-left: 100px;
    }

    @include vp-767 {
        margin-bottom: 80px;
        padding-right: 0px;
        padding-left: 0px;
    }
}

.about-info__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.about-info__item {
    margin-bottom: 159px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    gap: 165px;

    @media (max-width: 940px) {
        grid-template-columns: 1fr;
        gap: 0px;
        margin-bottom: 100px;
    }

    &--fourth {
        .about-info__text-wrapper {
            padding-top: 40px;
        }

        .about-info__text-title {
            margin-left: 8px;
        }
    }

    &--fifth {
        .about-info__text-wrapper {
            padding-top: 103px;
        }

        .about-info__text-title {
            max-width: 520px;
        }
    }
}

.about-info__item:nth-child(even) {
    direction: rtl;

    .about-info__text-wrapper {
        margin-right: 0;
        direction: ltr;

        @media (max-width: 768px) {
            margin-left: 0;
        }
    }

    .about-info__text-title {
        margin-top: 10px;
        max-width: 520px;
    }

    .about-info__img {
        margin-left: 0;
    }

    @media (max-width: 1420px) {
        flex-direction: column;

        .about-info__img {
            margin-right: 0;
        }
    }
}

.about-info__item:nth-child(odd) {
    .about-info__text-wrapper {
        // margin-right: 180px;

        @media (max-width: 768px) {
            margin-right: 0;
        }
    }
}

.about-info__item:last-child {
    margin-bottom: 0;
}

.about-info__text-wrapper {
    max-width: 538px;

    @media (max-width: 1420px) {
        margin-bottom: 40px;
        margin-right: 0;
        align-self: center;
    }

    @include vp-1023 {
        padding-top: 20px;
    }
}

.about-info__text-title {
    margin: 0;
    margin-bottom: 28px;
    max-width: 450px;
    font-weight: 800;
    font-size: 26px;
    font-weight: 800;
    line-height: 33px;
    color: $color-jaguar;

    @media (max-width: 1550px) {
        font-size: 32px;
        line-height: 38px;
    }

    @media (max-width: 1420px) {
        font-size: 26px;
        line-height: 34px;
    }
}

.about-info__text {
    margin: 0;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 30px;
    color: $color-jaguar;
}

.about-info__link {
    position: relative;
    display: block;
    padding-top: 9px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: $color-neon-blue;

    &:hover {
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $color-neon-blue;
        }
    }

    &:focus {
        margin-top: 35px;
        margin-left: -4px;
        padding: 3px;
        border: 1px solid $color-pink-flamingo;
        border-radius: 4px;
        outline: none;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 2px;
            width: 100%;
            height: 1px;
            background-color: $color-neon-blue;
        }
    }
}

.about-info__img {

    @media (max-width: 1420px) {
        margin-left: 0;
        max-width: 530px;
    }
}

.about-info__img img {
    border-radius: 32px;
}
