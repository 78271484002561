.tag {
  padding: 6px 12px;

  font-size: 14px;
  line-height: 30px;
  color: $color-jaguar;
  font-weight: 600;

  border-radius: 8px;
  background-color: rgba($color-default-black, 0.1);

  @include vp-767 {
    padding: 6px;
    font-size: 12px;
    line-height: 20px;
  }

  &--active {
    color: $color-shamrock;
    background-color: rgba($color-shamrock, 0.2);
  }

  &--pending {
    color: $color-gamboge;
    background-color: rgba($color-gamboge, 0.1);
  }
}
