.authorization {
  &--customer {
    margin-bottom: 158px;
  }

  &__form-button {
    &--customer {
      margin-bottom: 0;
    }
  }

  &--password {
    margin-bottom: 69px;
  }

  &__form-input {
    &--customer {
      margin-top: 49px;
      margin-bottom: 48px;

      .custom-input input {
        padding-left: 19px;
      }
    }

    &--password {
      margin-bottom: 48px;
    }
  }
}
