.menu-toggler {
  padding: 16px 4px;
  line-height: 0;
  min-width: 30px;
  flex-shrink: 0;

  &__item {
    display: inline-flex;

    margin: 0 3px;

    width: 4px;
    height: 4px;
    background-color: $color-jaguar;
    border-radius: 50%;
  }
}
