.useful-info {

  @include vp-1023 {
    overflow: hidden;
    max-width: 100%;
    // overflow-x: scroll;
  }

  &__wrapper {
    @include vp-1023 {
      max-width: 100%;
      overflow: hidden;
      // overflow-x: scroll;
    }
  }

  .title--h2 {
    margin-bottom: 32px;
    font-size: 46px;
    line-height: 48px;

    @include vp-1023 {
      margin: 0;
      margin-bottom: 16px;
      font-size: 26px;
      line-height: 34px;
      color: $color-jaguar;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 -4px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    @include vp-1023 {
      flex-wrap: nowrap;
    }

    &.reviews-page {
      margin-top: 80px;
    }
  }

  &__item {
    flex: 1 0 150px;
    margin: 0 4px;
    margin-bottom: 8px;
    max-width: 20%;
    height: 352px;
    background-color: $color-ghost-white;
    border-radius: 24px;

    &__link {
      user-select: none;
      -webkit-user-drag: none;
    }

    @include vp-1439 {
      flex: 1 0 30%;
      max-width: calc(100% / 3 - 8px);
      // height: 300px;
    }

    @include vp-767 {
      margin: 0 5px;
      width: 201px;
      height: 292px;
      max-width: unset;
      flex: none;
    }
  }
}
