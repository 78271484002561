.specialist-card {
    position: relative;
    padding: 40px;
    padding-top: 38px;
    padding-left: 215px;
    min-height: 340px;

    @include vp-1023 {
        min-height: 300px;
    }

    @include vp-767 {
        padding-left: 40px;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        min-width: 100%;
        margin-bottom: 36px;

        @include vp-1023 {
            margin-bottom: 24px;
        }

        @include vp-767 {
            flex-direction: column-reverse;
            align-items: center;
        }
    }

    &__controls {
        @include vp-767 {
            margin-bottom: 24px;

            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-self: stretch;
        }
    }

    &__avatar {
        position: absolute;
        top: 40px;
        left: 40px;

        @include vp-767 {
            position: unset;
            margin-bottom: 8px;
        }
    }

    &__title {
        flex: 1;
    }

    .title--h4 {
        margin-bottom: 8px;
    }

    &__info {
        display: flex;
        flex-wrap: wrap;

        @include vp-767 {
            flex-direction: column;
        }
    }

    &__column {
        padding-left: 3px;
        padding-right: 9.7%;

        @include vp-1023 {
            max-width: unset;
        }

        @include vp-767 {
            margin-bottom: 23px;
            padding: 0;
        }
    }

    &__btn {
        padding: 10px 14px;
        margin-left: 9px;

        font-size: 12px;
        letter-spacing: 0.03em;
        color: inherit;

        @include vp-767 {
            margin-left: 0;
            padding: 6px 12px;

            font-size: 10px;
            line-height: 18px;
            letter-spacing: 0.06em;

            border-radius: 8px;
        }
    }

    .languages__list {
        max-width: 170px;
    }

    .prof-services__item {
        @include vp-767 {
            font-size: 14px;
            margin-bottom: 4px;
        }
    }

    .status {
        padding-top: 2px;
    }
}
