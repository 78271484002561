.credit-card {
  padding: 76px 24px;
  border-radius: 24px;
  background: radial-gradient(97.86% 362.52% at 95.37% 100%, #22D695 0%, #70EBBE 100%);
  box-shadow: 0 22px 37px -3px rgba($color-shamrock, 0.4);

  @include vp-767 {
    padding: 60px 16px;
  }
}
