.password-field {
  &__btn {
    position: absolute;
    top: 0;
    right: 0;

    width: 62px;
    height: 62px;

    display: grid;
    place-items: center;
    transition: color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-neon-blue;
      }
    }
  }

  label input {
    padding-right: 52px;
  }

  &__close-eye {
    display: none;
  }

  &.is-visible {
    .password-field__close-eye {
      display: block;
    }

    .password-field__open-eye {
      display: none;
    }
  }
}
