.modal-to-archive {
  .modal__content {
    padding: 88px 44px 80px 44px;
  }

  .modal__content-title {
    max-width: unset;
  }

  .modal__content-button {
    background-color: inherit;
    color: $color-black-russian;
    border: 1px solid $color-link-water;
    width: unset;
    max-width: unset;
    padding: 18px 32px;

    &--background {
      background-color: $color-neon-blue;
      border: none;
      color: $color-default-white;
      padding: 18px 45px;

      @include vp-767 {
        margin-left: 5px;
      }
    }
  }
}
