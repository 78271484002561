.bonuses {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  display: flex;

  @include vp-767 {
    margin-bottom: 31px;
    margin-left: 0;

    font-size: 14px;
    line-height: 22px;
}

  &__content {
    background-color: $color-ghost-white;
    border-radius: 24px;
    // display: flex;
    // align-items: flex-end;
    // justify-content: space-between;
    flex: 1;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    min-height: 400px;
    max-width: 100%;
    overflow: hidden;
    zoom: 1.25;

    @media (max-width: 1199px) {
        zoom: unset;
    }

    @include vp-1023 {
        flex-direction: column;
        padding: 0;
        min-height: fit-content;
    }

    // @include vp-1439 {
    //   flex-direction: column;
    //   align-items: center;
    // }

    // @include vp-1023 {
    //   flex-direction: row;
    //   align-items: flex-end;
    // }

    // @include vp-767 {
    //   // flex-direction: column;
    //   // align-items: flex-start;
    //   display: block;
    // }
  }

  &__background-image-wrapper {
    display: block;
    margin: 0;
    align-self: flex-end;
    max-width: 600px;
    min-width: 600px;
    height: 100%;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    // @media (max-width: 1570px) {
    //   min-width: calc(100% - 602px);
    // }

    @include vp-1023 {
        display: flex;
        justify-content: center;
        z-index: 1;
        max-width: 100%;
        min-width: 100%;
        height: auto;
        max-height: 610px;
    }

    @include vp-767 {
        // padding: 90px 16px 0 40px;
        padding: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__picture {
    display: block;
    width: 100%;
    height: 100%;
    // height: auto;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // width: 600px;
    img {
        width: 100%;
        // height: auto;
        height: 100%;
        object-position: center;
    }
    // @include vp-1023 {
    //   position: initial;
    //   width: 100%;
    // }
  }

  &__content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 56px 40px 56px 72px;
    margin: auto 0;
    min-width: 602px;
    max-width: calc(100% - 600px);

    @include vp-1279 {
      padding: 56px 32px 56px 64px;
      min-width: 552px;
      // width: calc(100% - 600px);
    }

    @include vp-1023 {
      padding: 56px 56px 40px;
      // max-width: 100%;
      width: 100%;
      min-width: 100%;
  }

    @include vp-767 {
      padding: 40px 32px 32px;
      display: block;
      max-width: 100%;
    }

    .btn {
      background-color: transparent;
      width: fit-content;
      border-color: $color-link-water;
      padding: 13px 29px;
      @include vp-767 {
        width: 100%;
      }
    }
  }

  &__content .title--h2 {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 24px;

    @include vp-767 {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &__text {
    margin: 0 0 40px;
    font-size: 18px;
    line-height: 26px;
    max-width: 100%;
    color: $color-black-russian;

    @include vp-767 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;
    }

    p {
        margin-top: 0;
        margin-bottom: 16px;
        &:last-child {
            margin: 0;
        }
    }

    .ql-size-large{
      font-size: 18px;
      line-height: 26px;

      @include vp-767 {
          font-size: 16px;
          line-height: 22px;
      }

    }
}

  &__content-button {
    display: inline-block;
    position: relative;

    @include vp-767 {
      margin-bottom: 30px;
      color: $color-neon-blue;
      font-size: 14px;
      line-height: 22px;
      border-bottom: 1px solid rgba(67, 75, 238, 0.2);
    }
  }
}
