.review-card {
  padding-bottom: 57px;
  color: $color-jaguar;

  @include vp-767 {
    padding: 22px 16px 32px;
  }

  &__header {
    position: relative;

    margin-bottom: 15px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include vp-1023 {
      margin-bottom: 16px;
    }

    @include vp-767 {
      padding-top: 46px;
    }

    .rating {
      flex-direction: row;
    }
  }

  &__title {
    padding: 0 16px;
    padding-right: 8px;
    color: inherit;

    @include vp-767 {
      padding: 0 8px;
    }
  }

  &__subtitle {
    margin-bottom: 5px;
  }

  &__rating {
    position: absolute;
    left: 8px;
    bottom: -32px;

    font-size: 12px;
    line-height: 20px;

    @include vp-767 {
      top: 3px;
      left: 0;
      bottom: unset;
      padding-right: 8px;
    }
  }

  &__btn {
    align-self: flex-start;
    padding: 10px 16px;

    font-size: 12px;
    line-height: 20px;

    border-radius: 8px;

    @include vp-767 {
      padding: 5px 10px;

      font-size: 10px;
      line-height: 18px;
      letter-spacing: 0.06em;
    }
  }

  &__controls {
    display: flex;

    @include vp-767 {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__date {
    padding-right: 16px;
    margin-right: auto;

    font-size: 16px;

    @include vp-767 {
      padding-right: 0;
    }

    @include vp-767 {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__text {
    padding: 0 82px;

    font-size: 18px;
    line-height: 30px;

    @include vp-1023 {
      font-size: 16px;
      line-height: 1.5;
    }

    @include vp-767 {
      padding: 0;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__icon-share {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  &__share-btn {
    color: $color-jaguar;
    padding-right: 0;
    margin-left: 12px;
  }

  &__user {
    padding: 4px 0;
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 70%;
      -webkit-box-flex: 1;
  }

  &--new {
    background-color: $color-ghost-white;

    .review-card__date::after {
      display: inline-block;

      content: "new";
      margin-left: 8px;

      text-transform: uppercase;
      font-size: 12px;
      line-height: 20px;
      font-weight: 700;
      color: $color-jaguar;

      transform: translateY(-3px);
    }

    .btn--white {
      background-color: transparent;
    }
  }
}

.review-card--small {
  padding: 24px;
  border-radius: 12px;
  background-color: $color-ghost-white;

  .rating {
    margin-right: auto;
  }

  .review-card__title {
    padding-right: 0;
    padding-left: 4px;
  }

  .review-card__tag {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
  }

  .title {
    font-size: 16px;
  }

  .text:not(.grey-card__title) {
    margin-bottom: 16px;
  }
}
