.chat {
  display: flex;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr minmax(150px, 264px);
    grid-row: auto;
    column-gap: 20px;
    row-gap: 32px;

    width: 76%;
    margin: 0 auto;

    @include vp-1023 {
      display: flex;
      flex-direction: column;
      row-gap: unset;
    }
  }

  @include vp-1439 {
    .chat__header {
      grid-column: 1/-1;
    }

    .chat__wrapper {
      width: 83%;
    }
  }

  @include vp-1023 {
    .chat__wrapper {
      width: 100%;
    }
  }

  &__info {
    margin: 0 auto;
    padding-bottom: 36px;
    max-width: 330px;


    &:not(:first-child) {
      padding-top: 21px;
    }
  }

  &__pinned {
    padding-top: 1px;
    grid-column: 2;
    grid-row: 2;

    @include vp-1023 {
      margin: 16px 0;
    }
  }

  &__window {
    grid-column: 1;
    border-top: 1px solid $color-link-water;
    height: 481px;
    padding: 16px 0;
    overflow: auto;

    @include vp-1023 {
      border-top: 0;
      min-height: 200px;
    }
  }

  &__controls {
    font-size: 14px;
    line-height: 20px;
    align-items: center;

    .popover {
      @include vp-767 {
        width: 100%;
      }
    }
  }

  &__btn {
    margin-top: 5px;
    margin-right: 20px;
    margin-bottom: 7px;
    padding: 18px 32px 20px;
    letter-spacing: 0.03em;

    @include vp-767 {
      width: 100%;
      margin-right: 0;
    }
  }

  &__text {
    margin: 0;
    font-size: 12px;
    text-align: center;
    color: $color-mischka;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__msg {
    display: flex;
    align-items: flex-end;
    padding: 16px;
    margin: 0 0 8px;
    width: fit-content;
    max-width: 56.5%;

    color: $color-jaguar;

    border-radius: 12px;
    background-color: rgba($color-neon-blue, 0.1);

    @include vp-767 {
      max-width: 90%;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .color-grey {
      font-size: 12px;
    }

    .text {
      padding-right: 8px;
    }
  }

  &__msg--green {
    background-color: rgba($color-shamrock, 0.1);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__msg--left {
    margin-right: auto;
  }

  &__msg--right {
    margin-left: auto;
  }
}
