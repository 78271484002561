.promo-specialists {
  overflow: hidden;
  .title--h1 {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 46px;

    @include vp-767 {
      font-size: 28px;
      line-height: 36px;
    }
  }
  .promo__content-text {
    padding-bottom: 30px;
  }
  .promo__content-wrapper::after {
    @include vp-1023 {
      display: none;
    }
  }
  .promo__text {
    margin-bottom: 40px;
    font-size: 18px;
      line-height: 26px;
    @include vp-767 {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 24px;
    }

    .ql-size-large{
      font-size: 18px;
      line-height: 26px;

      @include vp-767 {
          font-size: 16px;
          line-height: 22px;
      }

    }
  }
  .promo__order-buttons {
    position: initial;
    align-items: center;
    @include vp-767 {
      flex-direction: column;
    }
    .btn {
      box-shadow: 0px 13px 28px 0px #22D69580;
      text-transform: uppercase;
      width: fit-content;
      padding: 23px 64px;
      margin: 0 64px 0 0;
      font-size: 14px;
      line-height: 18px;
      @include vp-767 {
        width: 100%;
        margin: 0 0 32px;
      }
    }
    .link {
      height: 24px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      letter-spacing: 0.04em;
      align-self: auto;
      padding: 0;
      color: $color-shamrock;
      &__icon {
        margin-right: 8px;
      }
    }
  }
}
