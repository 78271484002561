.order-information {
  padding: 40px 12% 57px;
  background-color: $color-ghost-white;
  border-radius: 24px;
  margin-bottom: 48px;

  @include vp-1919 {
    padding: 40px 7.5% 57px;
  }

  @include vp-1199 {
    padding: 24px 24px 42px;
    width: calc(100% + 48px);
    position: relative;
    left: -24px;
  }

  @include vp-767 {
    padding: 24px 16px 42px;
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    margin-bottom: 20px;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-items: baseline;
    align-items: baseline;
    margin-bottom: 35px;

    @include vp-1199 {
      grid-template-columns: 1fr;
      margin-bottom: 18px;
    }
  }

  &__status {
    display: inline-block;
    padding: 6px 12px;
    margin: 0;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;

    @include vp-1199 {
      margin-bottom: 16px;
      order: 1;
    }

    &--active {
      color: $color-jungle-green;
      background: rgba(34, 214, 149, 0.2);
    }

    &--draft {
      color: $color-jaguar;
      background: rgba(0, 0, 0, 0.1);
    }

    &--deal {
      color: $color-deep-sky-blue;
      background: rgba(27, 198, 252, 0.2);
    }

    &--done {
      color: $color-default-white;
      background: $color-shamrock;
    }
  }

  &__buttons {
    justify-self: end;

    @include vp-1199 {
      order: 3;
      justify-self: start;
    }
  }

  &__button {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 16px;
    color: $color-jaguar;
    background-color: inherit;
    border: 1px solid $color-link-water;
    border-radius: 8px;

    &:not(:last-child) {
      margin-right: 9px;
    }

    &--details {
      display: none;
      letter-spacing: 0.06em;
    }

    &--dotts {
      padding: 12px 24px;
    }

    &--list {
      border-radius: 0;
      border: none;
      text-transform: none;
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      padding: 9px 16px;
      margin: 0;
      width: 100%;
      justify-content: flex-start;
      &:not(:last-child) {
        margin-right: 0;
      }
      &.btn {
        border-radius: 0;
      }
      @media (hover: hover), screen and (min-width: 0 \0) {
        &:hover,
        &:active {
          opacity: 1;
          background-color: #434BEE33;
          color: $color-neon-blue;
          &.btn--green {
            background-color: $color-humming-bird;
            color: $color-shamrock;
            transition: all $trans-default;
          }
        }
      }
    }

    @include vp-1199 {
      font-size: 10px;
      line-height: 18px;
      padding: 7px 12px;
    }

    @include vp-767 {
      display: none;

      &--details {
        display: block;
      }
    }
  }

  .dotted-menu {
    height: 24px;
  }

  .title--h2 {
    max-width: 650px;
    grid-column: 1 / -1;

    @include vp-1199 {
      grid-column: 1;
      max-width: unset;
      order: 2;
      margin-bottom: 16px;
    }

    @include vp-767 {
      max-width: calc(100vw - 32px);
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @include vp-1439 {
      padding: 0;
    }
  }

  &__item:not(:last-child) {
    padding-right: 21px;
  }

  &__item {
    flex: 1 0 0;

    @include vp-1199 {
      font-size: 14px;
      line-height: 22px;
      flex-basis: 48%;

      &:not(:last-child) {
        margin-bottom: 26px;
      }
    }

    &-heading {
      margin: 0;
      margin-bottom: 7px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-gray-chateau;

      @include vp-1199 {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 2px;
      }
    }

    &-link {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $color-neon-blue;
    }

    &-text {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-jaguar;

      @include vp-1199 {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.link-underline {
  border-bottom: 1px solid rgba(67, 75, 238, 0.2);
  cursor: pointer;
}
