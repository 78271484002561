.order-information-details {
  &__content-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__content-item {
    margin: 0;
    display: grid;
    grid-template-columns: minmax(max-content, 284px) 1fr;
    align-items: baseline;
    padding: 32px 0 36px;
    border-bottom: 1px solid $color-link-water;

    &:first-of-type {
      padding: 0 0 36px;

      @include vp-1199 {
        padding: 0 0 24px;
      }
    }

    @include vp-1199 {
      grid-template-columns: 1fr;
      padding: 22px 0 26px;
    }

    &-heading {
      margin: 0;
      max-width: 231px;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: $color-gray-chateau;

      @include vp-1199 {
        line-height: 26px;
        margin-bottom: 11px;
        max-width: unset;
      }
    }
  }

  &__content-services-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__content-services-item {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: $color-jaguar;

    @include vp-1023 {
      line-height: 26px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;

      @include vp-1023 {
        margin-bottom: 8px;
      }
    }
  }
}
