.form {
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 18px;

        .new-order__btn {
            display: none;

            @include vp-767 {
                display: block;
            }
        }

        &--big-margin {
            margin-bottom: 32px;
        }

        &--medium-margin {
            margin-bottom: 22px;
        }

        &--no-margin {
            margin-bottom: 0;
        }

        &--end {
            justify-content: flex-end;
        }

        @include vp-767 {
            margin-bottom: 23px;
            flex-direction: column;
        }

        &--block {
            display: block;
        }
    }

    &__block:not(:last-of-type) {
        margin-bottom: 44px;

        @include vp-767 {
            margin-bottom: 24px;
        }
    }

    &__flex {
        display: flex;
        flex-wrap: wrap;

        @include vp-767 {
            flex-direction: column;
        }
    }

    &__row:last-of-type {
        margin-bottom: 0;
    }

    &__field:first-child {
        margin-left: 0;
    }

    &__field:first-of-type {
        margin-left: 0;
    }

    &__field {
        flex-grow: 1;
        min-width: 20%;
        margin-left: 20px;

        @include vp-767 {
            margin-left: 0;
            margin-top: 18px;
        }

        &--small-margin {
            margin-left: 11px;

            @include vp-767 {
                margin: 18px 0 0 0;
            }
        }
    }

    &__field--w30 {
        max-width: 33%;

        @include vp-767 {
            max-width: unset;
        }
    }

    &__note {
        margin: 0;
        margin-top: 7px;
        min-width: 90%;

        font-size: 14px;
        line-height: 20px;
        color: $color-gray-chateau;
    }

    &__button {
        margin-top: 30px;
    }

    &__upload-foto {
        margin: 48px auto 30px;
    }

    &__control {
        padding: 16px 28px;
        font-size: 16px;

        @include vp-767 {
            width: 100%;
        }
    }

    &__controls {
        margin-top: 48px;

        &--small-margin {
            margin-top: 24px;
        }

        &--right {
            justify-content: flex-end;
        }

        @include vp-767 {
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;

            padding: 16px;
            padding-bottom: 24px;
            z-index: 100;
            background: white;

            .btn {
                border: 0;
                width: 100%;
                padding: 14px;

                &--transparent {
                    border: 1px solid $color-solitude;
                }
            }
        }

        &--full-width {
            flex-wrap: nowrap;

            @include vp-767 {
                flex-wrap: wrap;
                padding: 0;
            }

            .btn {
                width: 100%;

                &:first-child {
                    margin-right: 8px;

                    @include vp-767 {
                        margin-right: 0;
                        margin-bottom: 8px;
                    }
                }
            }
        }

        &--medium-width {
            @include vp-767 {
                flex-wrap: wrap;
                padding: 0;
            }

            .btn {
                width: 50%;

                @include vp-767 {
                    width: 100%;
                }
            }
        }

        &--not-fixed-sm {
            @include vp-767 {
                position: relative;

                .btn {
                    border: 1px solid $color-link-water;
                }
            }
        }
    }

    &__back-link {
        margin-right: 7px;
    }

    &__button {
        min-width: 300px;
        width: max-content;
        margin-top: 29px;
    }

    fieldset {
        margin: 0;
        padding: 0;
        border: 0;
        flex-grow: 1;

        @include vp-767 {
            flex: none;
        }
    }

    legend {
        margin: 0 0 12px;

        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
    }

    .custom-input {
        flex-grow: 1;
    }

    &__block--specialist--description {
        .custom-toggle--checkbox {
            padding: 0;
            margin-bottom: 16px;
        }
    }

    &__policy {
        max-width: 382px;
        margin-bottom: 34px;

        .custom-toggle {
            padding: 0;
            margin-bottom: 16px;

            label {
                padding-left: 0;
            }

            .custom-toggle__label {
                font-size: 16px;
                line-height: 24px;
                color: $color-jaguar;
            }

            &__icon {
                border: 1px solid $color-link-water;
            }
        }

        .link {
            margin-left: 5px;
        }
    }

    &__choose-lang-wrapper {
        margin-bottom: 7px;
        max-height: 548px;
        overflow-y: auto;
    }

    &--filter {
        .custom-toggle__label {
            font-size: 16px;
            line-height: 24px;
        }

        .custom-input label input {
            margin-bottom: 10px;
            padding: 8px 16px;
            font-size: 16px;
            line-height: 24px;
        }

        .form__row {
            @include vp-767 {
                margin-bottom: 7px;
            }
        }
    }

    .btn {
        &:not(:last-child) {

            @include vp-767 {
                margin-bottom: 8px;
            }
        }
    }

    &__text {
        color: $color-jaguar;
        font-size: 18px;
        line-height: 30px;
        margin-right: 8px;
        &.link {
            color: $color-neon-blue;
            .registration-page--specialist & {
                color: $color-shamrock;
            }
        }
    }
}

.form--big-fieldset {
    legend {
        margin: 0 0 24px;

        font-weight: 800;
        font-size: 32px;
        line-height: 48px;
    }
}

.form__row--h515 {
    & fieldset {
        max-height: 515px;
        overflow-y: auto;
    }

    .custom-toggle__icon {
        left: 1px;
    }
}

.form__grid {
    display: grid;
    column-gap: 19px;
    row-gap: 19px;


    &--4x {
        grid-template-columns: repeat(4, 1fr);

        @include vp-767 {
            grid-template-columns: repeat(3, 1fr);
        }

        @include vp-374 {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.form__title {
    margin-bottom: 24px;

    @include vp-767 {
        margin-bottom: 8px;
    }
}

.form__subtitle {
    margin: 0 0 8px;
}
