.specialist-page { 
  padding-top: 49px;
  padding-bottom: 5px;

  font-size: 18px;
  line-height: 30px;
  color: $color-jaguar;

  @include vp-1023 {
    padding-top: 32px;
    font-size: 16px;
    line-height: 24px;
  }

  @include vp-767 {
    padding: 16px 0 32px;

    font-size: 14px;
    line-height: 22px;
  }

  &__profile-card {
    margin-bottom: 48px;

    @include vp-1023 {
      margin-bottom: 32px;
    }

    @include vp-767 {
      margin: 0 -16px 32px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;

    @include vp-1023 {
      flex-direction: column-reverse;
    }
  }

  &__content {
    flex: 1 0 500px;
    padding-right: 6.5%;

    @include vp-1023 {
      flex-basis: 200px;
      padding-right: 16px;
    }

    @include vp-767 {
      padding-right: 0;
      flex: none;
    }
  }

  &__aside {
    padding-top: 32px;
    min-width: 300px;
    max-width: 407px;

    @include vp-1023 {
      max-width: 300px;
      min-width: 100%;
      margin-top: 2px;
      margin-bottom: 24px;
    }

    .indicators {
      margin-bottom: 16px;

      @include vp-767 {
        margin-bottom: 19px;
      }
    }

    .profile-card__note {
      margin-bottom: 9px;

      @include vp-767 {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .profile-card__note:last-of-type {
      margin-bottom: 24px;

      @include vp-767 {
        margin-bottom: 0;
      }
    }

  }

  &__btn {
    .popover--grey .popover__tip {
        background-color: $color-sky-blue;
        left: 0;
        right: 0;
    }

    .popover::after {
        background-color: $color-sky-blue;
    }

    @include vp-1023 {
     position: fixed;
     right: 0;
     bottom: 0;
     left: 0;

     padding: 24px 16px;

     background: $opacity-to-white;
    }
  }

  &__about {
    max-width: 90%;
    margin-bottom: 48px;

    @include vp-767 {
      max-width: unset;
      margin-bottom: 32px;
    }

    .title {
      margin-bottom: 12px;

      @include vp-1023 {
        margin-bottom: 9px;
      }
    }

    .subtypes {
      .title {
        margin-bottom: 0;
      }
    }

    .text {
      margin-bottom: 31px;

      @include vp-1023 {
        margin-bottom: 16px;
      }

      @include vp-767 {
        margin-bottom: 24px;
      }
    }
  }

  &__dropdown:not(:last-of-type) {
    margin-bottom: 20px;

    @include vp-767 {
      margin-bottom: 11px;
    }
  }

  &__dropdown {
    @include vp-767 {
      margin: 0 -16px;
      max-width: 100vw;
    }
  }

  &__controls {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 64px;

    @include vp-767 {
      margin-top: 54px;
      flex-wrap: wrap;
    }

    .btn {
      margin-bottom: 20px;
      width: auto;
      &:not(:last-child) {
        margin-right: 20px;
      }
      @include vp-767 {
        padding: 18px 32px;
        width: 100%;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
    @include vp-767 {
      .specialist-profile__btn-text {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__btn-text {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    border: none;
    background-color: transparent;
    color: $color-neon-blue;
    text-transform: none;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .03em;
    line-height: 30px;
    
  }

  .reviews {
    padding-top: 24px;
  }
}
