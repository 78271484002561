.price-promo {
  min-height: 680px;

  @include vp-1023 {
    min-height: 448px;
  }

  .promo__content-wrapper {
    padding: 30px 80px;

    @include vp-1023 {
      flex-direction: column;
      padding: 30px 5% 0;
    }
  }

  .promo__background-gradient-wrapper {
    @include vp-1023 {
      display: block;
      top: unset;
      bottom: 0;
      height: unset;
    }
  }
  .promo__background-gradient-wrapper img {
    width: unset;
    height: 100%;

    @include vp-1439 {
      width: unset;
      transform: translateX(0);
    }

    @include vp-1023 {
      width: 100%;
      transform: translateX(0);
    }

    @include vp-767 {
      width: 100%;
      height: auto;
      transform: translateX(0);
    }
  }

  .title--h1 {
    margin-bottom: 28px;
  }

  .promo__background-image-wrapper {
    align-self: center;
    flex: 1 1 100%;
    transform: translateY(30px);
    min-width: 45%;

    @include vp-1023 {
      padding: 0;
      transform: translateY(0);
      max-height: unset;
    }
  }

  .promo__text,
  .promo__subtitle {
    max-width: unset;
  }

  .promo__subtitle {
    margin-bottom: 28px;
  }

  .promo__content-text {
    width: unset;
    max-width: 895px;
    padding-right: 19%;
    padding-bottom: 0;

    @include vp-1439 {
      padding-right: 10%;
    }

    @include vp-1023 {
      padding: 0;
      margin-bottom: 32px;
    }
  }

  .promo__text {

    @include vp-1023 {
      font-size: 16px;
      line-height: 24px;
  }
  }
}
