.provisions {
  margin-bottom: 122px;

  @include vp-767 {
    margin-bottom: 80px;
  }
}

.provisions__list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, auto);
  list-style: none;
  max-width: 100%;

  @media (max-width: 940px) {
    grid-template-columns: 1fr;
    width: fit-content;
    margin: 0 auto;
  }
}

.provisions__item {
  padding: 40px;
  padding-bottom: 84px;
  margin-bottom: 20px;
  margin-right: 20px;
  max-width: 600px;
  min-height: 560px;
  border-radius: 32px;

  @media (max-width: 1160px) {
    padding: 30px;
    padding-bottom: 70px;
    margin-right: 15px;
  }

  @media (max-width: 940px) {
    margin-right: 0;
    margin-bottom: 15px;
    max-width: 100%;
  }

  &:nth-child(3n + 3) {
    margin-right: 0;
  }

  &--gray {
    background-color: $color-ghost-white;
  }

  &--light-green {
    background-color: $color-humming-bird;
  }

  &--quartz {
    background-color: $color-quartz;
  }
}

.provisions__item-top {
  margin-bottom: 49px;
  display: flex;
  justify-content: space-between;
}

.provisions__item-top-title {
  margin: 0;
  font-size: 46px;
  font-weight: 800;
  line-height: 48px;
  color: $color-black-russian;

  @media (max-width: 1360px) {
    font-size: 36px;
    line-height: 38px;
  }

  @include vp-1023 {
    font-size: 26px;
    line-height: 34px;
  }
}

.provisions__item-top-icon {
  position: relative;
  margin-top: 10px;
  margin-right: 50px;
  z-index: 5;

  @media (max-width: 1360px) {
    margin-right: 40px;
  }

  &::after {
    content: "";
    position: absolute;
    top: -16px;
    left: 11px;
    width: 64px;
    height: 64px;
    background-color: $color-quartz;
    border-radius: 50%;
    z-index: 0;

    @media (max-width: 1360px) {
      width: 56px;
      height: 56px;
    }
  }

  &--quartz::after {
    background-color: $color-humming-bird;
  }
}

.provisions__item-top-icon svg {
  position: relative;
  width: 32px;
  height: 32px;
  z-index: 2;

  @media (max-width: 1360px) {
    width: 28px;
    height: 28px;
  }
}

.provisions__item-text {
  margin: 0;
  font-size: 18px;
  line-height: 34px;
  color: $color-jaguar;
}

.provisions__item-text em {
  font-weight: 700;
}
