.cascade {
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 225px;
  padding-right: 390px;

  @include vp-1439 {
    padding-right: 0;
    padding-bottom: 120px;
  }

  @include vp-1279 {
    padding-top: 40px;
    padding-bottom: 70px;
  }

  h1 {
    margin: 0 0 40px;
    font-weight: 800;
    font-size: 56px;
    line-height: 76px;
    color: $color-black-russian;

    @include vp-1279 {
      font-size: 48px;
      line-height: 56px;
    }

    @include vp-1023 {
      margin-bottom: 30px;
    }

    @include vp-767 {
      font-size: 36px;
      line-height: 44px;
    }

    @include vp-374 {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  p {
    margin: 0;
  }

  p,
  li {
    font-size: 16px;
    line-height: 30px;

    @include vp-374 {
      font-size: 14px;
      line-height: 26px;
    }
  }

  ol {
    counter-reset: li;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li::before {
    counter-increment: li;
    content: counters(li, ".") ". ";
  }

  li {
    margin: 16px 0;
    color: $color-black-russian;
  }

  li::before,
  li > h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 48px;
    color: $color-jaguar;

    @include vp-767 {
      font-size: 20px;
      line-height: 30px;
    }

    @include vp-374 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  li::before {
    white-space: pre;
  }

  h2 {
    margin: 0;
    margin-top: 24px;
    padding-right: 30px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    @include vp-767 {
      margin: 0;
    }
  }

  ol ol li::before {
    font-family: $ff-sf-pro-display;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    @include vp-374 {
      font-size: 14px;
      line-height: 26px;
    }
  }

  a {
    color: $color-black-russian;
    text-decoration: underline;
    transition: color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-neon-blue;
        transition: color $trans-default;
      }
    }
  }
}
