.link {
  border: none;
  background-color: inherit;
  cursor: pointer;
  align-self: flex-start;
  color: $color-neon-blue;
  transition: opacity $trans-default;

  a {
    color: $color-neon-blue;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }

  &--underline {
    box-shadow: 0 1px 0 0 rgba($color-neon-blue, 0.2);
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }

  &--hover-underline {
    outline: none;
    transition: box-shadow $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 1;
        box-shadow: 0 1px 0 0 rgba($color-neon-blue, 0.7);
        transition: box-shadow $trans-default;
      }
    }

    .registration-page--specialist & {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          opacity: 1;
          box-shadow: 0 1px 0 0 rgba($color-shamrock, 0.7);
          transition: box-shadow $trans-default;
        }
      }
    }
  }

  &--green {
    color: $color-shamrock;

    &.link--underline {
      box-shadow: 0 1px 0 0 rgba($color-shamrock, 0.2);
    }
  }

  &--fw-medium {
    font-weight: 500;
  }
}
