.toggle-menu {
  position: absolute;
  top: 0;
  right: 16px;
  border: none;
  background-color: inherit;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: none;
  z-index: 3;

  @include vp-1023 {
    display: block;
  }

  &__item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 16px;
    height: 2px;
    background-color: $color-jaguar;
    border-radius: 5px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: $color-jaguar;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 5px;
    }

    &::before {
      bottom: 6px;
    }

    &::after {
      bottom: -6px;
    }
  }
}

.header__navigation.is-active .toggle-menu__item {
  @include vp-1023 {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);

    &::before {
      display: none;
    }

    &::after {
      top: 0;
      left: 0;
      transform: rotate(90deg);
    }
  }
}
