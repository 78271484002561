.card {
    display: flex;
    flex-direction: column;

    padding: 33px 30px;
    border: 1px solid $color-link-water;
    border-radius: 24px;

    @include vp-767 {
        padding: 16px;
    }

    &__title {
        color: inherit;
        margin-bottom: 11px;
        line-height: 1.3;
    }

    &__text {
        padding-bottom: 30px;
        font-size: 18px;
        line-height: 30px;

        @include vp-1023 {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__btn {
        margin-top: auto;
        padding: 24px;
        align-self: flex-start;
        min-width: 300px;
        height: 80px;
        box-shadow: 0px 13px 28px rgb(34 214 149 / 50%);
        border-radius: 12px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.03em;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select: none;
        user-select: none;

        @include vp-767 {
            align-self: stretch;
            font-size: 12px;
            line-height: 24px;
            min-width: unset;
            height: 64px;
        }
    }
}
