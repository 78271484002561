.selection-spec {
  &__list {
    list-style: none;
    padding: 0;
    margin: -12px -12px 0;
    display: flex;
    flex-wrap: wrap;

    @include vp-1439 {
      flex-direction: column;
    }

    @include vp-1023 {
      flex-wrap: wrap;
    }

    @include vp-767 {
      margin: 0;
    }
  }

  &__text {
    margin: 0;
    margin-bottom: auto;
    padding-bottom: 32px;
    max-width: 533px;
    font-size: 20px;
    line-height: 34px;
    color: $color-jaguar;

    @include vp-1439 {
      max-width: unset;
    }

    @include vp-1023 {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 24px;
    }

    @include vp-767 {
      margin-bottom: 0;
      padding-bottom: 24px;
    }
  }

  &__item:last-child {
    @include vp-767 {
      margin-bottom: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 12px 12px 0;
    padding: 40px 44px 64px 40px;
    // max-width: 684px;
    // width: 100%;
    // min-width: 500px;
    flex: 1 0 500px;
    min-height: 520px;
    background: $color-default-white;
    border: 1px solid $color-solitude;
    border-radius: 24px;

    @include vp-1439 {
      max-width: unset;
      min-height: unset;
      margin-right: 0;
      margin-bottom: 24px;
      flex-basis: auto;
    }

    @include vp-767 {
      margin: 0;
      margin-bottom: 13px;
      width: 100%;
      height: 100%;
      min-height: 100%;
      padding: 93px 24px 32px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 40px;
      right: 44px;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: $color-quartz;

      @include vp-1023 {
        top: 16px;
        right: 32px;
      }

      @include vp-767 {
        right: unset;
        top: 24px;
        left: 36px;
        width: 48px;
        height: 48px;
      }
    }

    &--mail-icon,
    &--search-icon {
      &::after {
        content: "";
        position: absolute;
        top: 56px;
        right: 88px;
        width: 32px;
        height: 32px;

        @include vp-1023 {
          top: 32px;
          right: 80px;
        }

        @include vp-767 {
          right: unset;
          top: 36px;
          left: 24px;
          width: 24px;
          height: 24px;
        }
      }
    }

    &--mail-icon {
      &::after {
        background: url("../../frontend/app/img/content/customer-page/selection-spec/icon-1.svg")
          50% 50% no-repeat;

        @include vp-767 {
          background: url("../../frontend/app/img/content/customer-page/selection-spec/icon-1-mobile.svg")
            50% 50% no-repeat;
        }
      }
    }

    &--search-icon {
      &::after {
        background: url("../../frontend/app/img/content/customer-page/selection-spec/icon-2.svg")
          50% 50% no-repeat;

        @include vp-767 {
          background: url("../../frontend/app/img/content/customer-page/selection-spec/icon-2-mobile.svg")
            50% 50% no-repeat;
        }
      }
    }

    .title {
      margin: 0;
      padding-right: 32%;
      margin-bottom: 24px;

      font-size: 46px;
      line-height: 48px;
      color: $color-black-russian;

      @include vp-1439 {
        padding-right: 80px;
      }

      @include vp-1279 {
        font-size: 36px;
        line-height: 44px;
      }

      @include vp-1023 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 16px;
      }

      @include vp-767 {
        padding-right: 0;
        font-size: 26px;
        line-height: 34px;
      }
    }

    &-button {
      align-self: flex-start;
      min-width: 300px;
      height: 80px;
      box-shadow: 0px 13px 28px rgba(67, 75, 238, 0.5);
      border-radius: 12px;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.03em;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;

      @include vp-767 {
        align-self: stretch;
        font-size: 12px;
        line-height: 24px;
        min-width: unset;
        height: 64px;
      }
    }
  }
}
