.pagination {
    max-width: 390px;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;

    list-style: none;
    margin-bottom: 10px;
    margin-top: 10px;

    @include vp-374 {
        &__item {
            width: 36px;
            height: 36px;

            &--prev,
            &--next {
                width: 66px;
            }
        }
    }

    &__item {
        width: 40px;
        height: 40px;
        margin-right: 2px;

        &--prev,
        &--next {
            width: 93px;
        }

        @include vp-767 {
            &--5 {
                display: none;
            }
        }
    }

    &__item:last-child {
        margin-right: 0;
    }

    &__link {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 16px;
        line-height: 30px;
        color: $color-gray-chateau;
        border-radius: 4px;
        transition: color $trans-default;

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover {
                color: $color-neon-blue;
                transition: color $trans-default;

                svg {
                    color: $color-neon-blue;
                    transition: color $trans-default;
                }
            }

            &:focus {
                outline: 2px solid rgba($color-neon-blue, 0.1);
            }
        }
    }

    &__link--prev,
    &__link--next {
        justify-content: space-evenly;

        svg {
            width: 9px;
            height: 18px;

            color: $color-gray-chateau;
            transition: color $trans-default;

            @include vp-767 {
                margin-right: 5px;
            }
        }
    }

    &__link--prev {
        flex-direction: row-reverse;

        svg {
            transform: rotate(180deg);
        }
    }

    &__link--next {
        @include vp-767 {
            margin-left: 5px;

            svg {
                margin-right: 0;
                margin-left: 5px;
            }
        }
    }

    & .is-active {
        color: $color-neon-blue;
        background: rgba($color-neon-blue, 0.1);
        pointer-events: none;
    }
}
