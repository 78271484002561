.modal-order-date {
  .modal__content {
    width: 100%;
    padding: 88px 44px 80px 44px;
  }

  .modal__content-title {
    max-width: unset;
  }

  .custom-input {
    max-width: 320px;
    margin: 0 auto 24px;

    button {
      padding: 0;
      position: absolute;
      top: 50%;
      right: 22px;
      transform: translateY(-50%);
      border: none;
      background-color: inherit;
    }

    input {
      padding-right: 60px;
    }
  }


  &--publish {
    legend {
      text-align: left;
    }
  }

  .modal__content-button {
    width: 100%;
    max-width: 208px;
    padding: 18px 32px;
  }
}
