.how-often {
  &__select {
    position: relative;
  }

  &__select-button {
    cursor: pointer;
    border: none;
    border-bottom: 1px solid rgba(67, 75, 238, 0.2);
    background-color: inherit;
    padding: 0;
    margin-left: 8px;
    margin-right: 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: $color-neon-blue;

    @include vp-767 {
      font-size: 14px;
      line-height: 18px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }

  &__select-list {
    position: absolute;
    z-index: 3;
    display: none;
    list-style: none;
    padding: 22px 0 12px 0;
    margin: 0;
    background: $color-default-white;
    box-shadow: 0px 20px 76px rgba(72, 73, 95, 0.1);
    border-radius: 8px;
  }

  &__select-item {
    cursor: pointer;
    padding: 9px 26px 9px 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: $color-jaguar;

    @include vp-767 {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__select-list.is-active {
    display: block;
  }

  &__select-item.is-current {
    color: $color-neon-blue;
    background: rgba(67, 75, 238, 0.2);
  }
}
