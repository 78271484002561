.title {
  margin: 0;
  font-weight: 800;
  color: $color-black-russian;

  &--h1 {
    font-size: 68px;
    line-height: 76px;

    @include vp-1279 {
      font-size: 48px;
      line-height: 56px;
    }

    @include vp-767 {
      font-size: 36px;
      line-height: 44px;
    }
  }

  &--h2 {
    font-size: 40px;
    line-height: 48px;

    @include vp-1023 {
      font-size: 30px;
      line-height: 34px;
    }

    @include vp-767 {
      font-size: 26px;
    }
  }

  &--h3 {
    font-size: 32px;
    line-height: 48px;

    @include vp-767 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &--h4 {
    font-size: 18px;
    line-height: 34px;
    font-weight: 700;

    @include vp-1023 {
      font-size: 16px;
      line-height: 1.2;
    }

    @include vp-767 {
      font-size: 14px;
    }
  }

  &--f68 {
    font-size: 68px;
    line-height: 1.1;

    @include vp-1439 {
      font-size: 55px;
    }

    @include vp-1023 {
      font-size: 38px;
    }

    @include vp-767 {
      font-size: 32px;
    }
  }

  &--f46 {
    font-size: 46px;
    line-height: 1.2;

    @include vp-1439 {
      font-size: 40px;
    }

    @include vp-1023 {
      font-size: 32px;
    }

    @include vp-767 {
      font-size: 26px;
    }
  }

  &--f32 {
    font-size: 32px;
    line-height: 48px;

    @include vp-1023 {
      font-size: 24px;
      line-height: 1.2;
    }

    @include vp-767 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &--f24 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;

    @include vp-1023 {
      font-size: 24px;
      line-height: 1.2;
    }

    @include vp-767 {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
