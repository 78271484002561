.contacts-field {
  flex-grow: 1;

  .custom-input {
    input {
      position: relative;
      padding-right: 60px;
    }
  }

  .valid-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  &.is-valid {
    .valid-icon {
      display: block;
    }
  }

  &__button {
    position: relative;
    border: none;
    background-color: inherit;
  }
}
