.response-card-order {
  margin-right: 17px;
  width: 270px;

  @include vp-767 {
    margin-right: 0;
    margin-left: 10px;
    margin-bottom: 13px;
    width: 100%;
  }

  &__response,
  &__new-message,
  &__suggested-deals {
    position: relative;
    display: flex;
    margin-bottom: 24px;
  }

  &__response {
    margin-top: 5px;
  }

  &__suggested-deals {
    margin-bottom: 0;
  }

  &__response-amount,
  &__new-message-amount,
  &__suggested-deals-amount {
    position: absolute;
    top: 11px;
    left: 9px;
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $color-default-white;
    border-radius: 50%;
    text-align: center;
    min-width: 20px;
    min-height: 20px;
    background-color: $color-neon-blue;
  }

  &__text {
    margin: 0;
    margin-left: 16px;
    color: $color-jaguar;

    @include vp-767 {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
