.my-finances{

    &__wrapper{
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content max-content max-content;
        gap: 0px 0px;
        margin: 0 auto;
        padding-top: 74px;
        padding-right: 12%;
        padding-left: 12%;

        @media (max-width: 990px) {
            padding-right: 2%;
            padding-left: 2%;
        }

        @media (max-width: 767px) {
            padding-top: 15px;
        }

        .main-card__wrapper{
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .section-block{
            margin-top: 51px;

            &__title{
                margin: 0 0 19px;
                font-weight: 700;
                font-size: 24px;
                line-height: 34px;
            }

            @media (max-width: 767px) {
                margin-top: 25px;
            }
        }

        .other-cards{
            display: flex;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;
        }

        .payment-history{
            display: flex;
            flex-direction: column;
            gap: 20px;

            &__filters{
                display: flex;
                align-items: flex-end;
                gap: 10px;
                flex-wrap: wrap;

                @media (max-width: 767px) {
                    flex-wrap: nowrap;
                    flex-direction: column-reverse;
                    align-items: flex-start;
                }

                .date-field > label input {
                    padding: 15px 80px;    
                  }
            }

            .btn.modal__btn.btn--bg-green.btn--transparent{
                max-width: 298px;
                min-width: 200px;
                min-height: 62px;
            }

            .user-card__nav.user-nav{

                &:after{
                    position: absolute;
                    content: "";
                    width: 100%;
                    left: 0;
                    right: 0;
                    bottom: -20px;
                    height: 20px;
                }

                .menu-toggler{
                    height: 62px;
                    border: 1px solid #dadce3 !important;
                    border-radius: 12px !important;
                    padding: 0 20px;
                }

                .user-nav__list.list-reset{
                    bottom: -15px;

                    &:hover{
                        padding: 21px 16px;
                        max-height: 5000px;
                        opacity: 1;
                    }
                }
            }

            .table-payments{
                width: 100%;
                text-align: left;
                border: 1px solid #dadce3;
                border-radius: 12px;

                th{
                    padding: 17px 32px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 30px;
                    color: #9EA4A7;
                }

                td{
                    padding: 17px 32px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 30px;
                }

                tr:not(:last-child){
                    td,th{
                        border-bottom: 1px solid #dadce3;
                    }
                }
            }

            .mobile-payments{
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                &__payment.payment{
                    border: 1px solid #D2D5DD;
                    border-radius: 24px;
                    padding: 24px;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-width: 346px;
                    gap: 16px;

                    .payment-row{
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        &__title{
                            margin: 0;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: #9EA4A7;
                        }

                        &__info{
                            margin: 0;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                        }

                        &__card{
                            display: flex;
                            align-items: center;
                            gap: 5px;
                        }
                    }
                }
            }

            .table-payments__not-payments{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 132px 0;
                padding: 10px;
                white-space: nowrap;

                @media (max-width: 480px) {
                    margin: 50px 0;
                }

                &__icon-box{
                    width: 124px;
                    height: 124px;
                    border-radius: 50%;
                    background: #F4F4F6;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                p{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 30px;
                    margin: 38px 0 0;
                }
            }
        }

        .date-inputs{

            &__title{
                font-weight: 700;
                font-size: 18px;
                line-height: 34px;
            }

            &__block{
                display: flex;

                .react-datepicker-wrapper{
                    flex: 1 1 auto;

                    @media (max-width: 425px) {
                        .icon.date-field__icon{
                            display: none;
                        }

                        .date-field label input {
                            padding: 15px 15px 15px 50px;
                        }
                    }
                }

                .react-datepicker__day--selected {
                    color: #22D695;
                    border-radius: 4px;
                    background-color: rgba(34, 214, 149, 0.1)!important;

                    &:hover{
                        background-color: rgba(34, 214, 149, 0.1)!important;
                    }
                }

                .react-datepicker__day--keyboard-selected {
                    color: #22D695;
                    background-color: rgba(34, 214, 149, 0.1)!important;
                }

                div.separator{
                    flex: 1 1 auto;
                    min-width: 10px;
                }
            }
        }

        .mobile-cards{
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            margin-top: 25px;

            @media (max-width: 480px) {
                align-items: center;
            }

            .mobile-cards__main-card__description{
                text-align: center;
                font-weight: 700;
                font-size: 14px;
                line-height: 26px;
                color: #23232A;
            }

            .add-new__card{
                flex-direction: row;
                gap: 10px;
                height: auto;
                border-radius: 12px;
                min-height: 50px;

                p{
                    margin: 0;
                }
            }
        }
    }

    &__bank-card.add-new__card{
        cursor: pointer;
        width: 264px;
        height: 168px;
        border-radius: 24px;
        border: 1px dashed #22D695;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #22D695;
            margin: 10px 0 0;
        }
    }

    &__bank-card.bank-card{
        width: 264px;
        height: 168px;
        background: #F4F4F6;
        border-radius: 24px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;

        .bank-card__info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            &-icon__card{
                background: #E7E7EC;
                border-radius: 8px;
            }
            &--btn{
                border: none;
                outline: none;
                background: transparent;
                cursor: pointer;
            }

            &-card__number{
                margin: 0;
                font-weight: 700;
                font-size: 16px;
                line-height: 34px;

                span{
                    padding-right: 4px;
                }
            }
        }
    }

    .pagination{
        @media (max-width: 480px) {
            max-width: 330px;
        }
    }
}
.specialist-select__block{
    @media (max-width: 480px) {
        width: 100% !important;
    }

    &-title{
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;

        @media (max-width: 767px) {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
}

.specialist-select{
    &__prefix{
        &__control{
            padding: 12px 20px;
            background-color: #ffffff!important;
            border: 1px solid #dadce3!important;
            border-radius: 12px!important;
            width: 380px;
            transition: border-color 0.3s ease!important;

            &:hover{
                border-color: #22d695!important;
            }

            @media (max-width: 480px) {
                width: 100% !important;
            }

            &--is-focused{
                box-shadow: none!important;
            }
        }

        &__menu-list{
            padding: 0!important;
        }

        &__option{

            &:first-child{
                border-radius: 4px 4px 0 0;
            }

            &:last-child{
                border-radius: 0 0 4px 4px;
            }

            &--is-focused{
                background-color: rgba(34, 214, 149, 0.1)!important;
            }

            &--is-selected{
                color: #ffffff!important;
                background-color: #22d695!important;
            }
        }
    }
}

.upload-invoice__modal{
    &-label{
        text-align: left;
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
        margin-bottom: 5px;
    }

    .modal__content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .modal__content-title{
        margin: 0;
    }

    .modal__description{
        display: none;
    }

    .modal__content-buttons{
        margin-top: 25px;
        width: 100%;
    }

    .specialist-select__prefix__control {
        padding: 8px 20px;
        text-align: left;
    }
}
