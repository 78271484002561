// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2"),
//     url("../fonts/rouble.woff") format("woff");
// }

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "SF Pro Display";

  font-display: swap;
  src: url("../fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "SF Pro Display";

  font-display: swap;
  src: url("../fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "SF Pro Display";

  font-display: swap;
  src: url("../fonts/SFProDisplay-Semibold.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Semibold.woff") format("woff");
}

@font-face {
  font-style: italic;
  font-weight: 600;
  font-family: "SF Pro Display";

  font-display: swap;
  src: url("../fonts/SFProDisplay-SemiboldItalic.woff2") format("woff2"),
    url("../fonts/SFProDisplay-SemiboldItalic.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "SF Pro Display";

  font-display: swap;
  src: url("../fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 800;
  font-family: "SF Pro Display";

  font-display: swap;
  src: url("../fonts/SFProDisplay-Heavy.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Heavy.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Montserrat";

  font-display: swap;
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2"),
    url("../fonts/Montserrat-Medium.woff") format("woff");
}
