.profile-mini-card {
  padding: 8px 28px 20px 8px;
  background: $color-ghost-white;
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: flex-start;

  &__img-wrap {
    position: relative;
    width: max-content;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 16px;
    }
  }

  &__img-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 16px;
  }

  &__indicators {
    .rating {
      -webkit-box-direction: normal;
      -ms-flex-direction: unset;
      flex-direction: row;
      justify-content: center;

      svg {
        margin-left: 0;
        margin-right: 4px;
      }
    }

    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    margin: 0;
    padding-top: 7px;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: $color-jaguar;
  }

  &__deal {
    color: $color-gray-chateau;
    margin-right: 8px;
  }

  &__link-review {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $color-neon-blue;
  }
}
