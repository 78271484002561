.order-details {
  padding: 70px 0 164px;

  @include vp-767 {
    padding: 0 0 64px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__btn {
    display: block;
    margin-left: auto;
    margin-top: 77px;
    padding: 18px;
    min-width: 193px;

    @include vp-767 {
      margin-top: 32px;
      margin-left: 0;
      width: 100%;
      font-size: 12px;
      padding: 14px;
    }
  }

  .toggle-place {
    max-width: 406px;
  }

  .profile-block {
    line-height: 24px;
    padding: 20px;
    margin-bottom: 24px;

    @include vp-767 {
      padding: 14px;
      margin-top: 13px;
      margin-bottom: 16px;
    }

    .title {
      @include vp-767 {
        font-size: 16px;
      }
    }
  }

  .form {
    max-width: 835px;
  }

  .subtitle {
    margin-bottom: 40px;

    @include vp-767 {
      margin-bottom: 24px;
    }
  }

  .custom-toggle label span {
    @include vp-767 {
      font-size: 14px;
    }
  }

  .custom-toggle--radio,
  .custom-toggle--checkbox {
    padding: 0;

    @include vp-767 {
      padding: 6px 0;
    }
  }

  .custom-toggle--radio {
    padding: 3px 0 13px;

    &:last-child {
      padding-bottom: 6px;
    }

    @include vp-767 {
      padding: 8px 0;
    }
  }

  .toggle-place__item {
    padding-bottom: 8px;
  }

  .profile-block__row {
    padding: 16px 0;
    margin-bottom: 0;

    @include vp-767 {
      padding: 10px 0;
    }
  }

  .profile-block__col:not(:first-child) {
    text-align: right;

    @include vp-767 {
      text-align: left;
      flex: 0;
    }
  }

  .profile-block__controls {
    padding-left: 0;
  }

  .toggle-place__address {
    @include vp-767 {
      margin-bottom: 0;
    }
  }

  .photo-stream {
    @include vp-767 {
      padding-top: 10px;
    }
  }
}
