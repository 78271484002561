.order-stats-specialists {
  // grid-column: 1;
  // grid-row: 4;

  @include vp-1919 {
    // grid-column: 1;
    // grid-row: 3;
  }

  @include vp-1023 {
    // grid-column: 1;
  }

  .order-stats__wrapper {
    padding: 20px 0 159px 120px;

    @include vp-1023 {
      padding: 20px 16px 0;
    }

    @include vp-1919 {
      padding: 20px 0 0 120px;
    }
  }
}
