.account-options {
    min-height: 760px;

    display: flex;
    align-items: center;

    background-color: $color-ghost-white;
    border-radius: 24px;
   

    @include vp-1023 {
        margin-bottom: 0;
        min-height: unset;
        flex-direction: row;
        align-items: center;
    }

    @include vp-767 {
        flex-direction: column;
        grid-row: 4;
        margin-left: 0;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 0 auto;
        padding: 55px 16px 82px 120px;

        @include vp-1439 {
            padding: 55px;
            padding-bottom: 0;
        }

        @include vp-1023 {
            padding: 32px 16px 15px;
            padding-left: 32px;
        }

        @include vp-767 {
            padding-left: 16px;
            justify-content: flex-start;
            flex: none;
        }

        .title {
            margin-bottom: 33px;
            max-width: 402px;
            color: $color-jaguar;

            @include vp-1023 {
                font-size: 24px;
                line-height: 32px;
                color: $color-black-russian;
                margin-bottom: 18px;
            }
        }
    }

    &__list {
        position: relative;
        list-style: none;
        padding: 0;
        margin: 0;
        counter-reset: account-counter;
        display: flex;
        flex-direction: column;
        margin-bottom: 41px;

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            z-index: 1;
            top: 30px;
            left: 16px;
            width: 1px;
            height: 78%;
            background-color: $color-neon-blue;
        }

        @include vp-767 {
            margin-bottom: 35px;
        }

        &.green {
            &::before {
                background-color: $color-shamrock;
            }

            .account-options__item::before {
                color: $color-shamrock;
                border-color: $color-shamrock;;
            }
        }
    }

    &__item {
        counter-increment: account-counter;
        position: relative;
        z-index: 1;
        padding-left: 48px;
        margin-bottom: 18px;
        font-size: 18px;
        line-height: 30px;
        color: $color-jaguar;

        &::before {
            content: counter(account-counter);
            color: $color-neon-blue;
            font-weight: 700;
            font-size: 14px;
            line-height: 30px;
            position: absolute;
            --size: 32px;
            width: var(--size);
            height: var(--size);
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background: $color-ghost-white;
            border: 1px solid $color-neon-blue;
            border-radius: 50%;
            text-align: center;

            @include vp-767 {
                top: 14px;
            }
        }

        @include vp-1023 {
            font-size: 14px;
            line-height: 22px;
            color: $color-jaguar;
        }

        @include vp-767 {
            margin-bottom: 25px;
        }
    }

    &__item:last-child {
        margin-bottom: 0;
    }

    &__button {
        box-shadow: 0px 13px 28px rgba(67, 75, 238, 0.5);
        border-radius: 12px;
        width: 300px;
        height: 80px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;

        @include vp-767 {
            display: none;
        }

        &.btn--green {
            box-shadow: 0px 13px 28px rgba(34, 214, 149, 0.5);
            font-size: 20px;
        }
    }

    &__image {
        display: block;

        & img {
            width: 100%;
            transform: translateY(14px);

            @include vp-1439 {
                width: 80%;
                transform: translateY(0);
                margin-left: auto;
            }

            @include vp-1023 {
                width: 100%;
            }
        }

        @include vp-767 {
            display: none;
        }
    }

    &__image-mobile {
        display: none;

        @include vp-767 {
            display: block;
            width: 100%;
            padding-left: 10px;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
