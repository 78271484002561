.new-order {
    font-size: 18px;

    .choose-your-language {
        display: none;
    }

    @include vp-767 {
        font-size: 14px;
    }

    .form__row {
        display: block;
    }

    .new-order__form legend {
        margin-bottom: 8px;
    }

    .new-order__form {
        .date-field label input {
            padding: 15px 60px;
        }
    }

    &__wrapper {
        padding: 24px 0 78px;
        position: relative;
        &--first {
            padding-top: 54px;
        }

        @include vp-1439 {
            padding: 24px 0;
        }

        @include vp-1023 {
            padding: 24px 0;
        }

        @include vp-767 {
            padding: 24px 0 20px;
        }
    }

    &__header {
        padding-top: 62px;
        margin-bottom: 36px;

        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include vp-1439 {
            padding-top: 60px;
        }

        @include vp-1023 {
            padding-top: 50px;
        }

        @include vp-767 {
            margin-bottom: 16px;
            padding-top: 15px;
        }

        .title {
            padding-right: 32px;

            @include vp-767 {
                min-width: 100%;
            }
        }

        .title,
        .subtitle {
            min-width: 55%;
            max-width: 75%;

            @include vp-767 {
                min-width: 100%;
                padding-right: 0;
            }
        }

        .subtitle {
            margin-top: 10px;

            @include vp-767 {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    &__btn {
        margin-left: auto;
        transform: translateY(5px);

        @include vp-767 {
            display: none;
        }
    }

    .subtitle,
    &__form {
        max-width: 832px;
    }

    .new-order-start {
        margin-bottom: 36px;

        @include vp-767 {
            margin-bottom: 0;
        }
    }

    .new-order-start__toggle {
        @include vp-767 {
            width: 100%;
        }
    }

    // .new-order-field {
    //   margin-bottom: 24px;
    //   // padding: 8px 20px;
    //   // border: 1px solid $color-link-water;
    //   // border-radius: 12px;
    // }

    // .new-order-start__toggle {
    //   max-width: 100%;
    //   margin: 0 9px 12px 0;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   outline: 2px solid red;
    // }

    .custom-input label input {
        padding: 15px 20px;
        // padding: 14px 20px 14px 47px;
    }

    .custom-input.new-order-start__search-field label input {
        padding: 6px 12px;
        border: none;
    }

    .custom-toggle label span {
        @include vp-767 {
            font-size: inherit;
        }
    }

    .specialists__wrapper {
        &--create-order {
            .slide-block {
                margin-bottom: 23px;
            }

            @media (max-width: 1439px) {
                .slider-block--row-column {
                    flex-direction: row;
                }
            }
        }
    }

    .specialists__buttons {
        margin-bottom: 32px;

        .form__row {
            display: flex;
        }

        .popover__tip {
            background-color: $color-sky-blue;
            left: 0;
            right: 0;
        }

        .popover::after {
            background-color: $color-sky-blue;
        }
    }

    .new-order__preview {

        p {
            span {
                font-weight: bold;
            }
        }

        .new-order__specialists {
            display: flex;
            align-items: center;
            gap: 20px;

            .output__list {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                list-style: none;
            }

            @media (max-width: 1023px) {
                .output__list {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }
            .output__item {
                position: relative;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ffffff;
                border-radius: 50%;
            }
            .output__item img {
                width: 90%;
                height: 90%;
                object-fit: cover;
                border-radius: 50%;
            }
            .output__item:not(:first-child) {
                margin-left: -20px;
            }
            .output__text {
                max-width: max-content;
                margin: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 34px;
            }
            @media (max-width: 1023px) {
                .output__text {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                }
            }
            .output__link {
                position: relative;
                font-size: 16px;
                line-height: 24px;
                color: #434bee;
            }
            .output__link::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.2);
            }
            .output__item--link .output__link {
                display: none;
            }
            .output__item--link {
                position: relative;
            }
            @media (max-width: 1023px) {
                .output__item--link .output__quantity {
                    display: none;
                }
                .output__item--link .output__link {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 90%;
                    height: 90%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #434bee;
                    background-color: #d9dbfc;
                    border-radius: 50%;
                }
            }
            .output__quantity {
                font-size: 12px;
                line-height: 20px;
                font-weight: 500;
                color: #434bee;
            }
        }

        .new-order__notice {
            background-color: #f5f5fa;
            padding: 10px;
            padding-left: 20px;
            margin-bottom: 20px;
            border-radius: 12px;
        }
    }
}

.new-order__authorization {
    &-heading {
        margin: 0;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: $color-jaguar;
    }

    &-text {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: $color-black-russian;
    }
}
