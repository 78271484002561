.prof-services {

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin-bottom: 7px;
    font-size: 16px;
    line-height: 24px;
    color: $color-jaguar;
  }
}
