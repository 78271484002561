.table {
  line-height: 2.5;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    flex: 1;
    padding: 0 16px;
  }

  td:not(:first-child) {
    flex-basis: 75px;
  }

  tr {
    display: flex;
    background-color: $color-ghost-white;
    border-radius: 4px;
  }

  tr:not(:last-child) {
    margin-bottom: 2px;
  }

  .custom-toggle label span {
    font-size: 16px;
    line-height: 34px;
  }
}
