.pinned-chats {
    position: sticky;
    top: 0;
    right: 0;

    .browser-firefox & {
        position: relative;
    }

    @include vp-1199 {
        position: static;

        .browser-firefox & {
            position: static;
        }
    }

    &__button {
        position: relative;
        padding: 0;
        border: none;
        background-color: inherit;

        @include vp-1199 {
            padding: 15px 33px 15px 24px;
            border: 1px solid $color-link-water;
            border-radius: 12px;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-icon {
            display: none;

            @include vp-1199 {
                display: block;
            }
        }

        .pinned-chats__msg-count {
            display: none;
            position: absolute;
            right: 23px;
            bottom: 10px;

            @include vp-1199 {
                display: block;
            }
        }
    }

    &__title {
        display: block;
        margin: 0;
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
        color: $color-jaguar;
        margin-bottom: 11px;

        @include vp-1199 {
            font-size: 16px;
            line-height: 26px;
            margin: 0;
        }
    }

    &__wrapper {
        @include vp-1199 {
            display: none;
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        border: 1px solid $color-link-water;
        border-radius: 24px;
        max-width: 264px;

        @media (max-width: 1585px) {
            max-width: 100%;
        }

        @include vp-1199 {
            border: none;
            max-width: unset;
        }
    }

    &__item {
        padding: 16px 16px 24px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        @include vp-767 {
            padding-right: 0;
        }

        &:first-of-type {
            padding: 24px 16px 24px;

            @include vp-767 {
                padding-right: 0;
            }
        }

        &:last-of-type {
            padding: 16px 16px 24px;

            @include vp-767 {
                padding-right: 0;
            }
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid $color-link-water;
        }

        &-heading {
            display: inline-block;
            margin: 0;
            margin-bottom: 4px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $color-jaguar;
        }

        &-button {
            padding: 0;
            border: none;
            background-color: inherit;
        }

        &-name {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $color-gray-chateau;
        }
    }

    &__msg-count {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: $color-default-white;
        min-width: 20px;
        min-height: 20px;
        background-color: $color-neon-blue;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &--grey {
            color: $color-gray-chateau;
            background-color: $color-ghost-white;
        }
    }

    &__top-wrapper,
    &__bottom-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }

    &__item.is-current {
        background-color: $color-ghost-white;

        @include vp-1199 {
            background-color: transparent;
        }
    }

    &__mobile-button {
        display: none;
    }
}

.is-open {
    @include vp-1199 {
        .pinned-chats__button {
            display: none;
        }

        .pinned-chats__mobile-button {
            display: block;

            .pinned-chats__mobile-title {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                max-width: max-content;
                margin: 0 0 4px;
                padding: 0 0 0 10px;
                font-weight: 800;
                font-size: 18px;
                line-height: 26px;
                color: $color-jaguar;
                border: none;
                background-color: transparent;
                cursor: pointer;

                &:hover,
                &:focus {
                    opacity: 0.8;
                    transition: opacity $trans-default;
                }
            }

            svg {
                width: 9px;
                height: 18px;
                margin-right: 17px;
                transform: rotate(180deg);
            }
        }

        .pinned-chats__wrapper {
            display: block;
            height: auto;
            padding: 16px;

            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            overflow-y: auto;
            background-color: $color-default-white;
            border-radius: none;
            background-color: white;

            .pinned-chats__item-button {
                margin-left: 4px;
            }
        }
    }
}
