.reviews {
  &__title {
    margin-bottom: 24px;

    @include vp-767 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__item:not(:last-child) {
    margin-bottom: 20px;

    @include vp-767 {
      margin-bottom: 12px;
    }
  }

  &__btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;

    @include vp-767 {
      padding-bottom: 12px;
      font-size: 12px;
    }
  }
}
