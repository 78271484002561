.my-orders {
    .pinned-chats {
        position: unset;
        margin-bottom: 18px;
    }

    .pinned-chats__title {
        margin-bottom: 8px;
        display: block;
        z-index: 1;
    }

    .pinned-chats__list {
        @media (max-width: 1585px) {
            max-width: 264px;
        }
        @include vp-1199 {
            max-width: unset;
        }
    }

    .cards-orders {
        @media (max-width: 1585px) {
            margin-right: 20px;
        }

        @include vp-1199 {
            margin-right: 0;
        }
    }

    .toggle-filter {
        @include vp-1199 {
            margin-bottom: 10px;
            min-height: 66px;
        }
    }

    .cards-orders {
        @include vp-1199 {
            margin-top: 20px;
        }

        &__item-info-additional {
            min-width: 39%;

            .review-card {
                max-width: unset;
            }

            .link {
                font-size: 12px;
            }

            .popover__tip {
                font-size: 16px;
                line-height: 24px;
            }

            .cards-orders__buttons {
                margin-left: auto;
            }

            .cards-orders__btn {
                display: block;
                margin-left: auto;
                min-width: 183px;
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }
    }


    .cards-orders__item-bottom-wrapper {
        margin-bottom: 26px;
    }

    .review-card {
        max-width: 264px;
    }

    .reviews {
        .title--h4 {
            margin-bottom: 14px;
        }

        @include vp-1199 {
            display: none;
        }
    }

    .cards-orders__item {
        padding-bottom: 40px;
    }

    .cards-orders__list {
        margin-bottom: 0;
    }
}
