.find-pro {
    position: relative;
    padding-top: 40px;
    padding-bottom: 164px;

    @include vp-1023 {
        padding-top: 13px;
        padding-bottom: 30px;
    }

    @include vp-767 {
        padding-bottom: 9px;
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr max-content;
        column-gap: 20px;

        @include vp-1439 {
            display: block;
        }
    }

    &__wrapper {
        max-width: max-content;
        padding: 40px 0 0 0;

        display: grid;
        grid-template-columns: 270px auto;
        grid-template-rows: max-content;
        column-gap: 18px;

        @include vp-1919 {
            padding: 40px 0 0 0;
        }

        @include vp-1439 {
            max-width: 100%;
            padding: 40px 0 0 0;
        }

        @include vp-1199 {
            padding: 0;
            display: flex;
            flex-direction: column;

            .filter {
                order: 1;
            }

            .find-pro__title-wrap {
                order: 2;
            }

            .find-pro__content,
            .find-pro__content-missing {
                order: 3;
            }

            .content-missing {
                order: 3;
            }
        }
    }

    &__title-wrap {
        max-width: 1200px;
        grid-column: 1 / -1;
        grid-row: 1 / 2;

        @include vp-1439 {
            max-width: 100%;
        }
    }

    .title {
        margin-bottom: 10px;
    }

    &__text {
        margin: 0 0 57px;

        font-size: 16px;
        line-height: 30px;
        color: $color-jaguar;

        @include vp-767 {
            margin: 0 0 14px;
            font-size: 14px;
            line-height: 22px;
        }
    }

    .filter__block {
        margin-bottom: 25px;

        @include vp-1199 {
            margin-bottom: 0;
        }
    }

    &__profiles-list {
        margin: 0 0 24px;
        padding: 0;
        list-style: none;

        @include vp-1199 {
            padding: 0;
        }

        @include vp-1023 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
            row-gap: 20px;
        }

        @include vp-767 {
            margin-bottom: 10px;
            grid-template-columns:  1fr;
            row-gap: 13px;
        }

        .profile-card-container:not(:last-child) {
            margin-bottom: 20px;

            @include vp-1023 {
                margin-bottom: 0;
            }
        }
    }

    .specialists {
        &__item {
            width: 263px;
            margin-bottom: 23px;
        }

        &__item-wrapper {
            height: 192px;

            @include vp-1023 {
                height: 144px;
            }


            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @include vp-1439 {
            margin-top: 45px;

            &__list {
                flex-direction: row;
            }

            &__item {
                margin-right: 12px;
            }

            &__item:last-child {
                margin-right: 0;
            }

            &__item-skills-list {
                margin: 0;
            }

            &__item-country-list {
                margin: 0;
            }

            &__amountOfDeals {
                margin: 0;
            }
        }

        @include vp-1023 {
            overflow-x: scroll;

            &__item {
                max-width: 200px;
            }

            &__item-skills-item p {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }

    &__content-missing {
        display: none;
    }

    .keywords {
        display: block;
        font-weight: 600;
        line-height: 1.3;
        color: #434bee;
        font-size: 22px;
        margin-bottom: 7px;

        @include vp-1439 {
            font-size: 20px;
        }

        @include vp-1023 {
            font-size: 18px;
        }

        @include vp-767 {
            font-size: 14px;
        }
    }
}


.no-content {
    .find-pro__content {
        display: none;
    }

    .find-pro__content-missing {
        display: flex;
    }
}
