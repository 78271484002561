.promo-page {
  overflow: hidden;
  .title--h1 {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 46px;

    @include vp-767 {
      font-size: 28px;
      line-height: 36px;
    }
  }
  .promo__content-text {
    padding-bottom: 30px;
  }
  .promo__content-wrapper::after {
    @include vp-1023 {
      display: none;
    }
  }
  .promo__text {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 26px;

    &:last-child {
      margin-bottom: 0;
    }
    .ql-size-large{
      font-size: 18px;
      line-height: 26px;
    }
    @include vp-767 {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 24px;

      .ql-size-large{
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
