.avatar {
  background-color: $color-gun-powder;
  border-radius: 16px;

  flex-shrink: 0;

  width: 133px;
  height: 133px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include vp-767 {
    width: 64px;
    height: 64px;
  }
}

.avatar--rounded {
  border-radius: 50%;
}

.avatar--md {
  width: 120px;
  height: 120px;

  @include vp-767 {
    width: 64px;
    height: 64px;
  }
}

.avatar--sm {
  width: 64px;
  height: 64px;

  @include vp-767 {
    width: 48px;
    height: 48px;
  }
}

.avatar--xs {
  width: 48px;
  height: 48px;
}

.avatar--xxs {
  width: 40px;
  height: 40px;
}