.modal-specialist {
    .modal__content {
        width: 90%;
        max-width: 911px;
        padding: 88px 40px 80px;
        text-align: left;

        @include vp-767 {
            display: grid;
            grid-template-rows: min-content min-content 1fr;

            width: 100%;
            padding: 16px;
            // min-height: 100vh;
            border-radius: 0;
        }
    }

    .form form {
        @include vp-767 {
            display: flex;
            flex-direction: column;
            height: 100%;
            // max-width: calc(100vw - 32px);
        }
    }

    .modal__wrapper {
        @include vp-767 {
            padding: 0;
        }
    }

    .modal__content-title {
        margin: 0 0 24px;
        max-width: unset;
        text-align: left;

        @include vp-767 {
            padding-right: 24px;
        }
    }

    .title {
        color: $color-jaguar;
    }

    .form__field {
        @include vp-767 {
            margin-top: 0;
        }
    }

    .form__back-link.btn {
        margin-right: 24px;

        @include vp-767 {
            display: flex;
            margin-right: 0;
            margin-bottom: 8px;
            width: 100%;
            border: 1px solid $color-solitude;
        }
    }

    .form__controls {
        margin-top: 56px;

        @include vp-767 {
            position: unset;
            margin-top: auto;
            padding: 16px 0 0;
        }
    }

    .modal__close-btn {
        @include vp-767 {
            top: 16px;
            right: 6px;
        }
    }

    .btn--light {
        padding-top: 0;
        padding-bottom: 4px;
        padding-left: 5px;
        padding-right: 0;
    }

    .custom-input input,
    .custom-textarea textarea {

        &:hover,
        &:focus {
            border-color: $color-shamrock;
        }
    }

    .form__grid {
        padding-bottom: 25px;

        @include vp-767 {
            padding-bottom: 0;
        }
    }

    .new-order-start__toggle {
        margin-right: 14px;

        @include vp-767 {
            margin-right: 0;
        }
    }

    .modal__subtitle.title {
        padding-top: 8px;
        margin-bottom: 8px;
    }
}
