.dotted-menu {
    position: relative;
    cursor: pointer;

    span:not(.date-field__label) {
        display: block;
        font-size: 38px;
    }

    &:hover,
    &:focus-within {
        color: $color-neon-blue;
    }

    &__list {
        position: absolute;
        margin: 0;
        padding: 11px 0;
        top: 42px;
        right: -3px;
        width: 191px;
        background-color: $color-default-white;
        box-shadow: 0px 20px 76px rgba($color-gun-powder, 0.1);
        border-radius: 8px;
        list-style: none;
        z-index: 5;

        opacity: 0;
        pointer-events: none;

        &::before {
            content: "";
            position: absolute;
            top: -19px;

            width: 100%;
            height: 20px;
        }
    }

    &.is-active &__list {
        opacity: 1;
        pointer-events: all;
    }

    &__item {
        padding: 9px 0;

        &:hover,
        &:focus-within {
            background-color: rgba($color-neon-blue, 0.2);
        }
    }

    &__item:hover &__item-link,
    &__item:focus-within &__item-link {
        color: $color-neon-blue;
    }

    &__item-link {
        padding: 0 16px;
        font-size: 18px;
        line-height: 30px;
        color: $color-jaguar;
        font-weight: 400;
        text-transform: none;
    }
}
