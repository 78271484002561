.trigger-gpt {
    position: relative;
    color: #9B9EAC;
    font-size: 18px;
    width: 240px;
    border-radius: 12px;
    border: 1px solid #D2D5DD;
    background-color: #F4F4F6;
    cursor: pointer;
    margin: 0 8px;
    padding: 0;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 19px;
        border-radius: 12px;
    }

    &__icon {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
    }

    &__form {
        margin-top: 24px;
    }

    &__textarea {
        margin-bottom: 24px;
    }

    &__btn {
        padding: 18px 32px;
    }

    &.colored {
        width: 370px;
        padding: 2px;
        color: #9EA4A7;
        background: radial-gradient(92.79% 655.56% at 7.81% 7.21%, #434BEE 6.65%, #22D695 28.44%, #434BEE 71.49%, #22D695 100%);
        border: none;
        margin: 0;
        font-size: 24px;
    }

    &.colored & {
        &__inner {
            padding: 22px 28px;
            background-color: #fff;
        }

        &__icon {
            width: 32px;
            height: 32px;
        }
    }

    &--banner {
        width: 370px !important;
    }

    @media (max-width: 1023px) {
        font-size: 16px;

        &__icon {
            width: 20px;
            height: 20px;
        }
        &--banner {
            width: 100% !important;
        }
    }
}