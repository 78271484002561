.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: rgba(35, 35, 42, 0.4);
    height: 100vh;
    border: unset;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    // background-color: rgba($color-default-black, 0.8);
    transform: scale(1.2);
    visibility: hidden;
    opacity: 0;
    transition: opacity $trans-modal, transform $trans-modal, z-index 0.7s cubic-bezier(0.55, 0, 0.1, 1), height 0.7s cubic-bezier(0.55, 0, 0.1, 1), visibility $trans-modal;
    pointer-events: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;

    .browser-firefox & {
        // width: calc(4 / 3 * 100%);
        // left: calc(-1 / 6 * 100%);

        // @media (max-width: 1199px) {
        //     width: 100%;
        //     left: 0;
        // }
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100vh;
        padding: 60px 40px;

        @include vp-767 {
            padding: 60px 16px;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        cursor: pointer;
    }

    &__content {
        position: relative;
        background: $color-default-white;
        border-radius: 32px;
        text-align: center;

        max-width: 724px;
        padding: 85px 92px;

        background-color: $color-default-white;

        .browser-firefox & {
            -moz-transform: scale(0.75);
            -moz-transform-origin: 50% 0%;

            @media (max-width: 1199px) {
                -moz-transform: scale(1);
            }
        }

        @include vp-767 {
            width: 100%;
            padding: 30px 20px 20px;
        }
    }

    &__content-title {
        margin: 0 auto 24px;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        color: $color-jaguar;
        max-width: 536px;

        @include vp-767 {
            // max-width: 300px;
            max-width: 350px;
            margin: 0 auto 16px;
            font-size: 26px;
            line-height: 34px;
        }
    }

    &__content-text {
        margin: 0;
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: $color-jaguar;

        @include vp-767 {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
        }
    }

    .modal__content-button {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: $color-default-white;
        padding: 18px 32px;
        max-width: 180px;
        width: 100%;
        border-radius: 12px;

        &--background {
            background-color: $color-shamrock;
            margin-left: 16px;

            @include vp-767 {
                margin-left: 0;
                // margin-top: 16px;
            }
        }
    }

    &__close-btn {
        position: absolute;
        top: 44px;
        right: 40px;
        z-index: 1;

        width: 40px;
        height: 40px;
        padding: 0;

        background: $color-transparent;
        border: none;
        cursor: pointer;

        @include vp-767 {
            top: 2px;
            right: 20px;

            & svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    &--responsive {
        .modal__content {
            width: 100%;
        }
    }

    &--fit-content {
        .modal__content {
            width: auto;

            @include vp-767 {
                width: 100%;
            }
        }
    }

    &--no-scale {
        transform: none;
    }

    &--preload {
        transition: none;
    }

    &.is-active {
        transform: scale(1);
        visibility: visible;
        opacity: 1;
        z-index: 1000;
        min-height: 100%;
        pointer-events: auto;
    }
}

.modal__content-buttons {
    display: flex;
}

.modal__btn {
    flex: 1;

    &:not(:first-child) {
        margin-left: 8px;
    }
}

.radius {
    .custom-input__error {
        position: relative;
        bottom: 0;
    }
}

.modal__button--open {
    cursor: pointer;
}