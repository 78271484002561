.toggle-filter {
    position: relative;
    width: 100%;
    margin-bottom: 26px;
    padding: 12px 16px 12px 50px;

    display: none;
    align-items: center;
    justify-content: flex-start;

    font-size: 14px;
    line-height: 22px;

    color: $color-default-black;
    background-color: $color-ghost-white;

    border: none;
    border-radius: 12px;
    text-transform: capitalize;
    cursor: pointer;


    @include vp-1199 {
        display: flex;
    }

    svg {
        position: absolute;
        left: 16px;
        width: 24px;
        height: 24px;
    }

    span {
        position: absolute;
        right: 16px;
        width: 20px;
        height: 20px;

        font-weight: 500;
        font-size: 12px;
        line-height: 20px;

        color: $color-default-white;
        background-color: $color-neon-blue;
        border-radius: 50%;
    }

    &_scroll {
        @include vp-1199 {
            position: fixed;
            width: calc(100% - 24px);
        }
    }

    @include vp-767 {
        margin-top: 22px;
    }
}
