.modal__content-buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    .btn:last-of-type {
        margin-left: 20px;
    }

    .btn:first-of-type {
        margin-left: 0;
    }
}

.modal .modal__content-button {
    max-width: none;
    width: auto;
}

.profile__buttons-form {
    grid-column: 1;
    grid-row: 5;
    justify-self: flex-start;
    max-width: 477px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 45px;
    width: 100%;
}

.btn {
    border-radius: 12px;
}

.btn-confirm {
    margin-left: 12px;
}

.custom-input__error,
.custom-textarea__error {
    bottom: -16px;
}

.custom-toggle__error {
    bottom: -14px;
}

.grecaptcha-badge {
    margin-bottom: 1rem;
}

.grecaptcha-badge {
    display: none;
}

.form__note {
    margin-top: 15px;
}

.form fieldset {
    margin-bottom: 20px;
}

.form__row--h515 {
    width: 100%;
}

.confirm-field__btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 130px;
    height: 62px;
    color: $color-sweet-pink;
    display: grid;
    place-items: center;
    transition: color 0.3s ease;
}

.form__row--h515 fieldset {
    width: 100%;
}

.about-service-page .accordion__element-button {
    svg {
        margin-right: 20px;
        transform: none;
    }

    .customer {
        fill: $color-neon-blue;
    }

    .specialist {
        fill: $color-shamrock;
    }
}

.accordion__element-button-description {
    padding-left: 47px;
    margin-top: 40px;
    font-size: 18px;
}

.new-order-start__field {
    min-height: 62px;
}

.new-order-start__field-error {
    margin-top: 4px;
    font-size: 12px;
    line-height: 14px;
    color: #ffd8d8;
    transition: opacity 0.3s ease;
}

.custom-toggle {
    margin-top: 2px;
}

.new-order-start__field.is-invalid {
    background-color: #fff5f5;
    transition: background-color 0.3s ease;
}

.order-map {
    width: 100%;
    border-radius: 12px;
    height: 379px;

    &__link-wrap {
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;
        @include vp-767 {
            flex-direction: column;
            margin-bottom: 32px;
        }
    }

    &__text {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-gray-chateau;
        width: calc(50% + 22px);
        @include vp-767 {
            width: 100%;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        color: $color-neon-blue;
        cursor: pointer;
        margin-bottom: 32px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: calc(50% - 40px);
        @include vp-767 {
            width: 100%;
        }

        svg {
            margin-right: 10px;
            width: 16px;
            height: 20px;
        }
    }
}

.react-datepicker-wrapper {
    @include vp-767 {
        width: 100%;
    }
}

.date-start__choice-date-input,
.date-start__period-input {
    @include vp-767 {
        max-width: unset;
    }
}

.react-datepicker-popper {
    @include vp-767 {
        width: 100%;
    }
}

.react-datepicker__month-container {
    @include vp-767 {
        float: unset;
    }
}

.custom-input--small {
    max-width: 264px;
    @include vp-767 {
        max-width: unset;
    }
}

.react-datepicker {
    font-size: 16px;
    width: 100%;
    font-family: "SF Pro Display", "Arial", sans-serif;
    line-height: 30px;
    border: none;
    background-color: white;
    box-shadow: 0 20px 76px rgba(72, 73, 95, 0.1);
}

.react-datepicker__header {
    background-color: white;
    border: none;
    color: $color-gray-chateau;
}

.react-datepicker__day-name {
    color: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    text-transform: uppercase;
    width: 36px;
    height: 36px;
    align-content: center;
    display: inline-grid;
}

.react-datepicker__week {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: $color-jaguar;
}

.react-datepicker__day--selected {
    color: $color-neon-blue;
    border-radius: 4px;
    background: rgba(67, 75, 238, 0.1);
}

.react-datepicker__day {
    width: 36px;
    height: 36px;
    align-content: center;
    display: inline-grid;
}

.react-datepicker__navigation-icon {
    height: 18px;
}

.react-datepicker__navigation-icon::before {
    height: 14px;
    border-color: #23232a;
    width: 14px;
}

.select-day {
    padding: 0;
    width: 48px;
    height: 30px;
    box-shadow: 0 20px 76px rgba(72, 73, 95, 0.1);
}

.select-day .custom-select__indicator {
    margin-left: 0;
    width: 0;
    height: 0;
}

.select-day .custom-select__control {
    position: relative;
    border: 2px solid transparent;
    padding: 0 0 0 8px;
    height: 30px;
    border-bottom: 1px solid rgba(67, 75, 238, 0.2);
}

.select-day .custom-select__value-container {
    opacity: 1;
}

.select-day .custom-select__single-value {
    color: $color-neon-blue;
}

.select-day .custom-select__option {
    padding: 8px 5px;
    justify-content: center;
}

.select-day .custom-select__menu-list {
    overflow-x: hidden;
    border: none;
    background-color: white;
    z-index: 1;
}

.select-day .custom-select__menu-list::-webkit-scrollbar {
    width: 4px;
    /* ширина scrollbar */
}

.select-day .custom-select__menu-list::-webkit-scrollbar-track {
    background: transparent;
    /* цвет дорожки */
}

.select-day .custom-select__menu-list::-webkit-scrollbar-thumb {
    background-color: $color-gray-chateau;
    /* цвет плашки */
    border-radius: 20px;
    /* закругления плашки */
}

.select-week-day {
    width: 135px;
}

.select-day .custom-select__menu {
    height: 290px;
    overflow: auto;
    overflow-y: hidden;
}

.custom-toggle {
    align-items: center;
}

.promo__background-image-wrapper {
    overflow: hidden;
    margin-right: 0;
}

.account-options__image {
    overflow: hidden;
}

.icon {
    fill: currentColor;
}

.rating {
    &--red {
        color: $color-watermelon;
    }
}

.avatar {
    background-color: unset;
}

.reviews__btn {
    margin-top: 20px;
}

.catalog-page .accordion__element-button {
    align-items: center;
    display: flex;
}

.catalog-page .accordion__element-parent {
    display: block;
}

.catalog-page .accordion__element-button-icon {
    transform: none;
    top: 0;
    position: initial;
    margin-right: 38px;
}

.accordion__element .accordion__content-parent {
    .accordion__element-button-icon {
        margin-right: 19px;
    }
}

.accordion__element-button-icon.is-open {
    fill: $color-neon-blue;
}

.accordion__element-parent-button-title.is-open {
    color: $color-neon-blue;
}

.accordion__element-parent-button {
    align-items: center;
    display: flex;
}

.catalog-page .accordion__element-button {
    padding-left: 0;
}

.visibility {
    visibility: hidden;
}

.accordion__wrapper {
    margin-top: 38px;
    margin-bottom: 28px;

    .accordion {
        .accordion__element.accordion__content-parent {
            margin-bottom: 16px;
        }
    }
}

.catalog-page .accordion__element-parent-button {
    padding-left: 71px;
}

.specialist-profile__header .upload-foto__btn-input--file label:hover {
    background-color: rgba(34, 214, 149, 0.5);
    transition: background-color 0.3s ease;
}

.specialist-profile {
    background-color: #ffffff;

    .warn-modal {
        .btn.modal__content-button {
            background-color: #22d695;
        }
    }

    @media (hover: hover),
    screen and (min-width: 0 \0) {

        .address-field__button:hover,
        .address-field__button:focus {
            color: #22d695;
        }
    }

    .email-field {
        margin-bottom: 48px;
    }

    .link {
        color: #22d695;
    }

    .custom-toggle--checkbox input:checked+.custom-toggle__icon {
        background-color: #d3f7ea;
    }

    .custom-input label input:hover,
    .custom-input label input:focus,
    .custom-input label textarea:hover,
    .custom-input label textarea:focus,
    .custom-textarea label input:hover,
    .custom-textarea label input:focus,
    .custom-textarea label textarea:hover,
    .custom-textarea label textarea:focus {
        border-color: #22d695;
    }
}

.registration-page.specialist-page {
    background-color: #d3f7ea;

    .custom-input label input:hover,
    .custom-input label input:focus,
    .custom-input label textarea:hover,
    .custom-input label textarea:focus,
    .custom-textarea label input:hover,
    .custom-textarea label input:focus,
    .custom-textarea label textarea:hover,
    .custom-textarea label textarea:focus {
        border-color: #22d695;
    }

    .btn+button[type="submit"] {
        background-color: #22d695;
    }
}

.registration-page.specialist-page.registration-page::after {
    background-color: #d3f7ea;
}

.aboutme-form {

    .custom-input label input:hover,
    .custom-input label input:focus,
    .custom-input label textarea:hover,
    .custom-input label textarea:focus,
    .custom-textarea label input:hover,
    .custom-textarea label input:focus,
    .custom-textarea label textarea:hover,
    .custom-textarea label textarea:focus {
        border-color: #22d695;
    }
}

.custom-input {
    flex-direction: column;

    label {
        position: relative;
    }

    .symbol-counter {
        position: absolute;
        right: 10px;
        bottom: 0;

        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #9ea4a7;
    }
}

.upload-foto__btn {
    .custom-input {
        label {
            position: absolute;
        }
    }
}

.max-length-error {
    color: #ef2e2e;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.go_left {
    left: -18px;
    transform: rotate(180deg);
}

.transition-transform {
    transition: transform 0.3s ease;
}

.rotate180 {
    transform: rotate(180deg);
}

.pagination__link {
    svg {
        fill: $color-gray-chateau;
    }
}

.date-field__icon {
    width: auto;
    height: auto;
}

.filter-orders__date .date-field__icon,
.filter-orders__date .date-field__label {
    top: 19px;
}

.btn--view-all {
    border-radius: 0;
}

.dotted-menu__list {
    width: 240px;
}

.dropdown-menu__list {
    position: absolute;
    margin: 0;
    padding: 11px 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 20px 76px rgb(72 73 95 / 10%);
    border-radius: 8px;
    list-style: none;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
}

.is-active {
    .dropdown-menu__list {
        opacity: 1;
        pointer-events: all;
    }
}

.dropdown-menu__item {
    padding: 9px 15px;
}

.dropdown-menu__item:hover {
    background-color: #f4f4f6;
    color: #17171b;
}

.registration-form__input-phone {
    margin-bottom: 32px;
}

.specialist.new-order__form.form {

    .new-order-start__field:hover,
    .new-order-start__field:focus {
        border-color: #22d695;
    }

    .new-order-start__toggle.is-active {
        background-color: #d3f7ea;
    }

    .new-order-start__toggle:focus {
        background-color: #d3f7ea;
    }

    @media (hover: hover),
    screen and (min-width: 0) {
        .new-order-start__toggle:hover {
            background-color: #d3f7ea;
        }
    }
}

.popover__tip {
    min-width: 274px;
}

.accordion-form__item-subtitle svg {
    transform: rotate(0);
}

.accordion-form__item-subtitle:hover svg,
.accordion-form__item-subtitle:focus svg {
    transform: rotate(180deg);
}

.accordion-form__item-subtitle.is-open svg {
    fill: $color-neon-blue;
    transition: fill 0.3s ease;
    transform: rotate(180deg);
}

.user-nav__list {
    min-width: 234px;
}

.ql-size-large {
    font-size: 1.5rem;
}

.ql-size-small {
    font-size: 14px;
    line-height: 18px;
}

.modal-specialist {

    .new-order-start__field:hover,
    .new-order-start__field:focus {
        border-color: #22d695;
    }

    .new-order-start__toggle.is-active {
        background-color: #d3f7ea;
    }

    .new-order-start__toggle:focus {
        background-color: #d3f7ea;
    }

    @media (hover: hover),
    screen and (min-width: 0) {
        .new-order-start__toggle:hover {
            background-color: #d3f7ea;
        }
    }

    &.customer {

        .custom-input input:hover,
        .custom-input input:focus {
            border-color: #434bee;
        }
    }
}

.company-data .custom-toggle--radio input:checked+.custom-toggle__icon {
    background-color: rgba(34, 214, 149, 0.2);
}

.company-data input:checked+.custom-toggle__icon::before {
    background-color: #22d695;
}

.company-data {
    .profile__button[type="submit"] {
        background-color: #22d695;
        color: #ffffff;
    }

    .custom-input {
        input:disabled {
            background-color: #f4f4f6;
        }
    }
}

.registration-page--specialist {

    .custom-input label input:hover,
    .custom-input label input:focus,
    .custom-input label textarea:hover,
    .custom-input label textarea:focus,
    .custom-textarea label input:hover,
    .custom-textarea label input:focus,
    .custom-textarea label textarea:hover,
    .custom-textarea label textarea:focus {
        border-color: #22d695;
    }

    .upload-foto__btn-input--file label:hover,
    .upload-foto__btn-input--file label:focus-within {
        background-color: rgba(34, 214, 149, 0.5);
        transition: background-color 0.3s ease;
    }
}

.order-information__status--fulfil {
    color: #ffffff;
    background-color: #22d695;
}

.order-information__status--blue {
    color: $color-deep-sky-blue;
    background-color: rgba($color-deep-sky-blue, 0.2);
}

.order-information--spec-check {
    color: #434bee;
    background-color: rgba(67, 75, 238, 0.2);
}

.profile-card__button {
    &--cancel {
        display: flex;
        text-align: center;
    }

    &--confirm {
        text-align: center;
    }
}

.closed-order-done {
    .pinned-chats {
        grid-row: 3;
    }
}

.order-information__status {
    &--spec-check {
        color: $color-neon-blue;
        background: rgba(67, 75, 238, 0.2);
    }

    &--red {
        color: $color-torch-red;
        background-color: rgba(236, 14, 40, 0.1);
    }
}

.modal-order-date .custom-input button {
    top: 20px;
    right: 0;
}

.react-datepicker__day--keyboard-selected {
    color: $color-neon-blue;
    background: rgba(67, 75, 238, 0.1);
}

.pagination__item--prev,
.pagination__item--next {
    cursor: pointer;
}

.notifications {
    .title-container {
        max-width: 832px;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        gap: 30px;
        justify-content: space-between;
    }

    .title {
        margin: 0;
    }

    .notice {
        &__text {
            display: block;
            max-height: none;
        }

        &__text.limit {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            -moz-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            line-clamp: 3;
            box-orient: vertical;
        }
    }

    .notice.viewed {
        border: 1px solid #d2d5dd;
    }

    .notice.no-viewed {
        border: 2px solid #434bee;
    }
}

.btn {
    &--text {
        background-color: transparent;
        margin: 0;
        color: #9ea4a7;
        text-transform: none;
        padding: 0;
        justify-content: flex-start;
        text-align: start;
        font-family: "SF Pro Display", "Arial", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        cursor: unset;
    }
}

.profile-card__button {
    max-width: 219px;
    @include vp-767 {
        max-width: 100%;
    }
}

.rating svg {
    width: auto;
    height: auto;
}

.review-block {
    .rating {
        flex-direction: row;
    }
}

.closed-order {
    .pinned-chats {
        grid-row: 3;
    }

    .order-information-details {
        grid-row: 1/5;

        @include vp-1199 {
            grid-row: 3/5;
        }
    }
}

.pinned-chats {
    width: 100%;
    z-index: 1;

    &__list {
        background-color: white;
    }
}

.profile-card__button {
    &--chat--specialist {
        background: #d3f7ea;
        display: flex;
        align-items: center;
        color: $color-jaguar;
        min-width: 263px;

        .pinned-chats__msg-count {
            background-color: #22d695;
            border-radius: 50%;
            height: 20px;
            min-width: 20px;
        }
    }

    &--chat--specialist--start {
        background: $color-shamrock;
        border-color: $color-shamrock;
        color: white;
        min-width: 263px;
    }

    &--chat--customer {
        margin: 10px 0;
        min-width: 263px;
    }
}

.active-order-2 {
    .pinned-chats {
        grid-row: 3;
    }

    .row-2 {
        grid-row: 2;

        @include vp-1199 {
            grid-row: 1;
        }
    }

    .order-information-details {
        grid-row: 1/5;

        @include vp-1199 {
            grid-row: 3/5;
        }
    }
}

.active-order__add-specialist-button {
    padding: 18px 32px;
}

.active-order__buttons > .btn--transparent,
.active-order__buttons > .active-order__add-specialist-button {
    grid-row: 2/2;

    @include vp-767 {
        width: 100%;
    }
}

.closed-order-done {
    .order-information-details {
        grid-row: 1/10;

        @include vp-1199 {
            grid-row: 10/10;
        }
    }

    .pinned-chats {
        grid-row: 3;
    }
}

.profile-mini-card-contacts {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $color-gray-chateau;
    word-break: break-all;
}

.profile-mini-card-contacts:nth-last-child(2) {
    margin-top: 16px;
}

.specialist-button__chat {
    max-width: none;
    background: #d3f7ea;
    color: #23232a;

    .pinned-chats__msg-count {
        margin-left: 8px;
        background-color: #22d695;
    }
}

.notifications-specialist {
    .notice.no-viewed {
        border: 2px solid #22d695;
    }

    .system-notification {
        background-color: rgba(34, 214, 149, 0.1);
    }

    .link {
        color: #22d695;
    }

    .pagination .is-active {
        color: #22d695;
        background: rgba(34, 214, 149, 0.1);
    }

    .pagination__link:hover {
        color: #22d695;
    }
}

.modal-new-card {
    .modal__content-title {
        margin-bottom: 0;
    }
}

.select-cards__container {
    min-width: 562px;

    label {
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
        color: #23232a;
    }

    .select-cards__prefix__control {
        margin-bottom: 20px;
        padding: 8px 5px;
        border: 1px solid #e5e6eb;
        border-radius: 12px;
        box-shadow: none !important;

        &:hover {
            border: 1px solid #e5e6eb;
        }
    }

    .select-cards__prefix__menu-list {
        padding: 0;

        .select-cards__prefix__option {
            background-color: transparent;

            &:first-child {
                border-radius: 4px 4px 0 0;
            }

            &:last-child {
                border-radius: 0 0 4px 4px;
            }
        }

        .select-cards__prefix__option--is-focused {
            background-color: rgba(34, 214, 149, 0.1);
        }

        .select-cards__prefix__option--is-selected {
            color: #ffffff;
            background-color: #22d695;
        }
    }
}

.not-reviews__block {
    margin: 80px 0;

    p {
        text-align: center;
        font-weight: 800;
        font-size: 32px;
        line-height: 48px;
        color: #d2d5dd;
    }
}

.credit-card {
    .card-number {
        flex-grow: 1;
        width: 100%;
        padding: 15px 24px;
        font-size: 18px;
        line-height: 30px;
        background-color: #ffffff;
        border: 1px solid #dadce3;
        border-radius: 12px;
        outline: none;
        transition: border-color 0.3s ease;
    }

    .card-expiry {
        flex-grow: 1;
        width: 100%;
        padding: 15px 24px;
        font-size: 18px;
        line-height: 30px;
        background-color: #ffffff;
        border: 1px solid #dadce3;
        border-radius: 12px;
        outline: none;
        transition: border-color 0.3s ease;
    }

    .card-cvc {
        margin-left: 8px;
        flex-grow: 1;
        width: 100%;
        padding: 15px 24px;
        font-size: 18px;
        line-height: 30px;
        background-color: #ffffff;
        border: 1px solid #dadce3;
        border-radius: 12px;
        outline: none;
        transition: border-color 0.3s ease;
    }

    .card-number,
    .card-expiry,
    .card-cvc {
        iframe {
            @media (min-width: 1200px) {
                min-height: 22px;
            }
         }
    }
}

.filter-orders.not-orders {
    width: 0;
}

.pinned-chats.hide {
    visibility: hidden;
}

.spec-page {
    .warn-modal {
        .btn.modal__content-button {
            background-color: #22d695;
        }
    }
}

.spec-page__offers.specialist-offers {
    .offer-card__btn {
        margin-left: 0;
        margin-bottom: 35px;
    }
}

.spec-page__new-offer.new-offer {
    .specialist-select__prefix__menu {
        width: 380px;
    }

    .new-offer-form .custom-toggle--radio:not(.custom-radio) .custom-toggle__icon {
        transform: translateY(12%);
    }

    .img-cover {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 18px;
    }

    .input-mailing__mailing {
        width: 380px;

        &-description {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #9ea4a7;
        }
    }

    .form__controls {
        display: flex;
        gap: 10px;
    }
}

.chat__wrapper {
    .chat__window {
        .chat__info {
            width: 100%;
            max-width: none;
            display: flex;
            flex-direction: column;

            .chat__text::not(.highlight) {
                align-self: center;
            }

            .chat__text.highlight {
                padding: 32px 72px;
                align-self: flex-start;
                max-width: 545px;
                background: #F4F4F6;
                border-radius: 12px;
                font-size: 1rem;
                line-height: 24px;
                color: #23232A;

                .title {
                    margin-bottom: 8px;
                }

                .avatar {
                    width: 58px;
                    height: 58px;
                    margin: 3px auto;
                    border-radius: 50%;
                    object-position: center;
                    object-fit: cover;
                }

                .full_name {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: center;
                    color: #23232A;
                    margin: 0;
                }

                .phone {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    color: #23232A;
                    margin: 0;
                }
            }
        }
    }

    .chat__form.form {
        position: relative;

        .custom-input {
            position: relative;

            .btn-send__message {
                position: absolute;
                right: 0;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                outline: none;
                background: transparent;
                border: none;
                border-radius: 50%;
                padding: 0;
                margin: 0;
                width: 44px;
                height: 44px;
                cursor: pointer;
                transition: color .3s, transform .3s;
                color: #434BEE;

                &:hover {
                    transform: translateY(-50%) translateX(8%);
                }

                &.disabled {
                    color: #0001 !important;
                }
            }
        }

        .chat__btn {
            &.specialist {
                background: rgba(34, 214, 149, 0.2);
                color: #29BA85;
            }

            &.customer {
                background: #D9DBFC;
                border: 1px solid #D2D5DD;
                color: #434BEE;
            }
        }
    }

    .user-card__nav {
        &:hover::after {
            content: "";
            position: absolute;
            height: 120%;
            top: 0;
            right: 0;
            min-width: 250px;
        }
    }
}

.notification-lexicon__error {
    max-width: 100%;
    position: absolute;
    bottom: 115%;
    left: 0;
    padding: 16px;
    background: #ffe7e7;
    box-shadow: 0px 4px 12px rgba(27, 25, 25, 0.12);
    border-radius: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ef2e2e;
}

.notification-lexicon__error.static {
    position: relative;
    margin: 15px 0;
}

.eliminare-comments__btn {
    padding: 22px 38px;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.03em;
    white-space: nowrap;

    @media (max-width: 480px) {
        margin: 10px auto 0;
        padding: 12px 10px;
        border-radius: 8px;
        font-size: 12px;
    }
}

.modal-notify__administrator {
    .custom-input.custom-input__specialist label textarea:hover {
        border-color: #22d695;
    }

    .custom-input.custom-input__specialist label textarea:focus {
        border-color: #22d695;
    }

    .modal__content-buttons {
        margin-top: 24px;

        .btn--green {
            background-color: #22d695 !important;
        }
    }
}

.useful-info__list {
    gap: 8px;
    justify-content: space-between;
}

.specialists__item {
    list-style: none;

    &-wrapper {
        img {
            object-fit: cover;
            width: 255px;
            height: 192px;
        }
    }
}

.new-order {
    .useful-info__list {
        gap: 20px;
        justify-content: flex-start;

        .specialists__item {
            max-width: 264px;
            width: 264px;
        }
    }

    .new-order__form .date-field label input {
        padding: 15px 15px 15px 85px;
    }
}

.about-service-page {
    .page__wrapper {
        padding-left: 1%;
        padding-right: 1%;
        display: flex;
        justify-content: space-between;
        gap: 120px;
    }

    .specialists__wrapper {
        flex: 0 0 272px;

        @media (max-width: 1023px) {
            grid-column: 1/-1;
            grid-row: 2;
            margin-top: 46px;
            gap: 8px;
        }

        .useful-info__item {
            margin-bottom: 20px;
        }
    }
}

.active-order {
    .advertising-container_active {
        grid-column: 1/-1;

        &__title {
            font-weight: 800;
            font-size: 46px;
            line-height: 48px;
            color: #17171b;
        }
    }

    .cards-orders__btn {
        width: 100%;
    }

    .profile-mini-card {
        width: 100%;
    }
}

.service-partner__list {
    gap: 8px;
}

.useful-info__item {
    overflow: hidden;
    flex: 0 0 auto;
    max-width: 272px;
    width: 272px;
    margin: 0;
    list-style: none;

    @include vp-767 {
        width: 201px;
    }

    &__link {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.prices-page {
    .page__grid {

        .useful-info__list {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: flex-start;
        }

        @media (max-width: 1023px) {
            .service-partner {
                grid-column: 1/-1;
                grid-row: 2;
                margin-left: 32px;
                margin-top: 46px;
            }
        }
    }
}

.user-panel__link_green {
    background-color: #22d695;
}

.specialist-chat {
    .link {
        color: #22d695;
    }

    .pinned-chats__msg-count {
        background-color: #22d695;
    }

    @media (hover: hover),
    screen and (min-width: 0 \0) {

        .user-nav__btn:hover,
        .user-nav__btn:focus {
            color: #22d695;
        }
    }

    .popover::after,
    .popover__tip {
        background-color: #d3f7ea;
    }

    .modal-complaint .modal__content-button--background {
        background-color: #22d695;
    }

    .custom-input label input:hover,
    .custom-input label input:focus,
    .custom-input label textarea:hover,
    .custom-input label textarea:focus,
    .custom-textarea label input:hover,
    .custom-textarea label input:focus,
    .custom-textarea label textarea:hover,
    .custom-textarea label textarea:focus {
        border-color: #22d695;
    }

    .chat__wrapper .chat__form.form .custom-input .btn-send__message {
        color: #22d695;
    }

    @media (hover: hover),
    screen and (min-width: 0 \0) {

        .user-nav__btn.green:hover,
        .user-nav__btn.green:focus {
            color: #22d695;
        }
    }
}

.loader.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: #00000040;
    backdrop-filter: blur(5px);
}


.cookies {
    min-height: 162px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 480px) {
        min-height: auto;
        padding: 16px;
    }

    .unverified-notification {
        z-index: 9;

        .cookies__container {
            justify-content: center;

            .cookies__text {
                font-size: 20px;
                font-weight: 500;
            }
        }
    }

    .services__container {
        justify-content: center;
        max-width: 80%;
        display: flex;
        width: 100%;

        .services__text {
            font-size: 20px;
            font-weight: 500;

            @include vp-1439 {
                padding-right: 5%;
                line-height: 1.5;
            }

            @include vp-1023 {
                padding-right: 16px;
                font-size: 14px;
            }

            @include vp-767 {
                margin-bottom: 32px;
                text-align: center;
            }
        }
    }

    .suspended-notification {
        z-index: 11;
    }

    &.specialist-draft-notification {
        .cookies__container {
            max-width: 80%;
            width: 100%;
            justify-content: center;
            align-items: center;

            @media (max-width: 1439px) {
                max-width: 80%;
                width: 100%;
                justify-content: center;
                align-items: center;
            }

            @media (max-width: 1024px) {
                flex-wrap: wrap;

                .cookies__text {
                    padding-right: 0;
                }
            }
        }

        .cookies {
            padding: 20px 32px 15px;

            &__container-text {
                position: relative;
                padding-bottom: 20px;
            }

            &__link-more {
                display: inline;
                margin-left: 5px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0px);
            }

            &__text {
                margin-bottom: 0;

                & span {
                    display: block;
                    margin-bottom: 5px;
                }

                &--expanded {
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height $trans-default;
                    margin-bottom: 0;
 
                    &.show {
                        max-height: 700px;
                    }
                }
            }

            &__btn {
                margin-top: 22px;
                padding: 15px 20px;
            }
        }
    }
}

.cookies .cookies .modal__content-button--background-customer {
    background-color: $color-neon-blue;
    margin-left: 16px;
}

.advertising-list {
    padding: 0;
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 20px;

    @media (max-width: 1024px) {
        display: flex;
        gap: 20px;
        flex-direction: row;
        overflow: auto;
    }

    &.create-order {
        display: block;
    }

    .specialists__item {
        list-style: none;
        width: auto;
        max-width: 272px;
    }

    .useful-info__item {
        list-style: none;
        width: auto;
        max-width: 272px;

        @include vp-767 {
            max-width: 201px;
        }
    }
}

.advertising-list.reviews-page {
    margin-top: 80px;
}

.advertising-list.my-orders {
    grid-column: 1/-1;
}

.specialist-profile__controls {
    .btn {
        margin-bottom: 20px;
        width: auto;
        &:not(:last-child) {
            margin-right: 20px;
        }
        @include vp-767 {
            padding: 18px 32px;
            width: 100%;
            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
    @include vp-767 {
        .specialist-profile__btn-text {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.specialist-profile__controls .specialist-profile__block-btn {
    margin: 0 0 0 24px;
}

.user-nav__btn.disabled {
    color: #0004;
    pointer-events: none;

    &:hover,
    &:focus {
        color: #0004;
    }
}

.text-reason__suspended {
    font-weight: bold;
    font-style: italic;
    font-size: 1rem;
    padding: 1px 5px;
}

.page--specialists .price-deals .price-deals__info-list .price-deals__info-item {
    font-size: 18px;
}

.active-order__buttons {
    gap: 10px;

    @include vp-1919 {
        max-width: 2 * 264px + 20px;
        margin-bottom: 12px;
      }
}

.offer-card__column {
    flex: 0 0 auto;
}

.offer-card__info {
    flex: 1 1 auto;
}

.modal-in-development {
    .modal__description {
        font-size: 1.5rem;
    }

    .btn.modal__content-button {
        margin-left: 0;
        min-width: 125px;
    }

    &.specialist {
        .btn.modal__content-button {
            background-color: #22d695;
        }
    }
}

.photo-stream .upload__delete-btn {
    width: 22px;
    border-radius: 50%;
    height: 22px;
    box-sizing: content-box;
    background: rgb(35 35 42 / 30%);
    padding: 6px;
    margin: 11px;
}

.text.profile-block__text {
    white-space: pre-line;
}

.list-orders__aside>*:first-child {
    position: sticky;
    top: 20px;
}

.notfound-page.error-base__fallback {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.modal-photoGalleryServices {

    .modal__content {
        max-width: 835px;

        .modal__description {
            font-size: 1.2rem;
        }

        .modal__content-buttons {
            button {
                flex: 1 1 auto;

                &.btn-ok {
                    max-width: 25%;
                }
            }
        }

        .inner-container {
            padding: 0;

            .title {
                font-weight: 800;
                font-size: 2.5rem;
                line-height: 54px;
                color: #23232A;
                text-align: left;
            }

            .photos {
                list-style: none;
                margin: 20px 0 10px 0;
                padding: 0;

                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                width: 100%;
                max-height: 400px;
                overflow-y: auto;

                @include vp-1023 {
                    justify-content: center;
                }

                .photo {
                    position: relative;
                    width: 140px;
                    height: 140px;
                    border-radius: 8px;
                    overflow: hidden;
                    cursor: pointer;

                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        background-color: rgb(0 0 0 / 40%);
                        ;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

.upload-advertorial__footer {
    justify-content: space-between;
    gap: 10px;
}

.specialist-page__about .text {
    white-space: pre-line;
}

.cookies__text {
    @include vp-479 {
        margin: 0;
        padding: 0;
        text-align: left;
    }
}

.modal.is-active {
    z-index: 2001;
}

.upload.not-empty {
    &.img {
        height: 100%;
        padding: 0;

        img {
            aspect-ratio: 1 / 1;
            object-fit: cover;
            display: block;
        }
    }
}

.modal__content {
    @include vp-767 {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 3% 3% 25% !important;
        overflow: auto;
    }

    h1.modal__content-title {
        @include vp-767 {
            margin-bottom: 0;
        }
    }

    h2.modal__content-title {
        @include vp-767 {
            margin: 10px 0;
        }
    }

    .form__row {
        @include vp-479 {
            margin-bottom: 14px;
        }
    }

    .form__row.form__controls {
        @include vp-767 {
            margin: 10px 0 0;
        }

        .btn {
            @include vp-767 {
                padding: 14px 28px;
                font-size: 16px;
                line-height: 16px;
            }

            @include vp-479 {
                padding: 12px 28px;
                font-size: 14px;
            }
        }
    }

    input.custom-input {
        @include vp-479 {
            padding: 8px 40px 8px 10px;
        }
    }

    .new-order-start__toggle {
        @include vp-479 {
            font-size: 16px;
            line-height: 18px;
        }
    }
}

.address-modal {
    .modal__content {

        .order-map {
            @include vp-767 {
                height: 280px;
            }

            @include vp-479 {
                height: 210px;
            }
        }

        .order-map__link {
            @include vp-479 {
                margin: 14px 0;
            }
        }

        .form fieldset {
            margin: 0;
        }

        fieldset>legend {
            @include vp-479 {
                line-height: 18px;
                font-size: 14px;
            }
        }
    }
}

.dropdown__btn {
    @include vp-767 {
        padding: 8px;
        border-radius: 8px;
        margin: 0 5px;
    }
}

.aboutme-form .custom-input textarea {
    @include vp-767 {
        min-height: 200px;
    }
}

// #debug {
//     position: fixed;
//     bottom: 0px;
//     right: 0px;
//     z-index: 9999;
//     padding: 10px;
//     background: red;
//     display: flex;
//     gap: 10px;
// }