.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 40px;

  @include vp-1919 {
    padding: 0 24px;
  }

  @include vp-1023 {
    padding: 0 24px;
  }

  @include vp-767 {
    padding: 0 16px;
  }
}
