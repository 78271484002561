.inner-container {
  padding: 0 13.5%;

  @include vp-1919 {
    padding: 0 7.5%;
  }

  @include vp-1439 {
    padding: 0 3%;
  }

  @include vp-1023 {
    padding: 0 32px;
  }

  @include vp-767 {
    padding: 0 16px;
  }
}
