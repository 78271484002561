.specialist-profile {
    padding-bottom: 170px;

    @include vp-767 {
        padding-bottom: 64px;
        font-size: 14px;
        line-height: 22px;
    }

    &__title {
        margin: 70px 0 32px;
        padding-left: 9.5%;

        @include vp-1439 {
            padding-left: 5%;
        }

        @include vp-1023 {
            padding-left: 24px;
            font-size: 42px;
            margin: 50px 0 25px;
        }

        @include vp-767 {
            margin: 18px 0;
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 40px 9.5% 40px;
        margin-bottom: 72px;

        font-size: 12px;
        font-weight: 500;
        color: $color-gray-chateau;

        @include vp-1439 {
            padding-left: 5%;
            margin-bottom: 60px;
        }

        @include vp-1023 {
            margin-bottom: 32px;
        }

        @include vp-767 {
            padding: 8px;
            margin-bottom: 24px;
        }

        &-row {
            justify-content: space-between;
            align-items: flex-start;

            @include vp-767 {
                .order-information__button {
                    display: block;
                }
            }
        }
    }

    &__name {
        flex: 1;

        .title {
            @include vp-767 {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }

    &__content {
        padding: 0 22.1% 0 17.27%;

        @include vp-1439 {
            padding: 0 5%;
        }

        @include vp-1023 {
            padding: 0;
        }
    }

    &__avatar {
        margin-right: 24px;

        @include vp-767 {
            margin-right: 16px;
            width: 64px;
            height: 64px;
        }
    }

    &__subtitle {
        padding-bottom: 24px;

        @include vp-1023 {
            padding-bottom: 16px;
            font-size: 26px;
            line-height: 1.3;
        }

        @include vp-767 {
            padding-bottom: 6px;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
        }
    }

    &__desc {
        margin: 0;
        padding-bottom: 4px;
        max-width: 60%;

        @include vp-1023 {
            max-width: unset;
        }
    }

    &__block:not(:last-child) {
        margin-bottom: 46px;

        @include vp-767 {
            margin-bottom: 26px;
        }
    }

    &__block--flex {
        display: flex;

        @include vp-767 {
            flex-direction: column;
        }
    }

    &__btn {
        padding: 18px;
        align-self: flex-start;
        min-width: 200px;

        @include vp-767 {
            padding: 12px;
            min-width: 158px;
        }
    }

    &__block-btn {
        display: block;
        margin: 28px auto 0;
        min-width: 193px;
        padding: 18px 26px;
        letter-spacing: .03em;
        line-height: 20px;

        @include vp-767 {
            min-width: unset;
            margin-top: 16px;
        }
    }

    &__row {
        margin-top: 15px;

        &--search {
            text-align: left;
        }
    }

    .title {
        color: $color-jaguar;
    }

    &__col {
        flex: 1;
        max-width: 65%;
        margin-right: auto;

        @include vp-767 {
            margin-bottom: 18px;
            max-width: unset;
            margin-right: 0;
        }
    }

    &__controls {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 64px;

        @include vp-767 {
            margin-top: 54px;
            flex-wrap: wrap;
        }
    }

    &__control:not(:first-child) {
        margin-left: 24px;
    }

    .profile-block:not(:first-of-type) {
        margin-top: 24px;
    }

    .profile-block {
        @include vp-767 {
            margin-top: 8px;
        }
    }

    .profile-block__row .profile-block__sub {
        @include vp-767 {
            margin-bottom: 10px;
        }
    }

    .profile-block__row:first-child {
        @include vp-767 {
            margin-bottom: 18px;
        }

        @include vp-479 {
            margin-bottom: 0;
        }
    }

    &__btn-text {
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        border: none;
        background-color: transparent;
        color: $color-neon-blue;
        text-transform: none;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: .03em;
        line-height: 30px;
    }
}