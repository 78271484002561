.toggle-category {
  .custom-toggle label {
    display: inline-flex;
    align-items: center;
  }

  .toggle-place__item {
    width: 250px;

    @include vp-767 {
      width: 100%;
    }
  }
}
