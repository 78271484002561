.modal-new-card {
  color: $color-gray-chateau;
  font-size: 14px;
  line-height: 20px;

  .modal__content {
    display: flex;
    flex-direction: column;
    padding: 95px 64px 64px;
    max-width: 690px;
    text-align: left;
    border-radius: 24px;

    @include vp-767 {
      border-radius: 0;
      padding: 16px;
      min-height: 100vh;
    }
  }

  .modal__content-title {
    margin: 0 0 32px;
    font-size: 46px;
    line-height: 54px;
    max-width: unset;

    @include vp-767 {
      margin-bottom: 16px;
      font-size: 26px;
      line-height: 1.5;
    }
  }

  .form__row {
    flex-wrap: nowrap;
  }

  .modal__close-btn {
    top: 49px;
    right: 66px;

    @include vp-767 {
      top: 16px;
      right: 6px;
    }
  }

  .credit-card {
    margin-bottom: 32px;
  }

  .text {
    margin-bottom: 16px;
  }

  .text:last-of-type {
    margin-bottom: 48px;

    @include vp-767 {
      margin-bottom: 8px;
    }
  }

  .modal__wrapper {
    @include vp-767 {
      padding: 0;
    }
  }

  .form__row {
    @include vp-767 {
      flex-direction: row;
    }
  }

  .form__field {
    @include vp-767 {
      margin-top: 0;
    }
  }

  .form__field:not(:first-child) {
    margin-left: 8px;
  }

  .modal__content-buttons {
    @include vp-767 {
      margin-top: auto;
      margin-left: -4px;
      margin-right: -4px;
      flex-wrap: wrap;
    }
  }

  .modal__btn {
    @include vp-767 {
      margin: 8px 4px 0;
    }
  }

  .custom-input label:hover {
    opacity: 1;
  }
}

.modal__status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 562px;

  &-icon__block {
    width: 124px;
    height: 124px;
    background: #F4F4F6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-title {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: #23232A;
    margin: 24px 0;
    text-align: center;
  }

  &-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #23232A;
    margin: 0 0 24px 0;
    text-align: center;
  }
}