.active-order {
    padding: 48px 0 16px;

    @include vp-1199 {
        padding: 16px 0 32px;
    }

    .inner-container {
        padding: 0 12%;
        display: grid;
        grid-template-columns: minmax(min-content, 832px) 264px;
        justify-content: space-between;

        @include vp-1919 {
            padding: 0 7.5%;
        }

        @include vp-1439 {
            padding: 0 3%;
        }

        @include vp-1199 {
            grid-template-columns: 1fr;
            padding: 0;
        }
    }

    .order-information-details {
        grid-column: 1;
        grid-row: 1 / 4;
        margin-bottom: 43px;

        @include vp-1919 {
            margin-right: 40px;
        }

        @include vp-1199 {
            grid-row: 2;
            margin: 0;
            margin-bottom: 31px;
        }
    }

    &__buttons {
        grid-row: 1;
        grid-column: 2;
        justify-self: end;
        position: relative;
        margin-bottom: 23px;
        max-width: 264px;
        width: 100%;

        display: flex;
        flex-direction: column;

        @include vp-1199 {
            grid-column: 1;
            justify-self: start;
            grid-row: 2;
            flex-direction: row;
            align-items: center;
        }

        @include vp-767 {
            margin-top: 23px;
            flex-direction: column;
        }
    }

    &__button {
        padding: 18px 32px;
        border-radius: 12px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: $color-default-white;
        height: 56px;
        min-width: 263px;

        &:not(:last-child) {
            margin-bottom: 12px;

            @include vp-1199 {
               margin-bottom: 0;
            }
        }

        @include vp-767 {
            height: 48px;
            font-size: 12px;
            width: 100%;
        }
    }

    .pinned-chats {
        padding-top: 10px;
        grid-row: 2;
        grid-column: 2;
        justify-self: end;

        @include vp-1199 {
            grid-row: 1;
            grid-column: 1;
            justify-self: stretch;
            padding-top: 23px;

            margin-bottom: 25px;
        }
    }

    .pinned-chats__button {
        margin-bottom: 12px;

        @include vp-1199 {
            margin-bottom: 0;
        }
    }

    &__response-specialists {
        grid-row: 5;
        grid-column: 1;

        @include vp-1919 {
            margin-right: 40px;
        }

        @include vp-1199 {
            grid-row: 6;
            margin: 0;
        }

        & .title {
            margin-bottom: 24px;

            @include vp-1199 {
                margin-bottom: 15px;
            }
        }
    }

    &__response-profiles-list {
        margin: 0;
        padding: 0;
        list-style: none;

        .profile-card, .profile-card-container {
            &:not(:last-child) {
                margin-bottom: 18px;
            }

            @include vp-767 {
                &:not(:last-child) {
                    margin-bottom: 14px;
                }
            }
        }
    }

    .profile-card-container {
        &:not(:last-child) {
            margin-bottom: 18px;
        }

        @include vp-767 {
            &:not(:last-child) {
                margin-bottom: 14px;
            }
        }
    }

    .active-order__card-button-chat {
        display: none;

        @include vp-767 {
            grid-column: 1/-1;
        }
    }

    &__card-button {
        max-width: 219px;
        width: 100%;
        background-color: inherit;
        border: 1px solid $color-link-water;
        color: $color-black-russian;
    }

    .slider-block.slider-block--specialist {
        flex-direction: row;
    }

    &__cocluded-deals {
        margin-top: 72px;
        grid-column: 1;
        grid-row: 5;
    }
}

.active-order-draft {
    .active-order__button--draft {
        background-color: inherit;
        color: $color-black-russian;
        border: 1px solid $color-link-water;
        border-radius: 12px;
    }

    .inner-container {
        // padding: 0 12%;
        display: grid;
        grid-template-columns: minmax(min-content, 1116px) 264px;
        justify-content: space-between;

        @include vp-1199 {
            grid-template-columns: 1fr;
            padding: 0;
        }

        .order-information-details {
            margin-bottom: 0;
        }

        .order-information-details__content-services-item {
            max-width: 555px;
        }
    }
}

.active-order-2 {
    .inner-container {
        // padding: 0 12%;
        display: grid;
        grid-template-columns: minmax(min-content, 1045px) 264px;
        justify-content: space-between;

        @include vp-1199 {
            grid-template-columns: 1fr;
            padding: 0;
        }
    }

    .order-information-details {
        margin-bottom: 72px;
    }

    .active-order__response-specialists {
        margin-bottom: 47px;
    }

    .active-order__cocluded-deals {
        grid-row: 6;
        grid-column: 1;

        @include vp-1919 {
            margin-right: 40px;
        }

        @include vp-1199 {
            margin: 0;
            grid-row: 7;
        }
    }
}

.closed-order {
    .inner-container {
        grid-template-columns: minmax(min-content, 1045px) 264px;
        justify-content: space-between;

        @include vp-1199 {
            grid-template-columns: 1fr;
            padding: 0;
        }
    }

    .profile-mini-card {
        grid-row: 2;
        grid-column: 2;

        @include vp-1199 {
            grid-row: 1;
            grid-column: 1;
            justify-self: stretch;
            padding-top: 23px;

            width: calc(100% + 32px);
            position: relative;
            padding-left: 16px;
        }
    }

    .order-information {
        @include vp-1199 {
            margin-bottom: 20px;
        }
    }

    .order-information-details {
        margin-bottom: 72px;

        @include vp-1199 {
            grid-row: 3;
        }
    }

    .review-block {
        grid-row: 2;
        grid-column: 2;

        @include vp-1199 {
            grid-row: 2;
            grid-column: 1;
            align-self: baseline;
            margin-bottom: 48px;
        }
    }

    .active-order__response-specialists {
        grid-row: 4;
    }

    .active-order__response-profiles-list .profile-card:not(:last-child) {
        margin-bottom: 21px;
    }
}

.closed-order-done {
    .inner-container {
        grid-template-columns: minmax(min-content, 1045px) 264px;
        justify-content: space-between;

        @include vp-1199 {
            grid-template-columns: 1fr;
        }
    }

    .profile-mini-card {
        grid-row: 1;
        grid-column: 2;
        justify-self: end;

        @include vp-1199 {
            grid-row: 1;
            grid-column: 1;
            justify-self: stretch;
            padding-top: 23px;

            width: calc(100% + 32px);
            position: relative;
            padding-left: 16px;
        }
    }

    .order-information {
        @include vp-1199 {
            margin-bottom: 20px;
        }
    }

    .order-information-details {
        margin-bottom: 72px;

        @include vp-1199 {
            margin-top: 20px;
        }
    }

    .review-block {
        grid-row: 2;
        grid-column: 2;

        @include vp-1199 {
            grid-row: 2;
            grid-column: 1;
            align-self: baseline;
            margin-bottom: 20px;

            padding: 24px 16px 32px;
            position: relative;
            padding-left: 16px;
        }
    }

    .active-order__response-specialists {
        grid-row: 4;
    }
}

.closed-order-add-spec {
    .inner-container {
        grid-template-columns: minmax(min-content, 1045px);
    }

    .order-information {
        margin-bottom: 47px;

        @include vp-1199 {
            margin-bottom: 28px;
        }
    }

    &__content {
        .title {
            margin-bottom: 25px;

            @include vp-1199 {
                margin-bottom: 19px;
            }
        }
    }

    .find-pro {
        padding: 0;
    }

    .find-pro__profiles-list {
        margin: 0 0 30px;

        @include vp-1199 {
            margin-bottom: 14px;
            row-gap: 12px;
        }
    }

    .output {
        display: block;

        @include vp-1199 {
            display: none;
        }
    }
}
