.card-expert {
  position: absolute;
  bottom: 120px;
  right: 120px;
  padding: 5px 5px 14px;
  border-radius: 14px;
  background-color: $color-default-white;
}

.card-expert > img {
  width: 147px;
  height: 111px;
  border-radius: 10px;
}

.card-expert__raiting {
  position: absolute;
  top: 109px;
  left: 10px;
  padding: 1px 1px 1px 11px;
  width: 29px;
  background-color: $color-shamrock;
  border-radius: 3px;

  &::before {
    content: "";
    position: absolute;
    left: 3px;
    top: 2px;
    width: 7px;
    height: 7px;
    background-image: url("../../frontend/app/img/content/about/icon-star.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.card-expert__raiting p {
  margin: 0;
  font-size: 7px;
  font-weight: 600;
  line-height: 11px;
  color: $color-default-white;
}

.card-expert__item-skill p {
  margin: 17px 0 38px 5px;
  margin-left: 5px;
  font-size: 8px;
  font-weight: 500;
  line-height: 11px;
  color: $color-black-russian;
}

.card-expert__bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-expert__country ul {
  padding: 0;
  margin: 0 0 0 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
  list-style: none;
}

.card-expert__country-button {
  padding: 0;
  width: min-content;
  height: min-content;
  font-size: 7px;
  font-weight: 500;
  line-height: 11px;
  color: $color-gray-chateau;
  background-color: inherit;
  white-space: nowrap;
}

.card-expert__amountOfDeals p {
  margin: 0 5px 0 0;
  font-size: 7px;
  font-weight: 500;
  line-height: 11px;
  color: $color-gray-chateau;
}
