.date-start {
  margin-bottom: 16px;

  &__choice-date-heading,
  &__period-heading {
    margin: 0;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: $color-jaguar;
  }

  &__choice-date-input,
  &__period-input {
    position: relative;
    max-width: 264px;

    & button {
      cursor: pointer;
      position: absolute;
      padding: 0;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-color: inherit;
      border: none;
    }
  }

  &__period-inputs {
    display: flex;
  }

  &__period-input {
    flex-basis: 264px;
    &:not(:last-of-type) {
      margin-right: 11px;
    }

    label {
      position: relative;
    }

    label span {
      position: absolute;
      left: 15px;
      color: $color-mischka;
      text-transform: capitalize;
    }
  }
}

.date-start__choice-date-input label input,
.date-start__period-input label input {
  padding-right: 60px;
}
