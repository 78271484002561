.upload-advertorial {
  display: flex;
  flex-direction: column;
  font-weight: 500;

  padding: 8px;
  height: 100%;
  min-height: 352px;
  border: 1px solid $color-link-water;
  border-radius: 24px;

  font-size: 14px;
  line-height: 20px;

  @include vp-767 {
    max-width: 201px;
    min-height: 292px;
    font-size: 12px;
    line-height: 20px;
  }

  &__header {
    position: relative;

    text-align: center;
    color: $color-jaguar;
    border-radius: 18px;
    min-height: 192px;

    background-color: $color-ghost-white;

    @include vp-767 {
      min-height: unset;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    opacity: 0;
    visibility: hidden;

    display: grid;
    place-items: center;

    border-radius: 18px;
    background-color: rgba($color-default-black, 0.3);
    transition: opacity $trans-600;

    @include vp-767 {
      visibility: visible;
    }

    .btn {
      background-color: transparent;
      color: $color-default-white;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        .upload-advertorial__overlay {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &__content {
    margin: 0;
    height: 100%;
    padding: 24px 6px;
    color: $color-gray-chateau;

    @include vp-767 {
      padding: 20px 0;
    }
  }

  &__footer {
    padding: 12px 6px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 12px;
    color: $color-gray-chateau;

    @include vp-767 {
      margin-top: auto;
      align-items: flex-start;
      padding: 9px 0 14px;

      font-size: 10px;
      line-height: 18px;
    }
  }

  &__count {
    font-size: 12px;
    padding-right: 6px;

    @include vp-767 {
      padding-right: 3px;
    }
  }

  &__caption {
    margin: 8px 0 12px;

    @include vp-767 {
      margin: 0 0 8px;
    }
  }

  &__placeholder {
    padding: 27px 36px;
    height: 100%;

    @include vp-767 {
      padding: 26px 12px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 18px 7px 0;
    list-style: none;
    flex: 1;
    color: $color-valhalla;
  }

  &__item:not(:last-child) {
    padding-bottom: 4px;
  }

  .languages {
    margin-right: auto;
  }

  .btn {
    padding: 12px 16px;
    font-size: 12px;
    border-radius: 8px;

    @include vp-767 {
      padding: 7px 14px;
      font-size: 10px;
      line-height: 18px;
    }
  }

  .rating {
    position: absolute;
    left: 7px;
    bottom: -11px;
  }
}