.page {
    padding-bottom: 156px;
    position: relative;

    .browser-firefox & {
        width: calc(4 / 3 * 100%);
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 1199px) {
            width: 100%;
        }
    }

    @include vp-1023 {
        padding-bottom: 80px;
    }

    @include vp-767 {
        overflow: hidden;
        padding-bottom: 32px;
    }

    &__container {
        padding: 0 20px;
        // max-width: 1440px;
        @include vp-1439 {
            padding: 0 18px;
        }

        @include vp-1279 {
            padding: 0 24px;
        }

        @include vp-1023 {
            padding: 0 25px;
        }

        @media (max-width: 820px) {
            padding: 0 23px;
        }

        @include vp-767 {
            padding: 0;
        }
    }

    &__intro {
        margin: 48px 0;

        @include vp-767 {
            margin: 24px 0 !important;
        }
    }

    &__promo {
        margin-bottom: 80px;

        @include vp-1439 {
            margin-bottom: 60px;
        }

        @include vp-1023 {
            margin-bottom: 36px;
        }
    }

    &__wrapper {
        margin: 0 auto;
        padding-left: 11.8%;
        padding-right: 12.2%;

        @include vp-1439 {
            padding-left: 10%;
            padding-right: 10.5%;
        }

        @include vp-1023 {
            padding: 0 32px;
        }

        @include vp-767 {
            max-width: unset;
            padding: 0 16px;
        }
    }

    &__title {
        margin: 72px 0 32px;

        @include vp-1439 {
            margin: 60px 0 30px;
        }

        @include vp-1023 {
            margin: 32px 0 16px;
        }

        @include vp-767 {
            margin: 16px 0;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr min-content;
        column-gap: 96px;

        padding-left: 0;

        @include vp-1439 {
            padding-left: 0;
            column-gap: 60px;
        }

        @include vp-1023 {
            padding-left: 0;
            column-gap: 0;
        }

        @include vp-767 {
            grid-template-columns: 1fr;
        }
    }

    .specialists:not(.specialists--row) {
        grid-column: 2;
        grid-row: 1/5;

        @include vp-1023 {
            grid-column: 1/-1;
            grid-row: 1;
            margin-bottom: 46px;
            margin-left: 32px;
            // overflow-x: scroll;
            scrollbar-width: none;
        }

        @include vp-767 {
            margin-left: 16px;
            margin-bottom: 32px;
        }
    }

    .section {
        margin-bottom: 80px;

        @include vp-1023 {
            margin-bottom: 46px;
            grid-column: 1/-1;
        }

        @include vp-767 {
            margin-bottom: 32px;
        }
    }

    .order-stats {
        @include vp-1023 {
            padding: 0 32px;
        }

        @include vp-767 {
            padding: 0 16px;
        }
    }

    &__aside {
        flex: 1;
        position: sticky;
        top: 20px;

        @include vp-1023 {
            position: unset;
        }
    }
}

.page--main {
    .useful-info {
        @media (max-width: 1339px) {
            margin-left: 32px;
            margin-bottom: 0;
        }

        @include vp-767 {
            grid-row: 3;
            margin-left: 16px;
        }

        &.section {
            margin-bottom: 0;
        }

        &__wrapper {
            .title:empty {
                margin: 0;
            }

            .useful-info__list:not(:empty) {
                margin-bottom: 80px;
            }

            @media (max-width: 1339px) {
                max-width: 100%;
                overflow: hidden;
                // overflow-x: scroll;
            }
        }

        &__list {
            margin: 0px;

            @media (max-width: 1339px) {
                flex-wrap: nowrap;
              }
        }
    }

    .specialists {
        &__wrapper {
            @media (max-width: 1339px) {
                padding-right: 0;
                // overflow: auto;
                flex-direction: row;
                justify-content: space-between;
              }
        }

        &__item {
            @media (max-width: 1339px) {
                margin-right: 12px;
                margin-bottom: 0;
              }
        }

    }

    .section {
        @media (max-width: 1339px) {
            margin-bottom: 46px;
            grid-column: 1/-1;
        }
    }

    .specialists:not(.specialists--row) {
        @media (max-width: 1339px) {
            grid-column: 1/-1;
            grid-row: 1;
            margin-bottom: 46px;
            margin-left: 32px;
            // overflow-x: scroll;
            scrollbar-width: none;
        }
    }

    .page__grid {
        @media (max-width: 1339px) {
            padding-left: 0;
            column-gap: 0;
        }
    }

    .bonuses {
        &__background-image-wrapper {
            @media (max-width: 1339px) {
                min-width: 400px;
            }

            @media (max-width: 1430px) {
                min-width: 400px;
            }
        }

        @media (max-width: 1339px) {
            grid-row: 2;
        }
    }

    .order-stats {
        @include vp-767 {
            margin-bottom: 16px;
        }
    }
}

.page--customer {
    .page__promo {
        margin-top: 8px;
    }

    .selection-spec {
        @include vp-1023 {
            margin: 0 23px 32px;
        }

        @include vp-767 {
            margin: 0 16px 32px;
        }
    }

    .account-options {
        @include vp-767 {
            margin-bottom: 0;
        }
    }
}

.page--specialists {
    .section {
        @media (max-width: 1199px) {
            grid-column: 1/-1;
            margin-bottom: 46px;
        }
    }

    .page__grid {
        @media (max-width: 1199px) {
            column-gap: 0;
            padding-left: 0;
        }
    }

    .account-options {
        grid-row: 2;

        @media (max-width: 1199px) {
            grid-row: 5;
            margin-bottom: 0;
        }
    }

    .service-partner {
        grid-row: 1/4;
        grid-column: 2;
        min-width: 262px;

        @media (max-width: 1199px) {
            grid-column: 1/-1;
            grid-row: 1;
            margin-left: 32px;
            margin-bottom: 46px;
        }

        .service-partner__wrapper {
            @media (max-width: 1199px) {
                overflow: hidden;
                // overflow-x: scroll;
            }
        }

        @include vp-767 {
            margin-left: 16px;
            margin-bottom: 32px;
        }
    }

    .order-stats {
        grid-row: 3;
        margin-bottom: 0;

        @media (max-width: 1199px) {
            grid-row: 4;
        }
    }

    .price-deals {
        @media (max-width: 1199px) {
            grid-row: 3;
        }
    }
}

.page__accordion {
    @include vp-1023 {
        padding: 0 32px;
    }

    @include vp-767 {
        padding: 0 16px;
    }
}
