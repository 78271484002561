.author-order {
  margin-top: 14px;
  padding: 18px 27px 19px 22px;
  width: 295px;
  background-color: $color-ghost-white;
  border-radius: 12px;

  @include vp-767 {
    padding: 13px 16px 13px 16px;
    width: 100%;
  }

  &--fulfil {
    margin-top: -17px;

    @include vp-767 {
      margin-top: -6px;
    }
  }

  &__title-name {
    margin: 0;
    font-weight: 700;
    line-height: 34px;

    @include vp-767 {
      font-size: 14px;
      line-height: 26px;
    }
  }

  &__bottom-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 3px;
    margin-bottom: 4px;

    .rating {
      padding-right: 6px;
      padding-left: 6px;
      flex-direction: row;
    }
  }

  &__response-link {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $color-neon-blue;
    box-shadow: 0 1px 0 0 rgba($color-neon-blue, 0.2);
    transition: $trans-default;

    &:hover,
    &:focus {
      color: rgba($color-neon-blue, 0.8);
      box-shadow: 0 1px 0 0 rgba($color-neon-blue, 0.1);
      transition: $trans-default;

      .popup-response {
        display: block;
        transition: $trans-default;
      }
    }
  }

  &__response-popup {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $color-jaguar;
  }

  &__amount-star-wrapper {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    color: $color-shamrock;
  }

  &__amount-star {
    display: block;
    margin-left: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }

  .indicators__deal,
  .indicators__link-review {
    display: none;
  }
}
