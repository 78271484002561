.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    text-transform: uppercase;
    color: $color-default-white;
    font-weight: 700;

    background-color: $color-neon-blue;
    border: none;
    user-select: none;
    cursor: pointer;

    transition: opacity $trans-default;

    &:disabled {
        opacity: 0.5;

        pointer-events: none;
    }

     &:focus-visible {
        outline: none;
    }

    @media (hover: hover), screen and (min-width: 0 \0) {
        &:hover,
        &:focus {
            opacity: 0.8;
        }
    }

    &--transparent {
        padding: 18px 32px;

        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;

        background-color: inherit;
        color: $color-black-russian;
        border: 1px solid $color-solitude;
        border-radius: 12px;

        @include vp-1023 {
            font-size: 12px;
        }

        @include vp-767 {
            padding: 14px 18px;
            background-color: white;
        }
    }

    &--skip {
        padding: 18px 32px;

        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;

        background-color: inherit;
        color: $color-black-russian;
        border-radius: 12px;

        @include vp-1023 {
            font-size: 12px;
        }

        @include vp-767 {
            padding: 14px 18px;
            background-color: white;
        }
    }

    &--blue {
        min-width: 300px;
        padding: 28px 50px;

        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.03em;

        box-shadow: 0px 13px 28px rgba(67, 75, 238, 0.5);
        border-radius: 12px;

        @include vp-767 {
            width: 100%;
            padding: 20px;
            font-size: 12px;
        }
    }

    &--white {
        color: inherit;
        border: 1px solid $color-solitude;
        background-color: $color-default-white;
    }

    &--rounded {
        border-radius: 12px;
    }

    &--green {
        background-color: $color-shamrock;
    }

    &--bg-green {
        color: $color-default-white;
        background-color: $color-shamrock;
    }

    &--nb {
        border: 1px solid transparent;
    }

    &--color-green {
        color: $color-shamrock;
    }

    &--bg-blue {
        color: $color-default-white;
        background-color: $color-neon-blue;
    }

    &--no-bg {
        background-color: transparent;
    }

    &--sm {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;

        @include vp-767 {
            font-size: 12px;
        }
    }

    &--wide {
        padding: 18px;
        width: 100%;

        @include vp-767 {
            padding: 14px;
        }
    }

    &--view-all {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        text-transform: none;
        color: #434bee;
        background-color: inherit;
        padding: 0;
        border-bottom: 1px solid rgba(67, 75, 238, 0.2);
        margin-top: 20px;

        .new-order & {
            margin-bottom: 48px;
        }
    }

    &--light {
        text-transform: unset;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;

        span {
            box-shadow: 0 1px 0 0 rgba($color-shamrock, 0.2);
        }
    }

    &--centered {
        display: block;
        margin: 0 auto;
        padding: 18px 23px;
    }
}
