.prof-list {
  &__item {
    margin-bottom: 12px;

    @include vp-767 {
      margin-bottom: 9px;
    }
  }

  // &__item.popover {
  //   margin-bottom: 12px;
  // }

  &__bullet {
    padding: 0 8px;

    @include vp-767 {
      padding-right: 4px;
    }
  }
}
