.concluded-deals {
  .title {
    margin-bottom: 24px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    padding: 40px 40px 56px;
    border: 1px solid $color-link-water;
    border-radius: 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-status {
      margin: 0;
      margin-bottom: 12px;
      padding: 6px 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $color-neon-blue;
      background: rgba(67, 75, 238, 0.2);
      border-radius: 8px;
    }

    &-button {
      background-color: inherit;
      border: none;
      cursor: pointer;
      padding: 0;
      margin-bottom: 15px;
    }

    &-title {
      margin: 0;
      margin-bottom: 30px;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      color: $color-jaguar;
      flex-basis: 100%;
    }

    &-data-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &-data-item {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      @include vp-767 {
        flex-direction: column;
      }
    }

    &-data-item-heading {
      margin: 0;
      margin-right: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-gray-chateau;
    }

    &-data-item-text {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-jaguar;
    }
  }

  &__pro-name {
    margin: 15px 0 0 5px;
    padding: 19px 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 34px;
    color: $color-jaguar;
    background: $color-ghost-white;
    border-radius: 12px;
    flex-basis: 295px;
  }
}
