.modal-exit {
  .modal__content-button {
    max-width: 145px;
    background-color: inherit;
    color: $color-black-russian;
    border: 1px solid $color-link-water;
    flex-grow: 1;
    padding: 18px 32px;

    &--background {
      background-color: $color-neon-blue;
      border: none;
      color: $color-default-white;
      margin-left: 24px;

      @include vp-767 {
        margin-left: 5px;
      }
    }
  }
}
