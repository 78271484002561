.description {
  line-height: 1.9;

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  dl {
    margin: 0;
  }

  dd {
    margin-left: 0;
  }

  dt {
    padding-right: 8px;
    color: $color-gray-chateau;
  }
}
