.user-nav {
  position: relative;

  font-size: 18px;
  line-height: 30px;
  color: $color-jaguar;

  @include vp-479 {
    position: static;
  }

  &:hover,
  &:focus,
  &:active {
    .user-nav__list {
      padding: 21px 16px;
      max-height: 5000px;
      opacity: 1;
    }
  }

  &__list {
    position: absolute;
    bottom: 0;
    right: 0;

    max-height: 0;
    opacity: 0;

    transform: translateY(100%);

    background-color: $color-default-white;
    box-shadow: 0 15px 15px rgb(72 73 95 / 10%);
    border-radius: 8px;
    overflow: hidden;
    transition: opacity $trans-default;

    @include vp-479 {
      opacity: 1;
      max-height: 5000px;
      width: 100%;
      box-shadow: none;
      padding: 0 16px !important;
    }
  }

  &__item:not(:last-child) {
    margin-bottom: 18px;
  }

  &__btn {
    white-space: nowrap;
    transition: color $trans-default;

    @media (hover: hover),
    screen and (min-width: 0\0) {

      &:hover,
      &:focus {
        color: $color-neon-blue;
      }
    }
  }

  &.is-open {
    .user-nav__list {
      padding: 21px 16px;
      max-height: 5000px;
      opacity: 1;
    }
  }
}