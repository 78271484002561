.upload-foto {
  position: relative;
  width: 120px;
  height: 120px;
  overflow: hidden;

  @include vp-767 {
    width: 64px;
    height: 64px;
  }

  &__icon {
    width: 120px;
    height: 120px;

    @include vp-767 {
      width: 64px;
      height: 64px;
    }
  }

  &__btn {
    position: absolute;
    bottom: 0;
    right: 0;

    width: 32px;
    height: 32px;

    cursor: pointer;

    &.add-foto svg {
      transform: rotate(45deg);
    }
  }

  &__btn-input {
    &--file {
      input {
        display: none;
      }

      label {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: transparent;
        cursor: pointer;
        z-index: 5;
        transition: background-color $trans-default;

        span {
          visibility: hidden;
        }

        &:hover,
        &:focus-within {
          background-color: rgba($color-neon-blue, 0.5);
          transition: background-color $trans-default;
        }
      }
    }
  }

  &.not-empty {
    .upload-foto__btn {
      transform: rotate(45deg);
    }

    .upload-foto__icon {
      display: none;
    }
  }
}