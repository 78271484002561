.popover {
    position: relative;

    cursor: pointer;

    &::after,
    &__tip {
        position: absolute;
        top: 0;

        width: 0;
        height: 0;

        visibility: hidden;
        opacity: 0;
        background-color: $color-quartz;

        transition: opacity $trans-default;
    }

    &::after {
        left: -6px;
        content: "";
        transform: rotate(45deg) translateY(-100%);
    }

    &__tip {
        left: -16px;

        padding: 8px 16px;

        font-size: 18px;
        line-height: 30px;

        border-radius: 12px;
        transform: translateY(-100%);

        @include vp-1199 {
            left: unset;
            right: 0;
        }
    }


    &:hover,
    &:focus {
        .popover__tip {
            width: unset;
            height: unset;
            visibility: visible;
            opacity: 1;
        }

        &::after {
            width: 12px;
            height: 12px;
            opacity: 1;
            visibility: visible;
        }
    }

    &.is-open {
        .popover__tip {
            width: unset;
            height: unset;
            visibility: visible;
            opacity: 1;
        }

        &::after {
            width: 12px;
            height: 12px;
            opacity: 1;
            visibility: visible;
        }
    }
    @include vp-767 {
        margin: 5px 0;
    }
}

.popover--grey {
    .popover__tip {
        left: 50%;
        right: -50%;

        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-transform: none;
        text-align: left;
        color: $color-jaguar;
    }

    &::after {
        left: calc(50% + 10px);
    }

    .popover__tip,
    &::after {
        top: -10px;
        background-color: $color-ghost-white;
    }
}

.popover--grey.specialist {
    max-width: 600px;

    .popover__tip {
        width: 100%;
        max-width: 600px;
        background: #d8d8fd;
        left: 0
    }

    .popover__tip,
    &::after {
        top: -10px;
        background-color: #d8d8fd;
    }
}

.popover--white {

    .popover__tip,
    &::after {
        background-color: $color-default-white;
    }
}