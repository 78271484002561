.list-orders {
    padding-bottom: 163px;

    @include vp-767 {
        padding-bottom: 63px;
    }

    &__progress-bar {
        display: none;

        @include vp-1199 {
            display: block;
        }
    }

    &__block,
    &__wrapper {
        margin: 0 auto;
        padding-top: 74px;
        padding-right: 12%;
        padding-left: 12%;

        @media (max-width: 1230px) {
            padding-right: 9%;
            padding-left: 9%;
        }

        @include vp-1199 {
            padding-right: 0;
            padding-left: 0;
        }

        @include vp-767 {
            padding-top: 12px;
        }
    }

    &__content {
        display: flex;
        align-items: flex-start;

        .cards-orders {
            flex: 1;
        }

        @include vp-1199 {
            flex-wrap: wrap;
        }

    }

    &__aside {
        // position: sticky;
        // top: 20px;

        @include vp-1199 {
            position: unset;
            flex: 1;
            order: -1;
            min-width: 45%;
        }

        @include vp-767 {
            min-width: 90%;
        }
    }

    &__aside:first-child {
        @include vp-1199 {
            margin-right: 20px;
        }

        @include vp-767 {
            margin-right: 0;
            margin-bottom: 16px;
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: max-content;


        @include vp-1199 {
            grid-template-columns: 1fr;
            padding-top: 20px;
        }

        .pinned-chats {
            @media (max-width: 1585px) {
                position: static;
                grid-row: 2/2;
                grid-column: 1/-1;
                margin-bottom: 20px;
            }

            @include vp-1199 {
                grid-row: 3;
                margin-bottom: 24px;
            }
        }
    }

    &__top-block {
        margin-bottom: 44px;
        max-width: 720px;
        grid-column: 1/-1;
        grid-row: 1/2;

        @include vp-1199 {
            grid-row: 2/2;
        }

        @include vp-767 {
            margin-bottom: 16px;
        }

        & .title--h1 {
            margin-bottom: 29px;
            font-size: 46px;
            line-height: 54px;

            @include vp-767 {
                margin-bottom: 10px;
                font-size: 26px;
                line-height: 34px;
            }
        }
    }

    &__search-input {
        position: relative;
        margin-bottom: 32px;

        .custom-input input {
            padding-top: 14px;
            padding-right: 55px;
            padding-left: 18px;
            max-width: 690px;
        }

        @include vp-767 {
            margin-bottom: 13px;
        }

        svg {
            position: absolute;
            top: 18px;
            right: 51px;
            cursor: pointer;

            @include vp-767 {
                right: 20px;
            }
        }
    }
}