.spec-page {
    padding-bottom: 133px;
    color: $color-jaguar;

    .filter__btn-subtitle,
    .toggle-filter {
        span {
            background-color: $color-shamrock;
        }
    }

    .custom-toggle--checkbox input:checked + .custom-toggle__icon {
        background-color: rgba($color-shamrock, 0.1);
    }

    .custom-toggle--checkbox .custom-toggle__icon:after, .custom-toggle--checkbox .custom-toggle__icon::before {
        background-color: $color-shamrock;
    }

    .slider-block.slider-block--specialist {
        flex-direction: row;
    }

    @include vp-767 {
        padding: 18px 0 64px;
    }

    &__container {
        width: 77%;

        @include vp-1023 {
            width: 100%;
        }
    }

    &__wrapper {
        max-width: 1116px;
    }

    &__header {
        margin-top: 72px;
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid $color-link-water;

        @include vp-1279 {
            margin-top: 46px;
            margin-bottom: 24px;
            padding-bottom: 24px;
        }

        @include vp-1023 {
            margin-top: 32px;
            margin-bottom: 16px;
            padding-bottom: 16px;
        }
    }

    &__title {
        margin-bottom: 46px;
    }

    &__heading {
        margin: 70px 0 50px;

        @include vp-767 {
            margin: 0 0 23px;
        }
    }

    &__offers {
        max-width: 1045px;
    }

    &__new-offer {
        max-width: 832px;
    }

    .promo-block {
        margin-top: 70px;

        @include vp-1023 {
            margin-right: -24px;
        }

        @include vp-767 {
            margin-right: -16px;
        }
    }

    .subtitle,
    .custom-toggle__label label span,
    .title {
        color: $color-jaguar;
    }

    .pagination {
        padding-top: 19px;
    }

    .pagination__link.is-active {
        color: $color-shamrock;
        background: rgba($color-shamrock, 0.1);
    }

    .pagination__link {
        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover {
                color: $color-shamrock;

                svg {
                    color: $color-shamrock;
                    transition: color $trans-default;
                }

            }

            &:focus {
                outline: 2px solid rgba($color-shamrock, 0.1);
            }
        }
    }

    .custom-input input,
    .custom-textarea textarea {
        &:hover,
        &:focus {
            border-color: $color-shamrock;
        }
    }

    .custom-toggle--radio input:checked + .custom-toggle__icon {
        background-color: rgba($color-shamrock, 0.2);
    }

    .custom-toggle--radio input:checked + .custom-toggle__icon::before,
    .custom-toggle--radio input:focus + .custom-toggle__icon::before {
        background-color: $color-shamrock;
    }

    .pinned-chats__msg-count:not(.pinned-chats__msg-count--grey),
    .response-card-order__response-amount,
    .response-card-order__new-message-amount,
    .receipt__amount--current,
    .receipt__link--current {
        background-color: $color-shamrock;
    }

    .btn--view-all {
        color: $color-shamrock;
    }

    .toggle-filter::after {
        background-color: $color-shamrock;
    }
}
