.specialist-customer {
  grid-column: 2;
  grid-row: 3 / 7;
  margin-bottom: 158px;

  @include vp-767 {
    grid-row: 2;
    margin-bottom: 32px;
  }
}
