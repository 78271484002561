.notifications {
  padding-top: 82px;
  padding-bottom: 164px;

  @include vp-1279 {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .title {
    margin-bottom: 50px;
  }

  &__list {
    margin: 0 0 32px;
    padding: 0;
    list-style: none;

    li:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
