.feedback-page {
  position: relative;
  padding-top: 80px;
  padding-bottom: 224px;

  @include vp-767 {
    padding-bottom: 0;
  }

  .container {
    padding: 0;
    max-width: 1400px;

    @include vp-1919 {
      padding: 0 24px;
    }

    @include vp-1023 {
      padding: 0 24px;
    }

    @include vp-767 {
      padding: 0 16px;
    }
  }

  .title {
    margin-bottom: 26px;
    max-width: 788px;

    @include vp-767 {
      margin: 0;
    }
  }

  .feedback-form {
    max-width: 1400px;
    display: block;
  }

  .feedback-form__text {
    margin: 0;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: $color-black-russian;
    max-width: 788px;

    @include vp-767 {
      padding: 0;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  .feedback-form__form-wrapper {
    max-width: 832px;
  }
}
