.catalog-page {
    .container {
        padding: 78px 260px 216px;

        @include vp-767 {
            padding: 0 16px 32px;
        }

        @include vp-1023 {
            padding: 0 24px 32px;
        }

        @include vp-1919 {
            padding: 0 24px 64px;
        }
    }

    .title {
        margin-bottom: 66px;

        @include vp-1023 {
            margin-bottom: 16px;
        }
    }

    .accordion__element {
        &-main {
            padding: 44px 0;
            border-top: 1px solid $color-solitude;

            &:last-child {
                border-bottom: 1px solid $color-solitude;
            }

            @include vp-1023 {
                padding: 22px 0;
            }
        }
    }

    .accordion__element-button {
        position: relative;
        font-weight: 800;
        font-size: 40px;
        line-height: 48px;
        padding: 0;
        padding-left: 76px;
        text-transform: none;
        color: $color-jaguar;
        background-color: inherit;
        border: none;
        cursor: pointer;
        display: inline;
        text-align: left;

        @include vp-1023 {
            font-size: 18px;
            line-height: 22px;
            padding-left: 30px;
        }

        @include vp-767 {
            font-size: 15px;
            line-height: 22px;
            padding-left: 30px;
        }

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover,
            &:focus {
                opacity: 0.6;
            }
        }
    }

    .accordion__element-button-icon {
        position: absolute;
        top: 13px;
        left: 16px;
        transform: rotate(-90deg);
        fill: $color-jaguar;

        @include vp-1023 {
            top: 6px;
            left: 0;
            width: 20px;
            height: 13px;
        }

        @include vp-767 {
            top: 6px;
            left: 0;
            width: 17px;
            height: 10px;
        }
    }

    .accordion__element-button-title {
        margin-right: 13px;

        @include vp-767 {
            margin-right: 5px;
        }
    }

    .accordion__element-button-count {
        color: $color-gray-chateau;
    }

    .accordion__element-parent {
        display: none;
        padding: 0 76px;

        &:first-child {
            padding-top: 27px;
        }

        &:last-child {
            margin-bottom: 22px;
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        @include vp-767 {
            padding: 0 16px 0 60px;
        }
    }

    .accordion__element-parent-button {
        position: relative;
        cursor: pointer;
        background-color: inherit;
        font-family: $ff-sf-pro-display;
        font-size: 24px;
        line-height: 40px;
        color: $color-jaguar;
        text-transform: none;
        padding: 0;
        padding-left: 32px;
        text-align: left;
        border: none;
        display: inline;

        @include vp-1023 {
            padding-left: 24px;
            font-size: 16px;
            line-height: 22px;
        }

        @include vp-767 {
            padding-left: 24px;
            font-size: 14px;
            line-height: 22px;
        }

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover,
            &:focus {
                opacity: 0.6;
            }
        }
    }

    .accordion__element-parent-button-icon {
        position: absolute;
        top: 13px;
        left: 4px;
        fill: $color-jaguar;

        @include vp-1023 {
            top: 4px;
        }
    }

    .accordion__element-parent-button-title {
        margin-right: 13px;

        @include vp-767 {
            margin-right: 5px;
        }
    }

    .accordion__element-parent-button-count {
        color: $color-gray-chateau;
    }

    .accordion__element-links {
        display: none;
        padding: 0 51px;

        &:first-child {
            padding-top: 16px;
        }

        &:last-child {
            margin-bottom: 41px;
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .accordion__element-link {
        font-family: $ff-sf-pro-display;
        color: $color-jaguar;
        font-size: 24px;
        line-height: 40px;

        @include vp-1023 {
            font-size: 14px;
            line-height: 22px;
        }

        @include vp-767 {
            font-size: 13px;
            line-height: 22px;
        }

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover,
            &:focus {
                opacity: 0.6;
            }
        }
    }

    .accordion__element-link-title {
        margin-right: 14px;

        @include vp-767 {
            margin-right: 5px;
        }
    }
}

.accordion__element-main.is-active {
    .accordion__element-parent {
        display: block;
    }

    .accordion__element-button-icon {
        fill: $color-neon-blue;
        transform: rotate(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.accordion__element-parent.is-active {
    .accordion__element-parent-button-icon {
        fill: $color-neon-blue;
        transform: rotate(90deg);
        animation: fadeIn 1s;
    }

    .accordion__element-parent-button {
        color: $color-neon-blue;
    }

    .accordion__element-links {
        display: block;
    }
}
