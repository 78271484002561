.new-offer-form {
  &__field {
    margin-right: 16px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }

    @include vp-767 {
      display: block;
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  &__title {
    margin-bottom: 27px;

    @include vp-767 {
      margin-bottom: 12px;
      font-weight: 800;
    }
  }

  &__row {
    margin-bottom: 12px;
  }

  &__block {
    margin-bottom: 51px;

    @include vp-767 {
      margin-bottom: 33px;
    }
  }

  &__btn {
    padding: 21px 32px;
    min-width: 225px;

    @include vp-767 {
      min-width: unset;
      margin: 8px 4px 0;
    }

    span {
      padding-left: 8px;
    }

    &:not(.btn--bg-green) {
      background-color: $color-default-white;

      @include vp-767 {
        border: 1px solid $color-solitude;
      }
    }
  }

  legend {
    margin-bottom: 9px;

    @include vp-767 {
      margin-bottom: 12px;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .form__field {
    max-width: 478px;

    @include vp-767 {
      max-width: none;
      margin-top: 0;
    }
  }

  .table {
    max-width: 548px;
    font-size: 16px;
    line-height: 34px;

    @include vp-767 {
      max-width: none;
    }
  }

  .custom-toggle--checkbox,
  .custom-toggle--radio {
    padding: 0;
  }

  .custom-toggle--checkbox {
    margin-bottom: 6px;
  }


  .custom-toggle--radio:not(.custom-radio) .custom-toggle__icon {
    top: 50%;
    transform: translateY(-50%);
  }

  .table tr:not(:last-child) {
    margin-bottom: 9px;
  }

  .table td {
    @include vp-767 {
      padding: 0 13px;
      max-width: 235px;
    }
  }

  .table td:not(:first-child) {
    @include vp-767 {
      flex-basis: 0;
      flex: 0 0 auto;
    }
  }

  .offer-card {
    width: 100%;

    @include vp-767 {
      margin-top: 18px;
      padding: 12px;
      border-radius: 12px;
    }
  }

  .offer-card__info {
    max-width: 600px;

    @include vp-767 {
      max-width: none;
      margin-right: 0;
    }
  }

  .offer-card__column {
    margin-left: 32px;
    padding-left: 0;
    max-width: 263px;

    @include vp-767 {
      margin-left: 0;
      max-width: none;
      flex: none;
    }
  }

  .form__row {
    @include vp-767 {
      display: block;
    }
  }

  .form__controls {
    @include vp-767 {
      display: flex;
      margin: 19px -4px 0;
      padding: 0;
      flex-wrap: wrap;
      position: unset;

      .btn {
        flex: 1 0 auto;
        width: unset;
      }

      .btn--bg-green {
        flex-basis: 167px;
      }
    }
  }

  .custom-toggle label span {
    font-size: 14px;
  }

  .upload-advertorial {
    @include vp-767 {
      margin: 6px auto 9px;
    }
  }
}
