.select-lang {
    z-index: 3;
    padding: 0;
    width: 71px;
    height: 40px;

    .custom-select__control {
        position: relative;
        border: 2px solid transparent;

        padding: 5px 10px 5px 6px;

        @include vp-767 {
            padding: 10px 24px 10px 0;
        }

        &:hover,
        &:focus {
            opacity: 0.7;
            transition: 0.3s;
        }
    }

    .custom-select__indicator {
        margin-left: 6px;
        width: 12px;
        height: 6px;
    }

    .custom-select__value-container,
    .custom-select__item {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: $color-black-russian;
    }

    .custom-select__value-container {
        opacity: 1;
    }

    .custom-select__menu-list{
        bottom: 0;
        border: none;
        background: $color-default-white;
        box-shadow: 0px 4px 10px rgba(201, 201, 201, 0.25);
        border-radius: 3px;
    }

    .custom-select__option {
        padding: 5px 10px 5px 6px;

        &:hover,
        &:focus {
            opacity: 0.7;
            transition: 0.3s;
        }
    }
}

