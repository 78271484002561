.specialists {
  @include vp-1023 {
    grid-row: 2/5;
  }

  @include vp-767 {
    grid-column: 1;
    grid-row: 2;
    margin-bottom: 34px;
    overflow: hidden;
    max-width: 100%;
    // overflow-x: scroll;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    &--service {
      overflow: hidden;
    }

    @include vp-1023 {
      padding-right: 0;
      // overflow: auto;
      flex-direction: row;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    @include vp-1023 {
      flex-direction: row;
      min-width: 1100px;
    }
  }

  &__item:last-child {
    @include vp-1023 {
      margin-right: 16px;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 8px;
    width: 272px;
    min-height: 352px;
    padding: 8px;
    background: $color-default-white;
    border: 1px solid $color-solitude;
    border-radius: 24px;
    user-select: none;
    transition: border-color $trans-default;

    & > div {
      max-height: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    @include vp-1023 {
      min-width: 187px;
      margin-right: 12px;
      margin-bottom: 0;
      min-height: 292px;
    }

    @include vp-767 {
      width: unset;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        border-color: $color-gray-chateau;
        transition: border-color $trans-default
      }
    }

    &-skills-list {
      list-style: none;
      padding: 0;
      margin: 0 0 6px 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-shrink: 1;
      overflow: hidden auto;

      @include vp-767 {
        margin: 0 0 3px 0;
        gap: 0;
      }
    }

    &-skills-item p {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $color-valhalla;

      @include vp-767 {
        font-size: 12px;
        color: $color-jaguar;
      }
    }
  }

  &__item-wrapper {
    position: relative;
    margin-bottom: 25px;
    border-radius: 18px;

    img {
      border-radius: 18px;
    }
  }

  &__raiting {
    position: absolute;
    transform: translate(7px, -50%);
    width: 51px;
    height: 24px;
    padding: 2px 2px 2px 20px;
    background-color: $color-shamrock;
    border-radius: 5px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      background: url("../../frontend/app/img/content/specialists/star-icon.svg") 50% 50%
        no-repeat;
    }

    & p {
      margin: 0;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $color-default-white;
    }

    @include vp-1023 {
      top: 139px;
    }
  }

  &__item--middle-raiting .specialists__raiting {
    background-color: $color-kournikova;
  }

  &__item--low-raiting .specialists__raiting {
    background-color: $color-watermelon;
  }

  &__item-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 6px;

    @include vp-767 {
      padding-left: 0;
    }
  }

  &__item-country-wrapper {
    display: flex;
    align-items: center;
    padding-right: 8px;
  }

  &__item-country-list {
    list-style: none;
    padding: 0;
    padding-right: 5px;
    margin: 0;
    margin-left: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @include vp-767 {
      margin: 0;
    }
  }

  &__item-country-item:not(:last-child) {
    margin-right: 5px;
  }

  &__item-country-item img {
    border-radius: 1px;
    margin: 0;

    @include vp-767 {
      margin: 0;
    }
  }

  &__item-content-count {
    font-weight: 500;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    background-color: inherit;
    color: $color-gray-chateau;
    width: min-content;
    height: min-content;
    white-space: nowrap;

    @include vp-767 {
      font-size: 10px;
      line-height: 18px;
    }
  }

  &__amountOfDeals {
    margin-right: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $color-gray-chateau;

    @include vp-767 {
      font-size: 10px;
      line-height: 18px;
      margin-right: 0;
    }
  }

  @include vp-767 {
    &__item-country-item img {
      width: 15px;
      height: 11px;
    }
  }

  &--row {
    .specialists__list {
      flex-direction: row;

      @include vp-1023 {
        width: min-content;
        min-width: unset;
        padding-right: 16px;
      }

      @include vp-767 {
        padding-right: 12px;
      }
    }

    .inner-container {
      @include vp-1023 {
        padding: 0;
        padding-left: 32px;
      }

      @include vp-767 {
        padding-left: 16px;
      }
    }

    .specialists__item-wrapper {
      @include vp-767 {
        margin-bottom: 22px;
      }
    }

    .specialists__item-img {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 192px / 247px * 100%;
      overflow: hidden;
      background-color: $color-gun-powder;
      border-radius: 18px;

      img {
        position: absolute;
        top: 50%;
        left: 0;
        width: auto;
        max-width: unset;
        height: 100%;
        transform: translateY(-50%);

        @include vp-767 {
          margin-bottom: 0;
        }
      }
    }

    .specialists__item {
      min-height: unset;

      @include vp-767 {
        min-width: 201px;
        padding-bottom: 16px;
      }
    }

    .specialists__item:not(:first-child) {
      margin-left: 21px;
      margin-right: 0;

      @include vp-1439 {
        margin-left: 16px;
      }

      @include vp-1023 {
        margin-left: 12px;
        margin-right: 0;
      }

      @include vp-767 {
        margin-right: 0;
      }
    }

    .specialists__item:first-child {
      @include vp-1023 {
        margin-right: 0;
      }
    }

    .specialists__raiting {
      @include vp-1023 {
        top: unset;
      }
    }
  }
}
