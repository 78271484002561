.modal-review-pro {
    .modal__content {
        padding: 88px 94px 80px;

        @include vp-767 {
            width: 100%;
            padding: 30px 20px 20px;
        }
    }

    .modal__content-button--background.btn--bg-green {
        background-color: $color-shamrock;
    }

    .modal__content-title {
        max-width: unset;
    }

    .modal__content-text {
        margin: 0 0 4px;
    }

    .modal__content-buttons {
        display: flex;
        justify-content: center;
        align-items: stretch;

        @include vp-767 {
            flex-direction: column;
            align-items: stretch;
        }
    }

    .modal__content-button {
        max-width: 264px;
        background-color: inherit;
        color: $color-black-russian;
        border: 1px solid $color-link-water;
        flex-grow: 1;
        padding: 18px 32px;

        &--background {
            background-color: $color-neon-blue;
            border: none;
            color: $color-default-white;
            padding: 18px 45px;
            margin-left: 8px;

            @include vp-767 {
                margin-left: 5px;
            }
        }

        @include vp-767 {
            max-width: unset;
        }
    }

    &__link {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-neon-blue;
        border-bottom: 1px solid rgba(67, 75, 238, 0.2);

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover,
            &:focus {
                opacity: 0.8;
            }
        }
    }

    .indicator-range,
    .indicator-star {
        margin-top: 27px;
        margin-bottom: 20px;
    }

    .indicator-range__title,
    .indicator-star__title {
        margin: 0;
        margin-bottom: 27px;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: $color-jaguar;
    }

    .indicator-range__control {
        padding: 21px 0;
    }

    .indicator-range__scale {
        position: relative;
        height: 8px;
        width: 100%;
        background: $color-ghost-white;
        border-radius: 32px;
    }

    .indicator-range__bar {
        position: absolute;
        height: 8px;
        width: 30%;
        background: $color-shamrock;
        border-radius: 32px;
    }

    .indicator-range__indicator-index {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 8px 24px;
        background-color: $color-shamrock;
        font-weight: 800;
        font-size: 24px;
        line-height: 34px;
        color: $color-default-white;
        border-radius: 136px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        & svg {
            margin-right: 8px;
        }
    }

    .custom-textarea {
        margin-bottom: 24px;
    }

    .custom-textarea__label {
        position: absolute;

        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;

        white-space: nowrap;

        border: 0;

        clip: rect(0 0 0 0);
        clip-path: inset(100%);
    }

    .indicator-star__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }

    .indicator-star__item {
        &:not(:last-child) {
            margin-right: 4px;
        }
    }

    .indicator-star__item-button {
        position: relative;
        padding: 0;
        cursor: pointer;
        border: none;
        background-color: inherit;

        &-icon {
            fill: $color-ghost-white;
            transition: fill 0.3s ease;
        }
    }

    .indicator-star__item-button-index {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 800;
        font-size: 16px;
        line-height: 34px;
        color: $color-default-white;
    }

    .indicator-star__item-button.is-active .indicator-star__item-button-icon {
        fill: $color-shamrock;
        transition: fill 0.3s ease;
    }
}
