.map {
  position: relative;

  &__placeholder,
  &__container {
    border-radius: 12px;
    min-height: 379px;
    overflow: hidden;
  }

  &__container {
    width: 100%;
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      max-width: unset;
    }
  }

  &__radar {
    position: absolute;
    top: 30px;
    left: 43%;
    width: 314px;
    height: 314px;

    background-color: $color-shamrock;
    transform: translateX(-50%);
    border-radius: 50%;
    opacity: 0.2;
    z-index: 10;
  }

  &__pin-icon {
    margin-right: 10px;
    width: 16px;
    height: 20px;
  }
}
