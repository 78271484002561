.slider {
  position: relative;

  &__title {
    margin-bottom: 16px;
  }

  &__wrapper {
    display: flex;
    overflow: hidden;

    @include vp-1023 {
      overflow: auto;
    }

    @include vp-767 {
      padding-right: 11px;
    }
  }

  &__slide {
    flex-shrink: 0;
    width: 23%;
    aspect-ratio: 1/1;
    margin-right: 22px;
    background-color: $color-gun-powder;
    overflow: hidden;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include vp-767 {
      margin-right: 11px;
      max-width: unset;
      width: 131px;
      height: 131px;
    }
  }

  &__slide:last-child {
    margin-right: 0;
  }

  &__btn {
    position: absolute;
    top: 52%;
    right: -18px;

    width: 40px;
    height: 40px;
    padding: 9px;

    color: inherit;

    border-radius: 50%;
    background-color: $color-default-white;
    box-shadow: 0px 4px 28px rgba(35, 35, 42, 0.11);

    @include vp-1023 {
      display: none;
    }
  }
}
