.price-deals {
    &__wrapper {
        display: flex;
        background-color: $color-ghost-white;
        border-radius: 24px;

        @include vp-767 {
            flex-direction: column-reverse;
        }
    }

    &__specialists {
        margin-right: 50px;
        width: 100%;
    }

    @include vp-767 {
        padding-left: 0;
        grid-row: 3;
    }
}

.price-deals__info {
    padding: 122px 8.5% 80px;
    max-width: 724px;

    @include vp-1439 {
        padding: 80px 30px 50px;
    }

    @include vp-1023 {
        margin-left: 0;
        margin-bottom: 27px;
        padding-bottom: 33px;
        padding-top: 33px;
        padding-left: 16px;
    }
}

.price-deals__info-title {
    margin: 0 0 31px;
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    color: $color-jaguar;

    @include vp-1023 {
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 32px;
    }
}

.price-deals__info-text {
    margin: 0 0 34px;
    padding-right: 32px;
    font-size: 18px;
    line-height: 30px;
    color: $color-jaguar;

    @include vp-1023 {
        padding-right: 0;
        font-size: 14px;
        line-height: 22px;
    }
}

.price-deals__info-text-title {
    margin: 0 0 15px;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;

    @include vp-1023 {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 25px;
    }
}

.price-deals__info-list {
    margin: 0 0 42px 23px;
    padding: 0;

    @include vp-1023 {
        margin-bottom: 32px;
    }
}

.price-deals__info-item p {
    margin: 0 0 8px;
    color: $color-jaguar;
    line-height: 24px;

    @include vp-1023 {
        font-size: 14px;
        line-height: 22px;
    }
}

.price-deals__info-all {
    position: relative;
    display: block;
    margin-left: 7px;
    width: fit-content;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: $color-shamrock;

    &:hover {
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $color-shamrock;
        }
    }

    &:focus {
        margin-top: -4px;
        margin-left: 3px;
        padding: 3px;
        border: 1px solid $color-pink-flamingo;
        border-radius: 4px;
        outline: none;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 2px;
            width: 100%;
            height: 1px;
            background-color: $color-shamrock;
        }
    }
}

.price-deals__img {
    flex: 1 1 auto;
    display: flex;
    align-items: center;

    @include vp-767 {
        max-width: 375px;
    }
}

.price-deals__img img {
    width: 100%;
}
