.details {
  font-size: 18px;
  line-height: 34px;
  font-weight: 700;

  @include vp-1023 {
    font-size: 16px;
    line-height: 1.2;
  }

  @include vp-767 {
    font-size: 14px;
    line-height: 26px;
  }

  dl {
    display: flex;
    flex-wrap: wrap;

    margin: 0;
  }

  dt,
  dd {
    padding: 16px 0 14px;
    border-bottom: 1px solid $color-link-water;

    @include vp-767 {
      padding-bottom: 18px;
    }
  }

    dt {
      flex: 1 0 390px;
      flex: 1;
      padding-right: 16px;
      min-width: 50%;
    }

  dt:last-of-type,
  dd:last-of-type {
    border-bottom: 0;
  }

  dd {
    margin-left: 0;
    flex: 1 0 130px;
    text-align: right;

    @include vp-767 {
      flex: none;
    }
  }

  &__text {
    padding-top: 3px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @include vp-767 {
      margin-right: -100px;
      padding-top: 15px;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
