.simplebar-track.simplebar-vertical {
    background-color: $color-ghost-white;
    border-radius: 32px;
    width: 4px;
}

.simplebar-scrollbar {
    &::before {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background-color: $color-gray-chateau;
        opacity: 1;
        border-radius: 32px;
    }
    &.simplebar-visible {
        &::before {
            opacity: 1;
        }
    }
}