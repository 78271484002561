.subtitle {
  margin: 0;
  font-size: 24px;
  line-height: 40px;
  color: $color-black-russian;

  @include vp-1023 {
    font-size: 18px;
    line-height: 26px;
  }

  @include vp-767 {
    font-size: 14px;
  }

  &--sm {
    font-size: 18px;
    line-height: 34px;
    color: inherit;

    @include vp-1023 {
      font-size: 16px;
      line-height: 1.2;
    }

    @include vp-767 {
      font-size: 14px;
    }
  }
}
