.about-service-page {
    .promo__content-text {
        max-width: 642px;
    }

    .promo__title {
        margin: 0;
        margin-bottom: 28px;
    }

    .promo__subtitle {
        margin: 0;
        margin-bottom: 28px;
    }

    .promo__text {
        margin: 0;
        font-weight: 400;
        color: $color-black-russian;

        @include vp-1023 {
            font-size: 16px;
            line-height: 24px;
        }
    }

    // .promo__content-wrapper {
    //     &::after {
    //         display: none;
    //     }
    // }

    .promo__background-image-wrapper {
        margin-right: 33px;
    }
}

.about-service-page {
    .questions-about {
        margin-bottom: 40px;

        .title p {
            margin-top: 0;
        }
    }

    .accordion {
        .title {
            margin-bottom: 52px;

            @include vp-1023 {
                margin-bottom: 16px;
            }
        }
    }

    .accordion__element {
        &-main {
            padding: 24px 0;

            &:not(:first-of-type) {
                border-top: 1px solid $color-solitude;
            }

            &:last-child {
                border-bottom: 1px solid $color-solitude;
                padding-bottom: 60px;
            }

            @include vp-1023 {
                padding: 22px 0;

                &:last-child {
                    padding-bottom: unset;
                }
            }
        }
    }

    .accordion__element-button {
        position: relative;
        font-weight: 800;
        font-size: 24px;
        line-height: 48px;
        padding: 0;
        padding-left: 47px;
        text-transform: none;
        color: $color-jaguar;
        background-color: inherit;
        border: none;
        cursor: pointer;
        display: inline;
        text-align: left;

        @include vp-1023 {
            font-size: 18px;
            line-height: 22px;
            padding-left: 30px;
        }

        @include vp-767 {
            font-size: 15px;
            line-height: 22px;
            padding-left: 30px;
        }

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover,
            &:focus {
                opacity: 0.6;
            }
        }
    }

    .accordion__element-button-icon {
        position: absolute;
        top: 16px;
        left: 9px;
        transform: rotate(-90deg);
        fill: $color-gray-chateau;
        width: 24px;
        height: 16px;

        @include vp-1023 {
            top: 6px;
            left: 0;
            width: 20px;
            height: 13px;
        }

        @include vp-767 {
            top: 6px;
            left: 0;
            width: 17px;
            height: 10px;
        }
    }

    .accordion__element-button-title {
        margin-right: 13px;

        p {
            margin: 0;
        }

        @include vp-767 {
            margin-right: 5px;
        }
    }

    .accordion__content {
        display: none;
        padding: 37px 0 33px 48px;
        max-width: 1140px;
    }

    .accordion__content p {
        margin: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: $color-jaguar;
    }
}

.accordion__element-main.is-active {
    .accordion__content {
        display: block;
    }

    .accordion__element-button-icon {
        fill: $color-neon-blue;
        transform: rotate(0);
    }
}
