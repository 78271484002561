.dropdown {
  padding: 38px 40px;

  font-size: 16px;
  line-height: 24px;

  @include vp-767 {
    padding: 10px 16px;

    font-size: 14px;
    line-height: 22px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__icon-btn {
    transform: rotate(90deg);
    transition: transform $trans-default;
  }

  &__title {
    flex: 1;
  }

  &__content {
    display: none;

    @include vp-767 {
      padding: 14px 0;
    }
  }

  &__btn {
    padding: 18px;
    padding-right: 4px;
    margin-left: auto;
  }

  &__item {
    margin-bottom: 9px;
  }

  &__sub {
    @include vp-767 {
      margin-bottom: 16px;
    }
  }

  .slider {
    padding-bottom: 10px;

    @include vp-767 {
      margin-right: -16px;
      padding-bottom: 0;
    }
  }

  .details {
    @include vp-767 {
      margin-bottom: 12px;
    }
  }

  &.is-open {
    .dropdown__icon-btn {
      transform: rotate(-90deg);
    }

    .dropdown__content {
      display: block;
    }
  }
}
