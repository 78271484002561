.custom-radio {
  display: inline-flex;
  align-items: center;
  width: unset;
  padding: 0;
  color: $color-jaguar;

  label {
    padding-left: 0;
    min-width: 264px;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        opacity: 1;
      }
    }
  }

  label span {
    color: inherit;
  }

  .custom-toggle__icon {
    position: unset;

    display: inline-block;
    vertical-align: middle;

    margin-right: 26px;
    background-color: rgba($color-default-white, 0.4);
    box-shadow: none;
    transform: translateY(-2px);

    &::before {
      background-color: $color-default-white;
    }

    @include vp-767 {
      margin-right: 8px;
      transform: translateY(0);
    }
  }

  .custom-toggle--radio .custom-toggle__icon::before {
    position: unset;
  }


  .custom-radio__label {
    display: block;
    padding: 27px 24px;
    padding: 26px 23px 28px 31px;
    border-radius: 8px;

    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    transition: background-color $trans-default;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100vw - 32px);
    border-radius: 24px;
    box-shadow: 0 0 0 1px $color-link-water;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: rgba($color-shamrock, 0.2);
      }
    }

    @include vp-767 {
      font-size: 12px;
      padding: 9px 12px;
      border-radius: 10px;
      text-align: center;
    }
  }

  input:focus + .custom-radio__label,
  input:checked + .custom-radio__label {
    background-color: $color-shamrock;
    box-shadow: none;
    color: $color-default-white;
  }

  input:checked + .custom-toggle__icon {
    border-color: rgba($color-default-white, 0.1);
  }

  &.is-active {
    border-radius: 8px;
    background-color: $color-quartz;

    .custom-radio__label {
      padding: 6px 10px;
      padding-right: 0;
      background-color: $color-quartz;
      max-width: calc(100vw - 108px);
    }
  }

  .btn {
    color: inherit;
    padding: 10px;
    padding-right: 15px;
    background-color: transparent;
  }
}
