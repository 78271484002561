.offer-card {
  display: flex;

  padding: 40px;
  min-height: 331px;
  border-radius: 24px;
  border: 1px solid $color-link-water;

  color: $color-jaguar;

  @include vp-767 {
    min-height: unset;
    padding: 24px;
    padding-bottom: 32px;
    flex-direction: column;

    font-size: 14px;
    line-height: 22px;
  }

  &__info {
    margin-right: auto;
    max-width: 579px;

    @include vp-767 {
      margin-bottom: 14px;
    }
  }

  &__title {
    margin: 12px 0 20px;
    color: inherit;

    @include vp-767 {
      margin: 15px 0 20px;
      letter-spacing: 0.02em;
    }
  }

  &__btn {
    display: block;
    margin-left: auto;
    padding: 18px 32px;
    letter-spacing: 0.03em;
    font-size: 14px;

    @include vp-767 {
      margin-left: 0;
      width: 100%;
      font-size: 12px;
    }
  }

  &__column {
    flex: 1 1 0;
    padding-left: 17%;

    @include vp-767 {
      padding-left: 0;
    }
  }

  .custom-toggle label span {
    font-size: 16px;
    line-height: 34px;
  }

}
