.service-partner {
  min-width: 264px;
}

.service-partner__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;

  @include vp-1023 {
    flex-direction: row;
    overflow: auto;
  }
}

.service-partner__item {
  margin-bottom: 8px;

  @include vp-1023 {
    margin-right: 12px;
    margin-bottom: 0;
    min-width: 207px;
  }

  @include vp-767 {
    margin-right: 8px;
    min-width: 207px;
    box-sizing: content-box;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.service-partner__item:last-child {
  @include vp-1023 {
    margin-right: 0;
    padding-right: 16px;
  }
}


.service-partner__item a {
  transition: 0.4s;

  &:hover,
  &:focus {

    img {
      opacity: 0.8;
      transition: 0.4s;
    }
  }
}

.service-partner__item img {
  border-radius: 16px;
}
