.specialists-country {
  display: flex;
  align-items: center;
  padding-right: 8px;

  &__list {
    list-style: none;
    padding: 0;
    padding-right: 5px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @include vp-767 {
      margin: 0;
    }
  }

  &__item:not(:last-child) {
    margin-right: 5px;
  }

  &__item img {
    border-radius: 1px;
    margin: 0;

    @include vp-767 {
      margin: 0;
    }
  }

  &__count {
    font-weight: 500;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    background-color: inherit;
    color: $color-gray-chateau;
    width: min-content;
    height: min-content;
    white-space: nowrap;

    @include vp-767 {
      font-size: 10px;
      line-height: 18px;
    }
  }
}
