.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: auto;
    padding: 0;
    margin: 0 -17px;
    &__item {
        list-style-type: none;
        padding: 0;
        &:not(:last-child) {
            &::after {
                content: '/';
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                color: $color-gray-chateau;
            }
        }
        @include vp-767 {
            margin-right: 5px;
        }
    }
    &__link {
        padding: 0 17px;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: $color-gray-chateau;
        transition: opacity $trans-default;
        &:hover,
        &:active {
            opacity: 0.7;
        }
        &--active {
            color: $color-neon-blue;
            &:hover,
            &:active {
                opacity: 1;
            }
            .service-page & {
                color: $color-shamrock;
            }
        }
        @include vp-767 {
            padding: 0;
        }
    }

    @include vp-767 {
        margin: 0;
    }
}