.bonus-page {
  padding-bottom: 230px;

  @include vp-1279 {
    padding-bottom: 130px;
  }

  @include vp-767 {
    padding-bottom: 70px;
  }
}
