.cards-orders {
    margin-right: 20px;

    @media (max-width: 1585px) {
        margin-right: 0;
    }

    &__list {
        margin: 0 0 32px;
        padding: 0;
        list-style: none;

        @include vp-767 {
            margin-bottom: 13px;
        }
    }

    &__item {
        margin-bottom: 20px;
        padding: 39px 36px 51px 40px;
        border: 1px solid $color-link-water;
        border-radius: 24px;
        cursor: pointer;

        @include vp-767 {
            margin-bottom: 12px;
            padding: 21px 20px 30px 23px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item-top-wrapper {
        margin-bottom: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-content: center;
        justify-items: baseline;

        @include vp-767 {
            margin-bottom: 22px;
        }
    }

    &__item-status {
        margin: 0 0 13px;
        padding: 6px 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 8px;

        @include vp-767 {
            margin-bottom: 9px;
            padding: 5px 9px;
            font-size: 12px;
        }

        &--active {
            color: $color-jungle-green;
            background-color: rgba($color-shamrock, 0.2);
        }

        &--spec-check {
            color: $color-neon-blue;
            background-color: rgba($color-neon-blue, 0.2);
        }

        &--fulfil {
            color: $color-default-white;
            background-color: $color-shamrock;
        }

        &--draft {
            color: $color-jaguar;
            background-color: rgba($color-default-black, 0.1);
        }

        &--blue {
            color: $color-deep-sky-blue;
            background-color: rgba($color-deep-sky-blue, 0.2);
        }

        &--red {
            color: $color-torch-red;
            background-color: rgba($color-torch-red, 0.1);
        }
    }

    &__item &__item-dotted:focus-within {
        color: $color-neon-blue;
    }

    &__item-dotted {
        position: relative;
        margin-top: -11px;
        justify-self: end;
        cursor: pointer;

        &:hover,
        &:focus-within {
            color: $color-neon-blue;
        }
    }

    &__item-title {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        grid-column: 1/-1;
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        color: $color-jaguar;

        .title {
            margin-right: auto;
            padding-right: 16px;
            font-weight: 700;
        }

        span {
            display: block;
            font-weight: 700;
        }

        @include vp-767 {
            font-size: 18px;
            font-weight: 800;
            line-height: 26px;
        }
    }

    &__item-bottom-wrapper {
        display: flex;
        justify-content: space-between;

        @include vp-767 {
            flex-direction: column;
        }
    }

    &__item-info {
        margin-right: 10px;

        @include vp-767 {
            margin-right: 0;
            margin-bottom: 18px;
        }
    }

    &__item-info-additional {
        position: relative;
        bottom: 0;
    }

    &__item-create,
    &__item-deadline,
    &__item-repeat {
        margin: 0;
        margin-bottom: 6px;
        display: flex;
        color: $color-gray-chateau;

        @include vp-767 {
            font-size: 14px;
            line-height: 22px;
        }
    }

    &__item-create-date,
    &__item-deadline-date,
    &__item-repeat-text {
        margin-left: 8px;
        display: block;
        color: $color-jaguar;

        @include vp-767 {
            font-size: 14px;
            line-height: 22px;
        }
    }

    &__user {
        display: flex;
        align-items: center;
        color: $color-jaguar;
        font-weight: 700;

        .avatar {
            margin-right: 10px;
        }
    }

    &__btn {
        padding: 18px 33px;
        font-size: 14px;
        line-height: 20px;

        & + & {
            margin-top: 8px;
        }
    }

    .pagination {
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: -2px;

        @media (max-width: 374px) {
            max-width: 280px;
        }
    }
}
