.filter-orders {
    margin-right: 20px;
    width: 262px;

    @include vp-1199 {
        grid-row: 1;
        margin-right: 0;
        width: 100%;
    }

    &__date {
        margin-top: 10px;
        margin-bottom: 14px;

        .date-field__label {
            left: 14px;
            font-size: 18px;
            line-height: 30px;
            text-transform: lowercase;
            color: #9b9eac;
        }

        .date-field__icon {
            right: 15px;
        }

        .date-field__icon,
        .date-field__label {
            top: 20px;
        }

        .custom-input label input {
            margin-bottom: 16px;
            padding: 7px 52px 7px 70px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .filter__block {
        margin-bottom: 0;
    }

    .button-view-all {
        margin-bottom: 0;
    }

    .filter__wrap-block {
        margin-bottom: 26px;

        .choose-your-language {
            display: none;
        }
    }

    .filter__btn-subtitle {
        padding-top: 0;
        padding-bottom: 11px;

        @include vp-1199 {
            padding: 15px 0 16px;
        }

        &:not(:last-child) {
            @include vp-1199 {
                border-bottom: none;
            }
        }
    }

    .form__row {
        display: block;
    }

    .custom-toggle__label {
        font-size: 16px;
        line-height: 24px;
    }

    .toggle-place__btn-delete {
        right: 8px;
    }
}
