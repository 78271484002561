.user-panel {
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 20px;

  &__avatar {
    position: relative;
    margin-right: 12px;
  }

  &__bonus,
  &__link {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  }

  &__link {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 20px;
    height: 20px;

    color: $color-default-white;
    text-align: center;

    background-color: $color-neon-blue;
    border-radius: 50%;
    transition: opacity $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }

  &__link--green {
    background-color: $color-shamrock;
  }

  &__name {
    padding-right: 9px;

    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-weight: 700;
  }

  &__bonus {
    font-weight: 500;
  }
}
