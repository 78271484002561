.file-field {
  margin-bottom: 37px;

  @include vp-767 {
    margin-bottom: 30px;
  }

  &__row {
    margin: 0 0 30px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  &__text {
    margin: 0;
    font-size: 18px;
    line-height: 30px;
    color: $color-black-russian;

    @include vp-767 {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__btn {
    width: 24px;
    height: 24px;
    margin-right: 12px;

    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .custom-input {

    label input {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      white-space: nowrap;
      border: 0;
      clip: rect(0 0 0 0);
      clip-path: inset(100%);
    }

    label {
      max-width: max-content;
      padding-left: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.06em;

      color: $color-neon-blue;
      text-transform: uppercase;

      cursor: pointer;

      &:focus-within {
        opacity: 0.7;
      }
    }
  }
}
