.popup-response {
  position: absolute;
  padding: 8px 16px;
  top: -100px;
  left: -10px;
  width: 274px;
  background-color: $color-default-white;
  box-shadow: 0px 20px 76px rgba($color-gun-powder, 0.1);
  border-radius: 12px;
  display: none;

  transition: $trans-default;

  @include vp-1199 {
    top: inherit;
    right: -10px;
    bottom: -95px;
    left: inherit;
  }

  &::after {
    content: "";
    position: absolute;
    left: 10px;
    bottom: -14px;
    width: 12px;
    height: 12px;
    background-color: $color-default-white;
    transform: rotate(45deg) translateY(-100%);

    @include vp-1199 {
      top: 3px;
      right: 30px;
      bottom: inherit;
      left: inherit;
    }
  }
}
