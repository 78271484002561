// Colors
// ---------------------------------

// Default

$color-default-black: #000000;
$color-default-white: #ffffff;
$color-transparent: rgba(255, 255, 255, 0);

// Project palette
// для нейминга цветов используем https://www.htmlcsscolor.com/hex/334482

$color-neon-blue: #434bee;
$color-jaguar: #23232a;
$color-black-russian: #17171b;
$color-quartz: #d9dbfc;
$color-humming-bird: #d3f7ea;
$color-gray-chateau: #9ea4a7;
$color-shamrock: #22d695;
$color-solitude: #e5e6eb;
$color-link-water: #d2d5dd;
$color-hawkes-blue: #dedfe3;
$color-mischka: #9b9eac;
$color-solitude-2: #f4f5f7;
$color-red-oranges: #f93232;
$color-ghost-white: #f4f4f6;
$color-valhalla: #292940;
$color-kournikova: #fbcb51;
$color-watermelon: #fe5963;
$color-pink-flamingo: #f845fc;
$color-gun-powder: #48495f;
$color-hawkes-blue: #dadce3;
$color-cosmos: #ffd8d8;
$color-snow: #fff5f5;
$color-jungle-green: #29ba85;
$color-deep-sky-blue: #00c2ff;
$color-sweet-pink: #e48a85;
$color-gamboge: #ec930e;
$color-equator: #e2b064;
$color-torch-red: #ec0e28;
$color-shamrock2: #32BD8B;
$color-sky-blue: #D9DBFC;
$color-sky-green: #D3F7EA;

// Gradient

$black-to-right: linear-gradient(90deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0) 75%);

$opacity-to-white: linear-gradient(180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%);

// Typography
// ---------------------------------

// переменная используемая в html для подключения скейлинга

$fz-default: clamp(12px, 0.25vw + 0.7rem, 16px);

// у некоторых шрифтов в ios возникают проблемы с символом рубля
// https://www.artlebedev.ru/kovodstvo/sections/159/#13
$ff-rouble: "Rouble", "Arial", sans-serif;

$ff-sf-pro-display: "SF Pro Display", "Arial", sans-serif;
$ff-montserrat: "Montserrat", "Arial", sans-serif;

// Animation
// ---------------------------------

$tf-default: ease;
$trans-default: 0.3s $tf-default;

// если требуется изинг отличный от $tf-default, то переменную называем не цифрой
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);

$trans-600: 0.6s $tf-default;

// Viewports
// ---------------------------------

$vp-320: 320px;
$vp-375: 375px;
$vp-480: 480px;
$vp-768: 768px;
$vp-1024: 1024px;
$vp-1200: 1200px;
$vp-1280: 1280px;
$vp-1440: 1440px;
$vp-1920: 1920px;

// Retina
// ---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;