.order-stats {
  padding-top: 18px;

  @include vp-767 {
    padding: 0;
  }

  &__wrapper {
    padding: 20px 0 80px 120px;

    @include vp-1439 {
      padding: 20px 120px 80px;
    }

    @include vp-1023 {
      padding: 20px 80px 120px;
    }

    @include vp-767 {
      padding: 0 16px 9px;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: stretch;

    @include vp-1023 {
      flex-wrap: wrap;
    }

    @include vp-767 {
      flex-direction: column;
    }
  }

  &__item {
    border-bottom: 5px solid $color-quartz;
    max-width: 440px;
    width: 100%;
    padding-bottom: 29px;
    margin-right: 39px;

    &:not(:first-child) {
      margin-left: 5px;

      @include vp-1023 {
        margin-left: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &--green {
      border-bottom: 5px solid $color-humming-bird;
    }

    &--grey {
      border-bottom: 5px solid $color-solitude;
    }

    @include vp-1023 {
      width: unset;
      flex: 1;
    }

    @include vp-767 {
      padding-bottom: 14px;
      margin-right: 0;
      margin-bottom: 17px;
      max-width: unset;
    }
  }

  &__item:last-child {
    @include vp-767 {
      margin-bottom: 0;
    }
  }

  &__item p {
    margin: 0;
    font-weight: 800;
    font-size: 54px;
    line-height: 150%;
    color: $color-black-russian;

    @include vp-1439 {
      font-size: 34px;
      line-height: 34px;
    }

    @include vp-1023 {
      font-size: 26px;
      line-height: 34px;
    }
  }

  &__item span {
    margin: 0;
    font-family: $ff-montserrat;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: $color-black-russian;

    @include vp-767 {
      font-family: $ff-sf-pro-display;
    }
  }
}
