.content-wrapper {
  h2 {
    margin: 0 0 40px;
    font-size: 46px;
    line-height: 62px;
    font-weight: 800;

    @include vp-1439 {
      font-size: 40px;
      line-height: 1.3;
    }

    @include vp-1023 {
      font-size: 32px;
    }

    @include vp-767 {
      margin: 0 0 24px;
      font-size: 26px;
    }
  }

  p {
    margin: 0;
  }

  .table {
    max-width: 77%;
    margin-bottom: 24px;

    @include vp-1439 {
      max-width: 90%;
    }

    @include vp-1023 {
      max-width: unset;
    }
  }

  p:not(:last-of-type) {
    margin-bottom: 24px;
  }

  .table {
    font-size: 16px;
  }

  a {
    display: inline-flex;
    align-self: flex-start;
    color: $color-neon-blue;
    line-height: 2.1;
    transition: opacity $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }
  }
}
