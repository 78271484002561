.slider-block-container {
  position: relative;
  width: 100%;
}

.slider-block {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: max-content;
  transition: transform $trans-default;

  &--row-column {
    @media (min-width: 1339px) {
      flex-direction: column;
    }
  }

  &--customer {
    @media (min-width: 1023px) {
      flex-direction: column;
    }
  }

  &--specialist {
    @media (min-width: 1199px) {
      flex-direction: column;
    }
  }
}

.image {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.right-arrow,
.left-arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: none;

  position: absolute;
  top: calc(50% - 30px);
  z-index: 10;
  user-select: none;
  cursor: pointer;

  @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
          opacity: 0.8;
      }
  }

  &.hide {
    display: none;
  }
}

.left-arrow {
  transform: rotate(180deg);
}

.right-arrow {
  right: 32px;
}

.left-arrow {
  left: 0;
}

.slide-block {
  height: 100%;
  width: 284px;
  flex-grow: 1;
  flex-basis: min-content;

  & > li {
    height: 352px;
    margin-bottom: 8px;
  }
}