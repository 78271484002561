.accordion-form {

  &__list {
    max-width: 1116px;
    margin: 0;
    padding: 0 0 44px;
    list-style: none;

    @include vp-1279 {
      max-width: 100%;
    }
  }

  &__item-content {
    padding: 32px 0 35px;
  }

  &__item-wrap {
    max-width: 831px;
  }

  &__item-subtitle {
    width: 100%;
    padding: 24px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 800;
    font-size: 32px;
    line-height: 48px;

    color: $color-jaguar;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $color-link-water;
    text-align: left;
    cursor: pointer;
    transition: color $trans-default;

    @include vp-1439 {
      font-size: 24px;
      line-height: 36px;
    }

    @include vp-1023 {
      padding: 20px 0;
      font-size: 22px;
      line-height: 32px;
    }

    @include vp-767 {
      font-size: 18px;
      line-height: 24px;
    }

    @include vp-374 {
      padding: 15px 0;
      font-size: 16px;
      line-height: 20px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      transform: rotate(90deg);
      transition: color $trans-default, transform $trans-default;

      @include vp-767 {
        width: 15px;
        height: 15px;
      }

      @include vp-374 {
        width: 10px;
        height: 10px;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-neon-blue;
        transition: color $trans-default;

        svg {
          transform: rotate(-90deg);
          color: $color-neon-blue;
          transition: color $trans-default, transform $trans-default;
        }
      }
    }
  }

  &__item-text {
    max-width: 974px;
    margin: 0;
    padding-bottom: 21px;

    font-size: 24px;
    line-height: 40px;
    color: $color-gray-chateau;

    @include vp-1439 {
      font-size: 22px;
      line-height: 32px;
    }

    @include vp-1023 {
      font-size: 18px;
      line-height: 30px;
    }
  }

  .new-order-field {
    margin-bottom: 32px;

    .custom-radio__label {
      font-size: 18px;
      line-height: 30px;
    }
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .btn {
      min-width: 145px;
      padding: 17px 32px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.03em;

      @include vp-1279 {
        padding: 10px;
        font-size: 12px;
        line-height: 18px;
      }

      @include vp-1023 {
        min-width: 100%;
        margin-bottom: 10px;
      }
    }

    .btn:not(:last-child) {
      margin-right: 18px;

      @include vp-1279 {
        margin-right: 15px;
      }
    }
  }

  &__item-content {
    display: none;
  }

  &__item-subtitle.is-open ~ .accordion-form__item-content {
    display: block;
  }

  &__item-subtitle.is-open svg {
    transform: rotate(-90deg);
  }
}
