.price-page {

    @include vp-1023 {
        padding: 0 32px;
    }

    @include vp-767 {
        padding: 0 16px;
    }

    &__block:not(:last-child) {
        margin-bottom: 120px;

        @include vp-1439 {
            margin-bottom: 90px;
        }

        @include vp-1023 {
            margin-bottom: 60px;
        }

        @include vp-767 {
            margin-bottom: 36px;
        }
    }
}
