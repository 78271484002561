﻿.promotion-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    background: #f4f4f6;
    min-height: 100vh;
}

.promotion-card {
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.company-section,
.promotion-section {
    display: flex;
    align-items: start;
    gap: 20px;
}

@media (max-width: 550px) {
    .company-section,
    .promotion-section{
        flex-direction: column;
    }

    .company-avatar {
        order: -1;
    }

}

.company-avatar,
.promotion-avatar {
    width: 140px;
    height: 140px;
    border-radius: 14px;
    object-fit: cover;
}

.company-info,
.promotion-info {
    flex: 1;
}

.company-name {
    font-size: 2em;
    margin: 0;
    color: #333;
}

.company-description,
.promotion-description {
    font-size: 1em;
    margin-top: 10px;
    color: #666;
}

.promotion-title {
    font-size: 1.6em;
    margin: 0;
    color: #333;
}
