.new-order-start {
  &__field {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 15px 20px;

    font-size: 18px;
    line-height: 30px;

    background-color: $color-default-white;
    border: 1px solid $color-hawkes-blue;
    border-radius: 12px;
    outline: none;
    transition: border-color $trans-default;

    @include vp-767 {
      font-size: 16px;
      line-height: 24px;
    }

    &::placeholder {
      color: $color-mischka;
    }

    &:hover,
    &:focus {
      border-color: $color-neon-blue;
    }

    input {
      border: none;
      outline: none;
      background-color: inherit;
      padding: 0;
      flex-grow: 1;

      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: $color-jaguar;
    }

    &--selected {
      padding: 9px 20px;

      input {
        padding: 6px 12px;
      }
    }
  }

  &__toggle {
    cursor: pointer;
    max-width: 100%;
    margin: 0 9px 12px 0;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: $color-jaguar;

    display: inline-block;
    padding: 6px 12px;
    border: none;
    border-radius: 8px;
    background-color: $color-ghost-white;
    transition: background-color $trans-default;
    white-space: nowrap;

    @include vp-767 {
      white-space: normal;
    }

    @media (hover: hover),
    screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-quartz;
      }
    }

    &:focus {
      outline: none;
      background-color: $color-quartz;
    }

    @include vp-767 {
      padding: 8px 12px;
    }

    &.is-active {
      position: relative;
      padding: 6px 12px;
      background-color: $color-quartz;
      border-radius: 8px;
      color: $color-jaguar;

      margin: 0 9px 12px 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include vp-767 {
        margin: 0;
      }
    }

    .btn {
      color: inherit;
      padding: 3px;
      background-color: transparent;
    }

    &--category {
      background-color: $color-sky-blue;
    }

    &--type {
      background-color: $color-sky-green;
      
    }

    // &--subtype {
      // color: $color-gamboge;
    // }

    &--preloader {
      white-space: normal;
      &:hover {
        background-color: $color-ghost-white;
      }
    }
  }

  &__search-field label input.custom-input {
    border: none;
    padding: 6px 12px;
  }

  .form__controls {
    margin-top: 29px;

  }
}