.upload {
  position: relative;

  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  overflow: hidden;

  border-radius: 4px;
  border: 1px dashed $color-shamrock;
  overflow: auto;

  &__field {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 16px;
    transition: opacity $trans-600;
    cursor: pointer;
    transform: translate(-50%, -50%);

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }

  &__file-icon {
    margin: auto;
  }

  &__file-info {
    display: none;
    padding: 8px 28px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include vp-1023 {
      padding: 8px 22px;
    }

    @include vp-767 {
      padding: 8px;
    }
  }

  &__content {
    display: none;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  input {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  &__delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    border: 0;

    display: none;
  }

  &.not-empty {
    border: 0;
    background-color: $color-ghost-white;

    .upload__field {
      display: none;
    }

    .upload__content,
    .upload__file-info {
      display: block;
    }

    .upload__delete-btn {
      display: flex;
    }

  }
}
