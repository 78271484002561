.offer-page {
  padding-bottom: 185px;
  font-size: 18px;
  line-height: 1.7;

  @include vp-1439 {
    padding-bottom: 140px;
  }

  @include vp-1023 {
    font-size: 16px;
  }

  .section {
    margin-bottom: 117px;

    @include vp-1439 {
      margin-bottom: 80px;
    }
  }

  .section:last-child {
    margin-bottom: 0;
  }

  .feedback-form {
    padding-bottom: 0;

    @include vp-1023 {
      display: grid;
    }
  }

  .feedback-form__button {
    margin-top: 30px;
  }
}
