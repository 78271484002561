.date-field {
  position: relative;
  color: $color-jaguar;

  transition: color $trans-default;

  &:focus,
  &:hover {
    color: $color-neon-blue;
  }

  label input {
    padding: 15px 60px;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 24px;

    font-size: 18px;
    line-height: 30px;
    text-transform: capitalize;

    transform: translateY(-50%);

    color: $color-mischka;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 24px;

    width: 24px;
    height: 24px;

    pointer-events: none;

    transform: translateY(-50%);
  }

  input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: pointer;
  }

  input[type="date"]:required:invalid::-webkit-datetime-edit {
    color: transparent;
  }
  input[type="date"]:focus::webkit-datetime-edit {
    color: inherit;
  }
}
