.toggle-place {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__address {
    position: relative;
    margin: 0 0 10px;
    padding: 8px 12px;

    display: flex;
    flex-direction: column;

    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    color: $color-jaguar;
    background-color: $color-ghost-white;
    border-radius: 8px;

    span {
      color: $color-gray-chateau;
    }
  }

  &__btn-delete {
    position: absolute;
    top: 8px;
    right: 14px;

    width: 16px;
    height: 16px;
    padding: 0;

    background-color: transparent;
    border: none;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
      color: $color-jaguar;
    }
  }
}
