.modal-complaint {
  .modal__content {
    padding: 88px 94px 80px;

    @include vp-767 {
      width: 100%;
      padding: 30px 20px 20px;
    }
  }

  .modal__content-buttons {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .modal__content-button {
    max-width: 264px;
    background-color: inherit;
    color: $color-black-russian;
    border: 1px solid $color-link-water;
    flex-grow: 1;
    padding: 18px 32px;

    &--background {
      background-color: $color-neon-blue;
      border: none;
      color: $color-default-white;
      padding: 18px 45px;
      margin-left: 8px;

      @include vp-767 {
        margin-left: 5px;
      }
    }
  }

  .custom-textarea {
    margin-bottom: 24px;
  }

  .custom-textarea__label {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }
}
