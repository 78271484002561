.notfound-page {
    position: relative;
    width: 100%;
    margin: 0 auto;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px 100px 80px;

        //@include vp-1439 {
        //    padding-top: 80px;
        //    padding-bottom: 80px;
        //}
        //
        //@include vp-1023 {
        //    padding-top: 80px;
        //    padding-bottom: 80px;
        //}
        //
        //@include vp-767 {
        //    padding-top: 80px;
        //    padding-bottom: 80px;
        //}

        .notfound-info {
            text-align: center;
            width: 585px;
            height: 339px;

            &__title {
                font-weight: 600;
                font-size: 200px;
                line-height: 100%;
                color: #D2D5DD;
                margin: 0;

                @include vp-767 {
                    font-size: 10rem;
                }
            }

            &__description {
                font-weight: 700;
                font-size: 18px;
                line-height: 120%;
                color: #23232A;
                margin-bottom: 40px;
            }

            &__link {
                cursor: pointer;
                align-items: center;
                padding: 18px 32px;
                background: #434BEE;
                border-radius: 12px;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                color: #FFFFFF;
            }

            &__link.link-green {
                background: #22d695;
            }
        }
    }
}
