.bonus-balance {
  &__heading {
    margin: 0;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: $color-shamrock;
  }

  &__data,
  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-gray-chateau;
  }

  &__text {
    margin-bottom: 8px;
  }

  &__link {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
}
