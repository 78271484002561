.output {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;

    color: $color-default-white;
    background-color: $color-neon-blue;

    @include vp-1023 {
        background-color: $color-default-white;
        color: $color-jaguar;
        // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    &__container {
        width: 75%;
        margin: 0 auto;
        padding: 23px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @include vp-1439 {
            width: 100%;
        }

        @include vp-1023 {
            padding: 23px 16px;
            flex-direction: column;
        }
    }

    &__wrap-info {
        margin-right: 16px;
        display: flex;
        align-items: center;

        @include vp-1023 {
            margin-right: 0;
            flex-direction: column;
        }
    }

    &__list {
        margin: 0;
        margin-right: 16px;
        padding: 0;

        display: flex;
        align-items: center;
        list-style: none;

        @include vp-1023 {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__item {
        position: relative;
        width: 40px;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $color-default-white;
        border-radius: 50%;

        img {
            width: 90%;
            height: 90%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &__item:not(:first-child) {
        margin-left: -20px;
    }

    &__text {
        max-width: max-content;
        margin: 0;

        font-weight: 700;
        font-size: 18px;
        line-height: 34px;

        @include vp-1023 {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
        }
    }

    &__link {
        position: relative;
        font-size: 16px;
        line-height: 24px;
        color: inherit;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 1px;
            background-color: rgba($color-default-white, 0.2);
        }
    }

    &__item--link .output__link {
        display: none;
    }

    &__item--link {
        position: relative;

        @include vp-1023 {
            .output__quantity {
                display: none;
            }

            .output__link {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                width: 90%;
                height: 90%;

                display: flex;
                justify-content: center;
                align-items: center;

                color: $color-neon-blue;
                background-color: $color-quartz;
                border-radius: 50%;
            }
        }
    }

    &__quantity {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: $color-neon-blue;
    }

    &__btns {
        display: flex;
        align-items: center;

        @include vp-1023 {
            justify-content: space-between;
            flex-direction: column;
        }

        @include vp-374 {
            flex-direction: column;
        }

        .btn {
            max-width: max-content;
            padding: 18px 25px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.03em;

            @include vp-1023 {
                margin-top: 16px;
                padding: 14px 25px;
                font-size: 12px;
            }

            @include vp-767 {
                padding: 8px 25px;
            }
        }
    }

    &__btn-cancel.btn {
        min-width: 180px;
        margin-right: 16px;
        border: 1px solid rgba($color-default-white, 0.2);

        @include vp-1023 {
            min-width: 130px;
            margin-right: 12px;
            color: $color-jaguar;
            background-color: $color-default-white;
            border: 1px solid $color-link-water;
        }
    }

    &__btn-confirm.btn {
        color: $color-neon-blue;

        @include vp-1023 {
            min-width: 200px;
            color: $color-default-white;
            background-color: $color-neon-blue;
        }
    }
}

.output--white {
    color: $color-jaguar;
    background-color: $color-default-white;
    box-shadow: 0px -12px 28px rgba(35, 35, 42, 0.11);

    .output__btn-cancel {
        color: inherit;
        background-color: $color-default-white;
        border: 1px solid $color-solitude;
    }

    .output__container {
        padding: 21px;
    }

    .output__btn-confirm {
        color: $color-default-white;
        background-color: $color-neon-blue;
    }

    .output__link {
        color: $color-neon-blue;
    }

    .output__item--link {
        background-color: $color-quartz;
        border: 2px solid $color-default-white;
    }

    .output__link::after {
        background-color: rgba($color-neon-blue, 0.2);
    }

    @include vp-767 {
        border-radius: 24px 24px 0 0;
    }
}

.output__info .output__link {
    @include vp-1023 {
        display: none;
    }
}
