.review-block {
  padding: 24px 24px 32px;
  background-color: $color-ghost-white;
  border-radius: 12px;

  &__indicator {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &--max-index {
      .review-block__indicator-icon {
        fill: $color-shamrock;
      }

      .review-block__indicator-index {
        color: $color-shamrock;
      }
    }

    &-icon {
      margin-right: 12px;
    }

    &-index {
      font-weight: 800;
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__content {
    margin: 0;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-jaguar;
    word-break: break-word;
  }

  &__data {
    display: block;
    margin: 0;
    margin-bottom: 33px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-gray-chateau;
  }

  &__edit-review {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $color-neon-blue;
    margin-bottom: 4px;
  }

  &__time {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: $color-gray-chateau;
  }
}
