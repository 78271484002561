.chat-lk {
  height: 0;
  width: 400px;
  transition: height $trans-default;
  max-height: calc(100vh - 124px);
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  bottom: 40px;
  right: 40px;
  z-index: 3000;
  border-radius: 12px;
  position: fixed;
  background-color: #fff;

  .browser-firefox & {
    -moz-transform: scale(0.75);
    -moz-transform-origin: 50% 0%;
    bottom: -100px;
    right: 0;
    @media (max-width: 1199px) {
      -moz-transform: scale(1);
      bottom: 40px;
      right: 40px;
    }
  }

  @include vp-767 {
    right: 20px;
    bottom: 20px;
  }

  @include vp-479 {
    width: calc(100% - 40px);
    right: 20px;
    bottom: 20px;
  }

  &.active {
    height: 606px;
  }

  &__button {
    bottom: 40px;
    position: fixed;
    right: 40px;
    z-index: 3000;

    border-radius: 50%;
    padding: 15px;

    &-icon {
      background: transparent;
      border: none;
      cursor: pointer;
    }

    .browser-firefox & {
      -moz-transform: scale(0.75);
      -moz-transform-origin: 50% 0%;
      @media (max-width: 1199px) {
        -moz-transform: scale(1);
      }
    }
  }

  &__header {
    background-color: $color-neon-blue;
    padding: 12px 24px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--green {
      background-color: $color-shamrock;
    }

    h3 {
      color: white;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__content {
    height: 500px;
    position: relative;
    overflow: hidden;
    padding: 10px 5px;
    background-color: white;

    &-container {
      overflow-y: auto;
      height: 100%;
      padding: 0 24px;
    }

    &--empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__error-block, &__empty-block {
    color: $color-gray-chateau;;
  }

  &__card {
    display: block;
    padding: 16px 0;
    border-bottom: 1px solid #E0DEE3;
    background-color: transparent;
    outline: none;
    width: 100%;
    transition: opacity $trans-default;

    &--support {
      padding: 12px 24px;
      background-color: #f4f4f6;
      display: flex;
      align-items: center;

      img {
        width: 10%;
        margin-right: 10px;
      }
    }

    &-date {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: normal;
      color: $color-gray-chateau;
      margin-bottom: 5px;
    }

    &-title {
      font-weight: 500;
      margin-bottom: 2px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      line-height: 1.5;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      // &--support {
      //   color: #9ea4a7;
      // }
    }

    &-unread {
      margin-left: 15px;
      background-color: $color-neon-blue;
      color: white;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.01em;
      font-weight: 500;
      min-width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px;

      &--green {
        background-color: $color-shamrock;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
        &:hover {
          opacity: 0.7;
        }
    }
  }
}