.languages {
  max-width: max-content;

  &__title {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: $color-jaguar;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__list {
    margin: 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
  }

  &__item {
    width: 15px;
    height: 11px;
    margin-right: 5px;
    border-radius: 1px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__add {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $color-gray-chateau;
  }

  &--big {
    .languages__item {
      width: 22px;
      height: 16px;
      margin-right: 6px;
    }
  }

  &--full {
    .languages__item {
      margin-bottom: 12px;

      @include vp-767 {
        margin-top: 3px;
      }
    }
  }
}
