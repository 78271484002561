.alert-box {
  position: relative;

  padding: 16px;
  padding-left: 56px;

  border: 1px solid $color-gamboge;
  background: rgba($color-gamboge, 0.1);
  border-radius: 12px;
  color: $color-equator;

  @include vp-767 {
    font-size: 14px;
    line-height: 22px;
  }

  &__title {
    color: $color-gamboge;
    font-weight: 700;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 18px;
    height: 22px;

    transform: translateY(-50%);
  }
}
