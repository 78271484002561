.receipt {
  &__list {
    margin: 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__item {
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  &__link {
    padding: 7px 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $color-jaguar;
    text-transform: none;
    background-color: $color-default-white;
    border: 1px solid $color-link-water;
    border-radius: 10px;

    @include vp-767 {
      font-size: 14px;
      line-height: 22px;
    }

    &--current {
      padding: 8px 16px;
      color: $color-default-white;
      background-color: $color-neon-blue;
      border: none;
    }

    &--amount {
      padding-right: 44px;
    }
  }

  &__amount {
    position: absolute;
    top: 10px;
    right: 17px;
    display: block;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $color-default-white;
    background-color: $color-neon-blue;
    border-radius: 50%;
    text-align: center;
  }
}
