.address-field {
  position: relative;

  &__button {
    cursor: pointer;
    border: none;
    background-color: inherit;
    padding: 0;
    height: 100%;

    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-neon-blue;
      }
    }

    &--edit {
      right: 50px;
    }

    &--cancel {
      right: 20px;
    }
  }
}

.new-order .address-field label input {
  padding-right: 60px;
}
