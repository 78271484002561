.feedback-form {
    max-width: 1400px;
    margin: 0 auto;
    padding-bottom: 75px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;

    @include vp-1279 {
        grid-template-columns: 1fr;
    }

    .title--h2 {
        margin-bottom: 24px;
        font-size: 46px;
        line-height: 62px;

        @include vp-1439 {
            font-size: 40px;
            line-height: 1.3;
        }

        @include vp-1023 {
            font-size: 32px;
        }

        @include vp-767 {
            font-size: 30px;
            line-height: 34px;
        }
    }

    &__title {
        margin: 0 0 12px;
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
        color: $color-black-russian;
    }

    &__text {
        margin: 0;
        padding-right: 40px;
        font-size: 18px;
        line-height: 34px;
        color: $color-black-russian;

        @include vp-767 {
            padding: 0;
            font-size: 16px;
            line-height: 24px;
        }
    }


    &__form-wrapper {
        max-width: 690px;

        @include vp-767 {
            max-width: unset;
        }
    }

    &__input {
        margin-bottom: 17px;
    }

    &__textarea {
        margin-bottom: 20px;
        border-radius: 12px;
    }

    &__user-field {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;

        @include vp-767 {
            grid-template-columns: 1fr;
        }
    }

    &__type-field {
        margin-bottom: 12px;
    }

    &__file-field {
        margin-bottom: 37px;

        @include vp-767 {
            margin-bottom: 30px;
        }
    }

    &__file-name {
        margin: 0 0 30px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
    }

    &__file-error {
        margin-top: -30px;
        left: 0;
        font-size: 12px;
        line-height: 14px;
        color: #ffd8d8;
        margin-bottom: 30px;
    }

    &__file-text {
        margin: 0;
        font-size: 18px;
        line-height: 30px;
        color: $color-black-russian;

        @include vp-767 {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__file-delete {
        width: 24px;
        height: 24px;
        margin-right: 12px;

        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        &:hover,
        &:focus {
            opacity: 0.7;
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }

    &__file-field .custom-input {

        label input {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            white-space: nowrap;
            border: 0;
            clip: rect(0 0 0 0);
            clip-path: inset(100%);
        }

        label {
            max-width: max-content;
            padding-left: 8px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.06em;

            color: $color-neon-blue;
            text-transform: uppercase;

            cursor: pointer;

            &:focus-within {
                opacity: 0.7;
            }
        }
    }

    &__button {
        min-width: 300px;
        width: max-content;
        margin-top: 29px;
    }
}
