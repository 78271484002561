.page-main-specialists {
  display: grid;
  grid-template-columns: 1fr 272px;

  column-gap: 88px;
  padding-left: 80px;

  .order-stats__wrapper {
    padding: 20px 0 80px 0px;
  }

}
