.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.loader.medium {
    min-height: 40vh;
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
}

.loader.medium .lds-ellipsis {
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 5px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    background: #22D695;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    top: 50px;
    background: #434BEE;
    animation: lds-ellipsis2 0.6s infinite;
}

.loader.small .lds-ellipsis div:nth-child(2){
    top: 0px;
    right: 50px;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}


@keyframes lds-ellipsis2 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

.filter__wrap-block {
    .loader {
        justify-content: unset;
        min-height: auto;
    }
}
