.profile {
  padding: 48px 0 0;

  &__heading {
    padding: 42px 13.8% 40px 12%;
    background-color: $color-ghost-white;
    border-radius: 24px;
    margin-bottom: 46px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .upload-foto {
      margin-right: 24px;

      .avatar {
        @include vp-767 {
          width: 64px;
          height: 64px;
        }
      }
    }

    @include vp-1439 {
      padding: 42px 24px 40px;

      .bonus-balance {
        margin-left: auto;
      }
    }

    @include vp-1199 {
      padding: 42px 24px 40px;

      .title {
        width: min-content;
      }

      .bonus-balance {
        flex-basis: 50%;
      }
    }

    @include vp-767 {
      flex-direction: column;
      align-items: center;

      .title {
        width: min-content;
        margin-bottom: 18px;
      }

      .bonus-balance {
        margin-left: 0;
        text-align: center;
      }
    }
  }

  .bonus-balance {
    margin-left: auto;

    @include vp-767 {
      margin-left: 0;
    }
  }

  &__wrapper {
    margin-left: 11.9%;
    max-width: 1116px;

    display: grid;
    grid-template-columns: minmax(min-content, 477px) minmax(min-content, 477px);
    align-items: start;
    justify-content: space-between;

    @include vp-1439 {
      margin-left: 0;
    }

    @include vp-1023 {
      margin-left: 0;

      display: flex;
      flex-direction: column;
    }
  }

  &__fieldset {
    width: 100%;

    @include vp-1023 {
      max-width: 100%;
    }
  }

  &__name {
    grid-column: 1;
    grid-row: 1 / 2;
    margin-bottom: 30px;

    @include vp-1023 {
      margin-bottom: 24px;
    }
  }

  &__phone {
    grid-column: 1;
    grid-row: 2 / 3;
    margin-bottom: 80px;

    @include vp-1023 {
      margin-bottom: 24px;
    }
  }

  &__contacts {
    grid-column: 2;
    grid-row: 1;
    justify-self: flex-end;
    margin-bottom: 0;
  }

  &__buttons {
    grid-column: 2;
    grid-row: 2 / 4;
    justify-self: flex-end;
    max-width: 477px;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;

    @include vp-1023 {
      margin-left: 0;
    }

    @include vp-479 {
      max-width: 100%;
      width: 100%;
    }
  }

  .email-field {
    margin-bottom: 48px;

    &--emailEmpty {
      margin-bottom: 16px;
    }
  }

  &__email-wrapper {
    margin-top: 45px;
  }

  &__link-wrapper,
  &__email-button-wrapper {
    flex-basis: 100%;
    position: relative;
    padding-left: 31px;
    margin-bottom: 48px;
  }

  &__email-icon,
  &__link-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &__link-link,
  &__email-button {
    font-size: 18px;
    line-height: 30px;
  }

  &__email-button-wrapper {
    margin-bottom: 15px;
  }

  &__email-button {
    border: none;
    background-color: inherit;
    padding: 0;
  }

  &__email-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-gray-chateau;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 35px;

    &--emailEmpty {
      margin-bottom: 22px;
    }

    a {
      margin-left: 5px;
    }
  }

  &__button {
    background-color: inherit;
    border: 1px solid $color-link-water;
    padding: 18px 32px;
    border-radius: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #17171b;
    margin-bottom: 20px;

    &--color {
      background-color: $color-neon-blue;
      color: $color-default-white;

      .specialist-profile & {
        background-color: $color-shamrock;
      }
    }

    &:not(:last-child) {
      margin-right: 20px;

      @include vp-767 {
        margin-right: 0;
      }
    }

    @include vp-1023 {
      width: 100%;

      // &:not(:last-child) {
      //   margin-right: 0;
      // }
    }
  }

  &__languages {
    grid-column: 1;
    grid-row: 3;
    margin-bottom: 45px;

    legend {
      margin-bottom: 18px;
    }

    .toggle-lang__list {
      margin-bottom: 4px;
    }

    .toggle-lang__button {
      margin-bottom: 0;
    }

    .custom-toggle__label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__address {
    grid-column: 1;
    grid-row: 4;
    margin-bottom: 0;
    margin-top: 14px;

    .custom-input {
      position: relative;
    }

    .custom-input input {
      padding-right: 90px;
    }

    &-button {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background-color: inherit;
      padding: 0;
      width: min-content;

      &--edit {
        right: 50px;
      }

      &--cancel {
        right: 20px;
      }
    }

    &-link {
      font-size: 18px;
      line-height: 30px;
    }
  }
}