.profile-card {
    padding: 39px 40px;
    border: 1px solid $color-link-water;
    border-radius: 24px;

    display: grid;
    grid-template-columns: 150px 1fr minmax(min-content, 221px);
    grid-template-areas:
        "img title lang"
        "img indicator lang"
        "img service button"
        ". service offer"
        ". note .";
    column-gap: 23px;

    @include vp-1023 {
        padding: 24px;
        grid-template-columns: 64px 1fr;
        grid-template-areas:
            "img title"
            "img indicator"
            "lang lang"
            "service service"
            "offer offer"
            "note note"
            "button button";
        column-gap: 15px;
        row-gap: 14px;
    }

    @include vp-374 {
        display: block;
    }

    &__img-wrap {
        grid-area: img;
        width: 133px;
        height: 133px;
        border-radius: 16px;

        @include vp-1023 {
            width: 64px;
            height: 64px;
            border-radius: 12px;
        }

        @include vp-374 {
            margin-bottom: 10px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 16px;

            @include vp-1023 {
                border-radius: 12px;
            }
        }
    }

    &__title {
        grid-area: title;
        max-width: max-content;

        display: block;
        font-weight: 800;
        font-size: 24px;
        line-height: 24px;
        color: $color-jaguar;
        transition: $trans-default;

        &--disable {
            opacity: .5;
            pointer-events: none;
        }

        @include vp-1023 {
            font-size: 18px;
            line-height: 26px;
        }

        @include vp-374 {
            margin-bottom: 10px;
        }

        @media (hover: hover), screen and (min-width: 0 \0
        ) {
            &:hover,
            &:focus {
                color: $color-neon-blue;
                transition: $trans-default;
            }
        }
    }

    &-container--sent-offer {
        background: radial-gradient(92.79% 655.56% at 7.81% 7.21%,#434bee 6.65%,#22d695 28.44%,#434bee 71.49%,#22d695 100%);
        margin: 0;
        padding: 2px;
        border-radius: 24px;

        .profile-card {
            background: white;
            border: none;
        }
    }

    &-container--has-advertisement {
        background: radial-gradient(92.79% 655.56% at 7.81% 7.21%,#434bee 6.65%,#22d695 28.44%,#434bee 71.49%,#22d695 100%);
        margin: 0;
        padding: 2px;
        border-radius: 24px;

        .profile-card {
            background: white;
            border: none;
        }
    }

    .indicators {
        grid-area: indicator;

        @include vp-374 {
            margin-bottom: 10px;
        }
    }

    .profile-card__img-wrap {
        position: relative;
    }

    .profile-card__img-wrap svg.icon {
        width: 100%;
        height: 100%;
    }

    $blue-start: #3940d0;   // Синий цвет
    $blue-mid: #6087ce;     // Плавный переход от синего
    $green-start: #22df9b;  // Зеленый цвет
    $green-end: #1fbe84;    // Зеленый до конца

    .advertisement {
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        bottom: -16px;
    }

    .advertisement span {
        background: linear-gradient(
            to right,
            $blue-start 10%,    // Синий цвет
            $blue-mid 50%,      // Плавный переход от синего
            $green-start 80%,   // Зеленый цвет
            $green-end 100%     // Зеленый до конца
        );
        text-align: center;
        padding: 5px 25px;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        border-radius: 20px;
    }

    @media (max-width: 1023px) {
        .advertisement span {
            font-size: 9px;
            padding: 5px 10px;
        }
    }

    .languages {
        grid-area: lang;

        @include vp-767 {
            margin-bottom: 5px;

            .languages__title {
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
            }
        }

        @include vp-374 {
            margin-bottom: 10px;
        }
    }

    .prof-services {
        grid-area: service;
        margin-bottom: 14px;

        @include vp-767 {
            margin-bottom: 5px;

            .prof-services__item,
            .prof-services__link-more {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    &__note {
        grid-area: note;
        max-width: max-content;
        margin: 0;

        display: flex;
        align-items: center;

        font-size: 16px;
        line-height: 24px;
        color: $color-gray-chateau;

        @include vp-767 {
            font-size: 14px;
            line-height: 22px;
        }

        @include vp-374 {
            margin-bottom: 10px;
        }

        svg {
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }
    }

    &__button {
        max-width: max-content;
        height: 56px;
        padding: 18px 32px;
        display: flex;

        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;

        background: $color-neon-blue;
        border: 1px solid $color-neon-blue;
        color: $color-default-white;

        @include vp-1023 {
            grid-column: 1 / -1;
        }

        @include vp-767 {
            min-width: 100%;
            height: 48px;
            font-size: 12px;
        }

        &--cancel {
            min-width: 220px;
            display: none;
        }

        &--disabled {
            pointer-events: none;
            opacity: .5;
        }
    }

    &__button--chat {
        max-width: 219px;
        width: 100%;
        background: $color-quartz;
        border: 1px solid $color-solitude;
        color: $color-black-russian;

        .profile-card__button-text {
            margin-right: 8px;
        }
        .pinned-chats__msg-count {
            display: block;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            color: $color-default-white;
            border-radius: 50%;
            text-align: center;
            min-width: 20px;
            min-height: 20px;
            background-color: $color-neon-blue;
        }
    }

    &__offer {
        grid-area: offer;
        display: flex;
        color: #22D695;
    }

    &__offer-icon {
        flex-shrink: 0;
        width: 20px;
        height: 24px;
        padding: 12px 0 0 4px;

        svg {
            display: block;
        }

        @media (max-width: 1199px) {
            padding-top: 8px;
        }
    }

    .popover--specialist::after {
        background-color: $color-sky-blue;
    }

    .popover__tip.popover__tip--specialist {
        background-color: $color-sky-blue;
        left: 0;
        right: 0;
    }

    .popover--specialist-link {
        .popover__tip {
            background-color: $color-sky-blue;
            left: 0;
            right: 0;
        }

        &:after {
            background-color: $color-sky-blue;
            left: 15px;
        }
    }
}

.profile-card--block {
    display: block;
    min-width: unset;

    @include vp-1023 {
        min-width: unset;
    }
}
