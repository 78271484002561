.user-note {
  display: inline-flex;
  align-items: center;
  margin: 0;
  color: $color-gray-chateau;

  &__icon {
    margin-right: 6px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    @include vp-767 {
      margin-top: 3px;
    }
  }

  @include vp-767 {
    align-items: baseline;
    line-height: 1.3;
  }
}
