.profile-block {
	padding: 39px;
	padding-bottom: 38px;

	color: $color-gray-chateau;
	line-height: 30px;

	border-radius: 24px;
	border: 1px solid $color-link-water;

	@include vp-1023 {
		padding: 32px 16px;
		border-radius: 12px;
	}

	@include vp-767 {
		padding: 18px 14px;
	}

	&__icon {
		width: 20px;
		height: 14px;
	}

	&__desc {
		margin: 0;
		font-size: 18px;
		max-width: 671px;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		flex: 1;
		border-bottom: 1px solid $color-link-water;
		margin-bottom: 12px;

		@include vp-479 {
			padding-bottom: 10px;
		}
	}

	&__row:first-child {
		margin-bottom: 22px;
	}

	&__row:last-child {
		border: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}

	&__details {
		display: flex;
		line-height: 24px;
		padding-bottom: 6px;

		.text {
			max-width: 158px;
		}
	}

	&__col {
		display: flex;
		flex: 1 1 0;
		flex-direction: column;
		padding-right: 16px;

		@include vp-767 {
			min-width: unset;
			padding-right: 0;
			margin-top: 16px;
			min-width: 80%;
		}
	}

	&__link {
		margin-bottom: 32px;

		@include vp-479 {
			margin-bottom: 12px;
		}
	}

	&__col:first-of-type {
		flex-basis: 78px;

		@include vp-767 {
			margin-right: 0;
			margin-top: 0;
		}
	}

	&__col:not(:first-child) {
		max-width: 296px;
	}

	&__btn {
		padding: 5px;
		color: inherit;
	}

	&__btn-icon {
		width: 20px;
		height: 20px;
	}

	&__buttons {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		flex: 1;
		min-width: 63.5%;
		padding-right: 8%;
		padding-top: 4px;

		.btn {
			width: 100%;

			@include vp-767 {
				margin: 8px 8px 0;
				min-width: 230px;
				width: unset;
				flex: 1;
			}
		}

		.btn:not(:first-child) {
			margin-left: 24px;

			@include vp-767 {
				margin-left: 8px;
			}
		}

		@include vp-1023 {
			padding-right: 0;
		}

		@include vp-767 {
			margin: 0 -8px;
			flex-wrap: wrap;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 16px;
		line-height: 36px;
	}

	&__header {
		font-weight: 700;
		margin-bottom: 6px;

		@include vp-767 {
			font-size: 16px;
		}
	}

	&__control {
		margin-top: 5px;
		padding: 18px 32px;

		@include vp-1023 {
			margin-top: 0;
		}

		@include vp-767 {
			width: 100%;
		}
	}

	&__person {
		font-weight: 700;
		font-size: 16px;
	}

	&__check {
		order: -1;
		padding-top: 2px;
		padding-left: 2px;
		width: 38px;
		color: $color-shamrock;
		opacity: 1;
		transition: opacity $trans-default;
	}

	&__check.not-checked {
		opacity: 0;
	}

	&__controls {
		display: flex;
		align-items: flex-start;
		margin-left: auto;
		padding-left: 47px;

		@include vp-1439 {
			padding-left: 0;
		}

		@include vp-767 {
			justify-content: flex-end;
			align-items: flex-end;
		}

		&--centered {
			align-items: center;
		}
	}

	&__text {
		font-size: 18px;
		line-height: 30px;
		color: $color-jaguar;
	}

	&__list-item {
		display: flex;

		span {
			margin-left: auto;
			white-space: nowrap;
			padding: 0 50px;

			@include vp-1023 {
				padding: 0;
				padding-left: 8px;
			}
		}
	}

	&__list-item > &__year {
		margin: 0;
		white-space: nowrap;
		padding: 0 50px;

		@include vp-1023 {
			padding: 0;
			padding-left: 8px;
		}
	}

	&__list-item > &__clip {
		margin-left: auto;
		padding-right: 8px;

		svg {
			vertical-align: sub;
		}
	}

	&__list-item::before {
		content: "•";
		padding: 0 9px;
	}

	span {
		color: $color-jaguar;
		padding-left: 10px;

		@include vp-479 {
			padding-right: 10px;
		}
	}

	&.grey-card {
		border: 0;
	}

	.link {
		font-size: 12px;
		line-height: 20px;
		font-weight: 500;
	}

	.custom-toggle__label {
		padding-left: 0;
		font-size: 16px;
		line-height: 32px;
	}

	.custom-toggle--checkbox {
		padding: 0;
	}

	.dropdown {
		margin-top: -5px;
		padding: 0;
		color: $color-jaguar;
		max-width: 825px;

		@include vp-1023 {
			margin-top: 0;
		}
	}

	.slider {
		@include vp-767 {
			max-width: calc(100vw - 48px);
		}
	}

	&--prof {
		.profile-block__row {
			// padding-bottom: 15px;
			padding: 15px 0;
			margin-bottom: 15px;
		}

		.profile-block__row:last-child {
			// padding-bottom: 0;
			margin-bottom: 0;
		}
	}
}

.profile-block__row:not(:last-child) {
	.profile-block__sub {
		margin-bottom: 19px;
	}

	.profile-block__controls {
		@include vp-767 {
			margin-bottom: 18px;
		}

		@include vp-479 {
			margin-bottom: 0;
		}
	}

	.profile-block__control {
		@include vp-767 {
			margin-bottom: 16px;
		}
	}
}

.profile-block__row {
	@include vp-767 {
		.profile-block__sub {
			margin-bottom: 16px;
		}
	}
}