.find-pro-selected {

    .title {
        margin-bottom: 45px;
    }

    .find-pro__wrapper {
        max-width: max-content;
        margin: 0;
        padding: 35px 35px 35px 14%;
        grid-template-columns: 1fr;

        @include vp-1919 {
            padding: 35px 35px 35px 7.5%;
        }

        @include vp-1439 {
            padding: 35px 35px 35px 2%;
        }

        @include vp-1023 {
            max-width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
        }
    }

    .find-pro__profiles-list {
        @include vp-1023 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
        }

        @include vp-767 {
            grid-template-columns:  1fr;
        }
    }

    .profile-card {
        &__button--confirm {
            display: none;
        }

        &__button--cancel {
            display: flex;
        }
    }
}
