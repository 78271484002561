.rating {
  padding: 2px 4px;

  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-weight: 600;

  color: $color-default-white;
  border-radius: 5px;

  svg {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  &--min {
    background-color: $color-watermelon;
  }

  &--middle {
    background-color: $color-kournikova;
  }

  &--max {
    background-color: $color-shamrock;
  }

  &--lg {
    padding: 0;
    font-weight: 800;

    font-size: 40px;
    line-height: 48px;

    svg {
      margin-right: 12px;
      width: 22px;
      height: 22px;
      vertical-align: baseline;

      @include vp-767 {
        margin-right: 10px;
      }
    }

    @include vp-767 {
      font-size: 26px;
      line-height: 32px;
    }
  }

  &--block {
    display: block;
  }

  &--green {
    color: $color-shamrock;
  }

  &--yellow {
    color: $color-kournikova;
  }

  &--end {
    justify-content: flex-end;
  }
}
