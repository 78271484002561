.grey-card {
  border-radius: 24px;
  background-color: $color-ghost-white;

  @include vp-767 {
    padding: 24px 16px 16px;
    border-radius: 12px;
  }

  &__title {
    font-weight: 700;
  }
}
