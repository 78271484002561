.custom-input,
.custom-textarea {
    position: relative;

    display: flex;

    label {
        display: flex;
        align-items: center;
        width: 100%;
        transition: opacity $trans-default;

        &:hover,
        &:focus {
            opacity: 0.7;
            transition: opacity $trans-default;
        }

        input,
        textarea {
            flex-grow: 1;
            width: 100%;
            padding: 15px 24px;

            font-size: 18px;
            line-height: 30px;

            background-color: $color-default-white;
            border: 1px solid $color-hawkes-blue;
            border-radius: 12px;
            outline: none;
            transition: border-color $trans-default;

            // for mobile and tablet
            max-height: 400px;

            @include vp-767 {
                font-size: 16px;
                line-height: 24px;
            }

            &::placeholder {
                color: $color-mischka;
            }

            &:hover,
            &:focus {
                border-color: $color-neon-blue;
            }
        }
    }

    &__suffix {
        font-size: 18px;
        line-height: 30px;
        color: $color-mischka;
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    &__error {
        position: absolute;
        left: 0;
        bottom: -15px;

        font-size: 12px;
        line-height: 14px;

        color: #ef2e2e;
        opacity: 0;
        transition: opacity $trans-default;
    }

    &.is-invalid {

        textarea,
        input {
            border: 1px solid $color-cosmos;
            background-color: $color-snow;
            transition: background-color $trans-default;
        }

        .custom-input__error {
            opacity: 1;
        }
    }

    &.is-disabled {
        pointer-events: none;

        input {
            background-color: $color-solitude-2;
            // cursor: not-allowed;
        }
    }

    // &.is-valid {
    //   input {
    //     border: 2px solid $color-malachite;

    //     &:focus,
    //     &:hover {
    //       border: 2px solid rgba($color-malachite, 0.5);
    //     }
    //   }
    // }
}

.custom-textarea textarea {
    min-height: 120px;
}