.content-missing {
    padding-top: 180px;
    padding-bottom: 180px;

    &__wrapper {
        max-width: 430px;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__img {
        width: 124px;
        height: 124px;
        margin-bottom: 38px;

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-ghost-white;
        border-radius: 50%;

        svg {
            width: 32px;
            height: 32px;
        }
    }

    &__text {
        margin: 0;
        font-size: 16px;
        line-height: 30px;
        color: $color-jaguar;
        text-align: center;
    }
}
