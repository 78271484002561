.ad-gradient {
  background: linear-gradient($color-default-white, $color-default-white) padding-box,
    linear-gradient(to right, rgba(67, 75, 238, 1), rgba(34, 214, 149, 1), rgba(67, 75, 238, 1), rgba(34, 214, 149, 1)) border-box;
  border: 2px solid transparent;
  border-radius: 24px;
  padding: 40px;

  .profile-card__img-wrap {
    position: relative;

    &::before {
      content: "Реклама";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);

      width: max-content;
      height: 24px;
      padding: 2px 16px;

      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 600;
      font-size: 12px;
      line-height: 20px;

      color: $color-default-white;
      background: linear-gradient(93.7deg, $color-neon-blue 29.87%, $color-shamrock 83.48%);
      border-radius: 37px;

      @include vp-1023 {
        display: none;
      }
    }
  }
}
