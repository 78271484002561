.status {
  position: relative;

  margin: 0;
  padding-left: 12px;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $color-gray-chateau;

  &::after {
    position: absolute;
    top: 50%;
    left: 0;

    content: "";

    width: 8px;
    height: 8px;

    background-color: $color-gray-chateau;
    transform: translateY(-50%);
    border-radius: 50%;
  }
}
