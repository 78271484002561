.promo-block {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 20px;

  @include vp-1023 {
    display: flex;
    column-gap: 0;
    row-gap: 0;
    overflow: auto;
    padding-right: 20px;
  }

  &__item {
    position: relative;

    padding-top: 133%;
    margin-top: 20px;
    height: 0;
    border-radius: 24px;
    overflow: hidden;
    background-color: $color-ghost-white;

    @include vp-1023 {
      height: 221px;
      padding-top: 0;
      flex-shrink: 0;
      min-width: 170px;
    }
  }

  &__item:not(:first-child) {
    @include vp-1023 {
      margin-left: 20px;
    }
  }
}
