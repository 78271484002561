.promo-customer {
  overflow: hidden;

  @include vp-767 {
    margin-bottom: 32px;
  }

  // p {
  //   min-width: 514px;

  //   @include vp-1023 {
  //     max-width: 381px;
  //   }

  //   @include vp-767 {
  //     max-width: unset;
  //     min-width: unset;
  //   }
  // }

  @include vp-1023 {
    grid-row: 1;
  }

  // .promo__subtitle {
  //   max-width: 570px;
  // }

  .title--h1 {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 46px;

    @include vp-767 {
      font-size: 28px;
      line-height: 36px;
    }
  }

  .promo__content-text {
    padding-bottom: 30px;
    // max-width: 744px;

    // @include vp-1023 {
    //   max-width: 50%;
    // }

    // @include vp-767 {
    //   max-width: unset;
    // }
  }
  .promo__content-wrapper::after {
    @include vp-1023 {
      display: none;
    }
  }
  .promo__text {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 26px;

    @include vp-767 {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 24px;
    }

    .ql-size-large{
      font-size: 18px;
      line-height: 26px;

      @include vp-767 {
          font-size: 16px;
          line-height: 22px;
      }

    }
  }
  .promo__order-buttons {
    position: initial;
    align-items: center;
    @include vp-767 {
      flex-direction: column;
    }
    .btn {
      box-shadow: 0 13px 28px #434bee80;
      text-transform: uppercase;
      width: fit-content;
      padding: 23px 64px;
      margin: 0 64px 0 0;
      font-size: 14px;
      line-height: 18px;
      @include vp-767 {
        width: 100%;
        margin: 0 0 32px;
      }
    }
    .link {
      height: 24px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      letter-spacing: 0.04em;
      align-self: auto;
      padding: 0;
      &__icon {
        margin-right: 8px;
      }
    }
  }

  // .promo__background-image-wrapper {
  //   display: block;
  //   max-width: 50%;
  //   align-self: flex-end;
  //   margin: 0;

  //   & img {
  //     max-width: 108%;
  //   }

  //   @include vp-767 {
  //     display: block;
  //     max-width: 100%;
  //     padding: 0;
  //     width: 100%;
  //     z-index: -1;

  //     & img {
  //       margin: 0 auto;
  //       height: 100%;
  //       max-width: 100%;
  //       transform: translateX(-5px);
  //     }
  //   }
  // }

  .promo__content-hidden {
    display: none;
  }

  .promo__desktop-content {
    display: block;

    @include vp-767 {
      display: none;
    }
  }

  .promo__mobile-content,
  .promo__mobile-content-hidden {
    display: none;

    @include vp-767 {
      display: block;
    }
  }

  .promo__mobile-content-hidden {
    margin-bottom: 45px;

    @include vp-767 {
      margin-bottom: 0;
    }
  }

  .promo__mobile-content-hidden p {
    font-size: 14px;
    line-height: 22px;
    color: $color-jaguar;
  }
}

.promo-customer__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  overflow: hidden;

  @include vp-767 {
    top: 50%;
  }

  img {
    width: 78%;
    transform: translate(46%, -39%);
    opacity: 0.8;

    @include vp-767 {
      max-width: none;
      width: 150%;
      transform: translate(-61px, 0);
      opacity: 0.9;
    }
  }
}
