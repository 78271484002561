.account-options-specialists {  
   .account-options__wrapper {
    padding: 124px 0 181px 120px;
    max-width: 724px;

    @include vp-1919 {
      padding: 80px 30px 50px
    }

   
    @include vp-1023 {
      padding: 32px 16px 15px;
    }   
  }

  .account-options__list {
    max-width: 495px;

    &::before {
      background-color: $color-shamrock;
    }
  }

  .account-options__button {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    width: 274px;
    background-color: $color-shamrock;
    box-shadow: 0px 13px 28px rgba(34, 214, 149, 0.5);
  }

  .account-options__image {
    bottom: 20px;
    right: 11px;

    & img {
      width: 780px;
    }

    @include vp-1439 {
      align-items: center;
      display: flex;
      height: 100%;
    }

    @include vp-767 {
      display: none;
    }
    
  }

  .account-options__item::before {
    color: $color-shamrock;
    border: 1px solid $color-shamrock;
  }
}
