.indicators {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  column-gap: 10px;
  align-items: center;

  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  &__link-review {
    align-self: center;
  }

  &__deal {
    color: $color-gray-chateau;
  }
}
