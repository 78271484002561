.partner-block {
  margin-bottom: 147px;
  background-color: $color-ghost-white;
  border-radius: 32px;

  @include vp-1023 {
    margin-bottom: 70px;
    border-radius: 24px;
  }

  @include vp-767 {
    margin-right: -16px;
    margin-left: -16px;
  }

  &__wrapper {
    max-width: 1400px;
    margin: 0 auto;
    padding: 80px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;

    @include vp-1439 {
      padding: 50px;
    }

    @include vp-1023 {
      column-gap: 0;
      row-gap: 0;
      padding: 33px;
      display: flex;
      flex-direction: column-reverse;
    }

    @include vp-767 {
      padding: 33px 16px;
    }
  }

  &__img-container {
    max-width: 530px;
    height: 530px;
    border-radius: 32px;

    @include vp-1279 {
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
    }
  }

  &__content {
    padding-left: 80px;

    @include vp-1279 {
      padding-left: 20px;
    }

    @include vp-1023 {
      margin-bottom: 20px;
      padding-left: 0;
    }

    .title--h2 {
      margin-bottom: 40px;

      @include vp-767 {
        margin-bottom: 24px;
      }
    }
  }

  &__text {
    margin: 0;
    font-size: 18px;
    line-height: 30px;
    color: $color-jaguar;

    @include vp-767 {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
