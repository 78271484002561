.notice {
  max-width: 832px;
  min-height: 236px;
  padding: 32px 40px;

  display: flex;
  flex-direction: column;

  border: 1px solid $color-link-water;
  border-radius: 24px;

  @include vp-767 {
    padding: 16px 20px;
  }

  &__subtitle {
    margin: 0 0 27px;

    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: $color-jaguar;

    @include vp-767 {
      margin: 0 0 15px;
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__text {
    margin: 0;

    font-size: 18px;
    line-height: 30px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    max-height: 120px;

    @include vp-767 {
      font-size: 16px;
      line-height: 28px;
    }
  }

  &__link {
    font-weight: 600;
    text-decoration: underline;
  }

  &__link-more {
    margin-top: 13px;
    font-size: 18px;
    line-height: 30px;
    color: $color-gray-chateau;
    background-color: transparent;

    @include vp-767 {
      font-size: 16px;
      line-height: 28px;
    }
  }

  &__link-file {
    margin-top: 18px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    font-size: 18px;
    line-height: 30px;

    @include vp-767 {
      font-size: 16px;
      line-height: 28px;
    }

    svg {
      width: 16px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &__date-time {
    margin-top: 30px;
    align-self: end;

    font-size: 16px;
    line-height: 24px;
    color: $color-gray-chateau;

    @include vp-767 {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
