.pro-address {

  &__input {
    position: relative;
    margin-bottom: 19px;

    & button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);

      width: 24px;
      height: 24px;
      padding: 0;

      background-color: inherit;
      border: none;
      cursor: pointer;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;

      width: 24px;
      height: 24px;
    }
  }

  &__km {
    &-text {
      margin: 0;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 18px;
      line-height: 34px;
      color: $color-jaguar;
    }
  }

  &__input-km {
    position: relative;
    max-width: 264px;
    margin-bottom: 46px;

    span {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);

      max-width: max-content;
      font-size: 18px;
      line-height: 30px;
      color: $color-mischka;
    }
  }

  .custom-input label input {
    padding-right: 60px;
  }
}
