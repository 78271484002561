.table-large {
  table {
    width: 100%;
    border: 1px solid  $color-link-water;
    border-radius: 24px;

    @include vp-1023 {
      border-radius: 0;
      border: 0;
    }
  }

  thead {
    color: $color-gray-chateau;

    th {
      font-weight: 400;
    }

    @include vp-1023 {
      display: none;
    }
  }

  th {
    font-size: 18px;
    line-height: 30px;
    text-align: left;

    @include vp-1023 {
      font-size: 14px;
      line-height: 26px;
    }
  }

  th:first-child {
    flex: 1;

  }

  th,
  td {
    width: 23.7%;
    vertical-align: top;
    padding: 15px 30px;

    @include vp-1023 {
      width: unset;
    }
  }

  tr {
    display: flex;
    box-shadow: 0 1px 0 0 $color-link-water;

    @include vp-1023 {
      margin-bottom: 12px;
      flex-direction: column;
      border-radius: 24px;
      border: 1px solid  $color-link-water;
      box-shadow: none;
    }
  }

  tbody tr:last-child {
    box-shadow: none;

    @include vp-767 {
      margin-bottom: 0;
    }
  }

  tbody tr {
    min-height: 123px;

    @include vp-1023 {
      min-height: unset;
    }
  }

  .is-empty::after {
    content: "—";
    font-size: 18px;
    line-height: 30px;
    color: $color-gray-chateau;
  }

  .tag {
    padding: 0;
    background-color: transparent;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
  }

  @include vp-1023 {
    th::before,
    td::before {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $color-gray-chateau;
    }

    td::before {
      content: "Поднятие в ТОП";
    }

    th::before {
      content: "Сотрудник";
    }

    td:first-of-type::before {
      content: "На платформе";
    }

    td:last-of-type::before {
      content: "Email-рассылки";
    }

    tbody {
      display: flex;
      flex-wrap: wrap;
    }

    tr {
      flex: 1;
    }

    tr:not(:first-child) {
      margin-left: 20px;
    }

    th:first-child,
    th,
    td {
      flex: none;
    }
  }

  @include vp-767 {
    tbody {
      flex-direction: column;
    }

    tr:not(:first-child) {
      margin-left: 0;
    }

    td,
    th {
      padding: 7px 22px;
    }

    tr {
      font-size: 14px;
      padding: 16px 0 6px;
    }

    .tag {
      font-size: 12px;
    }
  }
}
