.filter {
    position: relative;

    &__wrap-block.mobile {
        display: none;

        @include vp-1199 {
            display: block;
        }
    }

    &__btn-subtitles {
        padding-bottom: 100px;
        flex-direction: column;
        display: none;

        @include vp-1199 {
            display: block;

            .filter__btns {
                display: flex;
                z-index: 1000;
                max-height: 130px;
                max-width: 100vw;

                .filter__btn--cancel {
                    border: 1px solid #d2d5dd;
                    justify-content: center;
                }
            }
        }

        button {
            width: 100%;
            padding: 16px 0;

            display: flex;
            align-items: center;
            justify-content: space-between;

            font-weight: 700;
            font-size: 16px;
            line-height: 26px;

            color: $color-jaguar;
            background-color: $color-default-white;
            border: none;
            border-bottom: 1px solid $color-link-water;

            cursor: pointer;
            transition: border-bottom $trans-default;

            &.btn--bg-green {
                color: $color-default-white;
                background-color: $color-shamrock;
            }

            @media (hover: hover), screen and (min-width: 0 \0
            ) {
                &:hover,
                &:focus {
                    border-bottom: 1px solid $color-neon-blue;
                    transition: border-bottom $trans-default;
                }
            }
        }

        .filter__msg-count {
            width: 20px;
            height: 20px;

            display: flex;
            justify-content: center;
            align-items: center;

            font-weight: 700;
            font-size: 12px;
            line-height: 20px;

            color: $color-default-white;
            background-color: $color-neon-blue;
            border-radius: 50%;
        }
    }

    &__block {
        margin-bottom: 16px;

        @include vp-1199 {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            max-width: 100vw;

            z-index: 100;
            overflow-y: auto;

            height: auto;
            padding: 56px 16px 16px;

            background-color: $color-default-white;
            border-radius: 0 0 32px 32px;
            display: none;

            .link {
                font-size: 14px;
                line-height: 22px;
            }
        }

        @include vp-767 {
            padding-top: 0;
        }
    }

    &__block:first-of-type {
        .filter__subtitle {
            display: none;

            @include vp-1199 {
                display: flex;
            }
        }
    }

    &__subtitle {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
        color: $color-jaguar;
        background-color: transparent;
        border: none;
        text-align: left;

        svg {
            width: 9px;
            height: 18px;
            margin-right: 20px;
            transform: rotate(0deg);
            display: none;
        }

        &--margin {
            margin-bottom: 10px;
        }

        @include vp-1199 {
            position: absolute;
            top: 0;
            width: 100%;
            padding: 16px 8px;

            font-weight: 800;
            font-size: 18px;
            line-height: 26px;
            cursor: pointer;

            span {
                content: "";
                position: absolute;
                top: 0;
                left: -16px;
                z-index: -1;

                width: 100%;
                height: 50px;

                background-color: $color-default-white;
            }

            svg {
                display: block;
            }

            &--margin {
                margin-bottom: 0;
            }

            @media (hover: hover), screen and (min-width: 0 \0
            ) {
                &:hover,
                &:focus {
                    opacity: 0.8;
                }
            }
        }

        @include vp-767 {
            position: relative;
        }
    }

    &__btns {
        position: fixed;
        bottom: 25px;
        left: 0;
        z-index: 200;

        width: 100%;
        padding: 16px;
        padding-top: 25px;
        display: none;

        .filter__btn {
            max-width: max-content;
            padding: 14px;

            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.03em;

            @include vp-1199 {
                max-width: 100%;
                width: 100%;
            }
        }

        .filter__btn--cancel {
            min-width: 130px;
            margin-right: 12px;
            background-color: $color-neon-blue;
            color: $color-default-white;

            @include vp-374 {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }

        .filter__btn--apply {
            min-width: 200px;
        }
    }

    .btn:not(.btn--view-all) {
        background-color: $color-neon-blue;
        color: $color-default-white;
        padding: 10px 16px;

        .spec-page & {
            // background-color: $color-shamrock;
        }
    }
}

.filter__block.is-open {
    display: block;
    z-index: 1000;
}

.filter__block:first-of-type.is-open ~ .filter__btns {
    display: flex;
}

.filter__btns.is-open {
    @include vp-767 {
        z-index: 1000;
    }
}

.filter__btn-subtitles {
    .filter__btns {
        @include vp-767 {
            flex-direction: column;
        }
    }
    .title {
        @include vp-767 {
            width: 100%;
            text-align: left;
        }
    }
}

.filter__star {
    color: red;
    margin-left: 5px;
    font-size: 1rem;
    vertical-align: middle;
}
