.photo-stream {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 20px;

  margin: 0;
  padding: 0;
  list-style: none;

  @include vp-767 {
    grid-template-columns: repeat(2, 1fr);
  }
}
