.your-address {
  &__input {
    position: relative;

    & button {
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-color: inherit;
      border: none;
      padding: 0;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;

      width: 24px;
      height: 24px;
    }
  }

  .custom-input label input {
    padding-right: 60px;
  }
}
