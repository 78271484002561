.phone-field {
  position: relative;
  margin-bottom: 31px;

  .custom-input {
    input {
      padding-left: 52px;
      color: $color-jaguar;
      border: 1px solid $color-link-water;
    }
  }

  &__icon {
    position: absolute;
    top: 23px;
    left: 21px;
    z-index: 3;

    @include vp-767 {
      top: 19px;
    }
  }
}

