.editing-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  padding: 24px 0;

  background: $color-default-white;
  box-shadow: 0px -12px 28px rgba(35, 35, 42, 0.11);

  @include vp-1023 {
    padding: 16px 0;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .btn {
      min-width: 145px;
      padding: 17px 32px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.03em;

      @include vp-1279 {
        padding: 10px;
        font-size: 12px;
        line-height: 18px;
      }

      @include vp-1023 {
        min-width: 100%;
        margin-bottom: 10px;
      }
    }

    .btn:not(:last-child) {
      margin-right: 18px;

      @include vp-1279 {
        margin-right: 15px;
      }
    }
  }
}
