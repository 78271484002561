.custom-toggle {
    position: relative;

    display: flex;
    width: 100%;
    padding: 10px 0;

    color: inherit;

    &__error {
        position: absolute;
        bottom: -5px;
        left: 0;

        font-size: 12px;
        line-height: 14px;

        color: #ef2e2e;
        opacity: 0;
        transition: opacity $trans-default;
    }

    input {
        position: absolute;

        width: 1px;
        height: 1px;
        margin: -1px;

        clip: rect(0 0 0 0);
    }

    label {
        cursor: pointer;
        transition: opacity $trans-default;
        user-select: none;

        @media (hover: hover),
        screen and (min-width: 0 \0) {

            &:hover,
            &:focus {
                opacity: 0.7;
                transition: opacity $trans-default;
            }
        }

        span {
            margin-bottom: 0;
            margin-left: 0;

            font-size: 18px;
            line-height: 30px;

            color: $color-black-russian;

            @include vp-767 {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }

    .custom-toggle__icon {
        position: relative;
        display: inline-flex;
        vertical-align: text-bottom;

        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        transition: border $trans-default;
    }

    &--checkbox-list {
        &.is-invalid {
            .custom-toggle__error {
                opacity: 1;
                position: relative;
            }
        }

        &-selected {
            padding-left: 5px;
            margin-bottom: 44px;
        }
    }

    &--radio-list-selected {
        margin-bottom: 44px;
    }

    &--scrollbar {
        margin-left: 5px;
    }

    &--checkbox {
        padding: 8px 2px;

        .custom-toggle__icon {
            box-shadow: 0 0 0 1px $color-hawkes-blue;
            border-radius: 4px;


            &:after,
            &::before {
                position: absolute;
                content: "";
                height: 2px;
                border-radius: 1px 0 0 1px;
                background-color: $color-neon-blue;
                opacity: 0;
            }

            &::after {
                transform: rotate(40deg);
                top: 13px;
                left: 3px;
                width: 8px;
            }

            &::before {
                transform: rotate(140deg);
                top: 11px;
                left: 8px;
                width: 13px;
            }

        }

        input:checked+.custom-toggle__icon {
            background-color: $color-quartz;
            box-shadow: none;

            &::after,
            &::before {
                opacity: 1;
            }
        }

        &.is-invalid {
            .custom-toggle__icon {
                border: 1px solid $color-cosmos;
                background-color: $color-snow;
                transition: background-color $trans-default;
            }

            .custom-toggle__error {
                opacity: 1;
            }
        }
    }

    &--radio {
        padding: 8px 0;

        .custom-toggle__icon {
            border: 1px solid $color-hawkes-blue;
            border-radius: 50%;
            box-shadow: 0 0 1px 0;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;

                width: 16px;
                height: 16px;

                border-radius: 50%;
                transform: translate(-50%, -50%);

                transition: background-color $trans-default;
            }


        }

        input:checked+.custom-toggle__icon {
            background-color: $color-quartz;
            box-shadow: none;
        }

        input:focus+.custom-toggle__icon::before,
        input:checked+.custom-toggle__icon::before {
            background-color: $color-neon-blue;
        }

        &.is-invalid {
            .custom-toggle__icon {
                border: 1px solid $color-cosmos;
                background-color: $color-snow;
                transition: background-color $trans-default;
            }

            .custom-toggle__error {
                opacity: 1;
                bottom: -5px;
            }
        }
    }

    /*&.is-valid {
      label span {
        color: $color-eucalyptus;
      }
    }*/

    &__sublist {
        padding-left: 24px;
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.custom-toggle--green {
    color: $color-shamrock;

    input:checked+.custom-toggle__icon {
        background-color: $color-humming-bird;
        border: 0;
    }

    input:checked+.custom-toggle__icon {
        background-color: $color-humming-bird;
        border: 0;

        &::after,
        &::before {
            background-color: $color-shamrock;
        }
    }
}