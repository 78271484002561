.specialist-offers {
  &__list {
    margin: 0 0 52px;
    padding: 0;
    list-style: none;

    @include vp-767 {
      margin-bottom: 24px;
    }
  }

  &__item:not(:last-child) {
    margin-bottom: 20px;

    @include vp-767 {
      margin-bottom: 12px;
    }
  }

  &__subtitle {
    margin-bottom: 26px;

    @include vp-767 {
      margin-bottom: 14px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 800;
    }
  }
}
