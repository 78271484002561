.footer {
  position: relative;
  padding: 84px 60px 65px 80px;
  background-color: $color-ghost-white;
  border-radius: 32px 32px 0px 0px;

  .browser-firefox & {
    width: calc(4 / 3 * 100%);
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1199px) {
      width: 100%;
    }
  }

  @include vp-1439 {
    padding: 60px 0 80px;
  }

  @include vp-1023 {
    padding: 40px 0 60px;
  }

  @include vp-767 {
    padding: 34px 0 24px;

    border-radius: 24px 24px 0px 0px;
  }

  .custom-select[data-name="select-lang"] .custom-select__icon {
    margin-left: 0;
  }
}

.footer__wrapper {
  display: flex;

  &--preview {
    pointer-events: none;
  }

  @include vp-767 {
    flex-direction: column;
  }
}

.footer__wrapper-logo-marketplace {
  margin-top: -5px;
}

.footer__wrapper-nav-legal {
  padding: 0 25.5% 0 16%;
  flex: 1;

  @include vp-1919 {
    padding: 0 16% 0 10%;
  }

  @include vp-1439 {
    padding: 0 10% 0 10%;
  }

  @include vp-1023 {
    padding: 0 5%;
  }

  @include vp-767 {
    padding: 0;
  }
}

.footer__logo {
  display: block;
  width: 154px;

  @include vp-767 {
    width: 94px;
  }
}

.footer__logo img {
  width: 154px;
  height: 40px;

  @include vp-767 {
    width: 94px;
    height: 26px;
  }
}

.footer__marketplace {
  margin-top: 26px;
  margin-bottom: 120px;
  display: flex;
  flex-wrap: wrap;

  @include vp-767 {
    margin-top: 16px;
    margin-bottom: 26px;
  }
}

.footer__marketplace__link {
  display: flex;
  align-items: center;

  margin-right: 8px;
  margin-bottom: 8px;
  padding: 12px 16px;

  background-color: $color-jaguar;
  border-radius: 8px;
  transition: 0.4s;

  @include vp-767 {
    margin-right: 12px;
    padding: 9px;
    padding-left: 10px;
    width: 130px;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    transition: 0.4s;
  }

  &:last-child {
    margin-right: 0;
  }
}

.footer__marketplace__icon {
  @include vp-767 {
    width: 19px;
    height: 22px;
  }
}

.footer__marketplace__text {
  margin: 0 0 0 12px;
  font-size: 10px;
  line-height: 12px;
  color: $color-default-white;

  @include vp-767 {
    margin-right: 14px;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.02em;
  }
}

.footer__marketplace span {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  @include vp-767 {
    font-size: 12px;
    line-height: 14px;
  }
}

.footer__nav__list {
  margin: 0 0 96px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  list-style: none;

  @media (max-width: 1341px) {
    flex-direction: column;
  }

  @include vp-1023 {
    margin-top: 2px;
    margin-bottom: 37px;
  }
}

.footer__nav-item {
  margin-bottom: 18px;
  transition: 0.4s;

  flex-basis: 50%;

  @include vp-767 {
    margin-bottom: 6px;
  }

  &:hover,
  &:focus-within {
    opacity: 0.7;
    transition: 0.4s;
  }
}

.footer__nav-link {
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: $color-black-russian;

  @include vp-767 {
    letter-spacing: 0;
  }
}

.footer__legal-ground {
  margin-bottom: -1px;
  position: relative;
  z-index: 2;

  @include vp-767 {
    margin-bottom: 28px;
  }
}

.footer__legal-ground-link {
  display: block;

  margin-bottom: 10px;
  max-width: 400px;

  font-size: 14px;
  line-height: 30px;
  color: $color-gray-chateau;
  transition: 0.4s;

  @media (max-width: 1140px) {
    max-width: 260px;
  }

  @include vp-767 {
    max-width: 400px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  &:hover,
  &:focus {
    color: $color-black-russian;
    transition: 0.4s;
  }
}

.footer__wrapper-social-select {
  margin-left: auto;
  margin-top: -5px;

  @include vp-1023 {
    margin-left: 0;
  }
}

.footer__select {
  position: relative;
  margin-left: 28px;
  margin-bottom: 39px;

  @include vp-767 {
    margin-bottom: 16px;
    margin-left: 34px;
  }
}

.footer__select-img {
  position: absolute;
  top: 12px;
  left: -26px;

  @include vp-767 {
    left: -33px;
  }
}

.footer__social {
  margin: 0 0 130px 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 136px;

  list-style: none;

  @include vp-767 {
    margin-bottom: 28px;
  }
}

.footer__social-item {
  margin-right: 8px;
  margin-bottom: 8px;
  width: 40px;
  height: 40px;

  text-align: center;
  background-color: rgba($color-black-russian, 0.05);
  border-radius: 50%;
  transition: 0.3s;

  &:nth-child(3n + 3) {
    margin-right: 0;
  }

  &:hover,
  &:focus-within {
    background-color: $color-black-russian;
    transition: 0.5s;
  }
}

.footer__social-link {
  display: block;
  padding: 10px;
  width: 40px;
  height: 40px;
  transition: 0.3s;

  &:hover,
  &:focus {
    opacity: 0.7;
    transition: 0.4s;
  }
}

.footer-copyright {
  position: relative;
  display: flex;
  justify-content: space-between;
  bottom: 118px;

  @include vp-1279 {
    bottom: 149px;
  }

  @include vp-767 {
    bottom: 0;
  }
}

.footer-copyright__text {
  margin: 0;
  font-size: 14px;
  line-height: 30px;

  @include vp-767 {
    font-size: 12px;
    line-height: 20px;
  }
}

.footer-copyright__owner {
  margin-left: 6px;

  @include vp-767 {
    margin-left: 0;
  }
}

.footer-copyright__owner {
  font-weight: 600;
  color: $color-black-russian;

  @include vp-767 {
    font-weight: 500;
  }
}

.footer-copyright__developer {
  @include vp-767 {
    margin-top: -4px;
  }
}

.footer-copyright__developer-link {
  font-size: 14px;
  line-height: 30px;
  color: $color-gray-chateau;
  transition: 0.3s;

  @include vp-767 {
    font-size: 12px;
    letter-spacing: 0.02em;
  }

  &:hover,
  &:focus {
    color: $color-black-russian;
    transition: 0.4s;
  }
}
