.registration-page {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: $color-quartz;

  .browser-firefox & {
    width: calc(4 / 3 * 100%);
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1199px) {
      width: 100%;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 100px;
    width: 100%;
    height: 100%;
    background-color: $color-quartz;
    z-index: -1;
  }

  &--specialist {
    background-color: $color-humming-bird;

    &::after {
      background-color: $color-humming-bird;
    }

    &--underline {
      box-shadow: 0 1px 0 0 rgba($color-shamrock, 0.2);
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
    }

    &--hover-underline {
      outline: none;
      transition: box-shadow $trans-default;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          opacity: 1;
          box-shadow: 0 1px 0 0 rgba($color-shamrock, 0.7);
          transition: box-shadow $trans-default;
        }
      }
    }

    .custom-toggle,
    .form__note {
      .link {
        color: $color-shamrock;
        transition: opacity $trans-default;

        @media (hover: hover), screen and (min-width: 0\0) {
          &:hover,
          &:focus {
            opacity: 0.6;
          }
        }
      }
    }

    .btn {
      padding: 18px;
    }

    .btn--bg-blue {
      background-color: $color-shamrock;
    }
  }
}

.registration-page__wrapper {
  padding-top: 139px;
  padding-bottom: 164px;

  @media (max-width: 1919px) {
    padding-top: 140px;
    padding-bottom: 164px;
  }

  @include vp-1023 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include vp-767 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

// .registration-page__bg-img {
//   position: absolute;
//   margin-left: -45px;
//   top: 114px;
//   left: 0;

//   width: 100%;
//   height: 100%;

//   @include vp-1023 {
//     margin-left: 0;
//     top: 53px;
//   }

//   @include vp-767 {
//     top: -24px;
//   }
// }

// .registration-page__bg-img img {
//   margin: 0 auto;
//   width: 948px;
//   height: 650px;
// }
