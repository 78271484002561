.specialist-page-modal {
  .modal__content-title {
    text-align: left;

    @include vp-767 {
      margin: 0 0 22px;
      max-width: unset;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .modal__content-buttons {
    text-align: right;
    position: relative;
    top: 9px;

    @include vp-767 {
      top: unset;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      margin-top: auto;
    }
  }

  .modal__content-button {
    background-color: inherit;
    color: $color-black-russian;
    border: 1px solid $color-link-water;
    width: unset;
    padding: 18px 32px;

    &--background {
      background-color: $color-neon-blue;
      border: none;
      color: $color-default-white;
    }

    @include vp-767 {
      max-width: unset;
      flex: 1;
      font-size: 12px;
      padding: 12px;
    }
  }

  .custom-toggle label {
    padding-left: 32px;
  }

  .modal__close-btn {
    top: 38px;
    right: 36px;

    @include vp-767 {
      display: none;
    }
  }

  .modal__wrapper {
    @include vp-767 {
      padding: 0;
      align-items: flex-end;
    }
  }

  .modal__content {
    @include vp-767 {
      display: flex;
      flex-direction: column;

      border-radius: 24px 24px 0 0;
      padding: 26px 17px;
      min-height: 392px;
    }
  }

  .custom-toggle__label {
    @include vp-767 {
      font-size: 14px;
    }
  }

  .modal__content-button--background {
    @include vp-767 {
      margin: 0;
    }
  }
}
