.order-editing {
  position: relative;
  padding-top: 82px;
  padding-bottom: 164px;

  @include vp-1279 {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .title {
    margin-bottom: 9px;
  }

  &__object {
    margin: 0 0 38px;
    font-size: 24px;
    line-height: 40px;
    color: $color-jaguar;

    @include vp-1439 {
      font-size: 22px;
      line-height: 32px;
    }

    @include vp-1023 {
      font-size: 18px;
      line-height: 30px;
    }
  }
}
