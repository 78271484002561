.price-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    grid-auto-flow: dense;

    color: $color-jaguar;

    @include vp-1279 {
        grid-template-columns: 1fr 2fr;

        &_row {
            grid: none;
        }
    }


    @media (max-width: 940px) {
        display: flex;
        flex-direction: column-reverse;
      }

    &__image {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: $color-ghost-white;
        border-radius: 24px;
        overflow: hidden;
        direction: ltr;

        img {
            flex: 1;
            min-width: 100%;
        }

        @include vp-1279 {
            display: block;
            align-self: center;
        }

        @include vp-767 {
            min-height: unset;
        }
    }

    .table {
        margin-top: 8px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        direction: ltr;
        padding: 32px 70px;
        padding-right: 90px;

        @include vp-767 {
            padding: 0;
            margin-bottom: 16px;
        }
    }

    &__title {
        line-height: 1.3;
        margin-bottom: 42px;
    }

    &__text {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 30px;
    }

    &__btn {
        margin-top: 30px;
        min-width: 300px;
        padding: 24px;
        align-self: flex-start;
        font-size: 20px;
        height: 80px;
        box-shadow: 0 13px 28px rgb(34 214 149 / 50%);
        border-radius: 12px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.03em;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select: none;
        user-select: none;

        @include vp-767 {
            align-self: stretch;
            font-size: 12px;
            line-height: 24px;
            min-width: unset;
            height: 64px;
        }
    }

    &--reverse {
        direction: rtl;

        .price-block__info {
            padding-left: 0;
            padding-right: 23%;

            @include vp-1279 {
                padding: 32px 90px 32px 70px;
            }

            @include vp-767 {
                padding: 0;
            }
        }
    }

    &__card:not(:first-child) {
        @include vp-1279 {
            margin-bottom: 32px;
        }
    }
}
